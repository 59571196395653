import { Link } from 'react-router-dom';
import getGameHref from './utils/getGameHref';
import getGamesList from './utils/getGamesList';
import Img from 'src/components/Img';

type Props = {
  games?: any;
  sendToGtm?: (action?: string) => void;
};

const PROVIDER_CARD_HEIGHT: Record<string, number> = {
  '3': 88,
  '2': 148,
  '1': 300,
};

const useProviderGames = ({ games, sendToGtm }: Props) => {
  const { cols, count } = getGamesList({ gameCount: games?.length ?? 1 });
  const providerItems = games?.slice(0, count).map((game: any) => (
    <Link
      to={getGameHref(false, game?.href)}
      key={game?.id}
    >
      <Img
        style={{
          borderRadius: '0.75rem',
          transform: 'rotate(-5deg)',
          margin: ' 0.25rem',
          height: `${PROVIDER_CARD_HEIGHT[cols]}px`
        }}
        height={PROVIDER_CARD_HEIGHT[cols]}
        src={game?.thumbUrl}
      />
    </Link>
  ));

  return { providerItems, cols };
};

const ProviderGames: React.FC<Props> = ({ games, sendToGtm }) => {
  const { providerItems } = useProviderGames({ games, sendToGtm });
  return (
    <div className='cols-items'>{providerItems}</div>
  );
};

export default ProviderGames;
