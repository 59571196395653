import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  notification,
} from "antd"
import { useCallback, useMemo, useState } from "react"
import { FormProps, RequiredMark } from "antd/es/form/Form"
import "./style.scss"
import { userApi } from "src/api/userApi"

export interface Props {
  open: boolean
  setOpen: any
}

const ddData = [
  {
    text: "Ngân hàng TMCP An Bình",
    value: 1,
    selected: false,
    description: "ABB",
    imageSrc: "https://i.ibb.co/TR6Gsn3/abb.png",
  },
  {
    text: "Ngân hàng TMCP Á Châu",
    value: 2,
    selected: false,
    description: "ACB",
    imageSrc: "https://i.ibb.co/82r0XYp/acb.png",
  },
  // {
  //     text: "Ngân hàng TMCP Bắc Á",
  //     value: 3,
  //     selected: false,
  //     description: "BAB",
  //     imageSrc: "https://i.ibb.co/TPrzXBQ/bab.png"
  // },
  {
    text: "Ngân hàng TMCP Đầu tư và Phát triển Việt Nam",
    value: 4,
    selected: false,
    description: "BIDV",
    imageSrc: "https://i.ibb.co/h29D5kt/bidv.png",
  },
  {
    text: "Ngân hàng TMCP Bảo Việt",
    value: 5,
    selected: false,
    description: "BVB",
    imageSrc: "https://i.ibb.co/pJfphyp/bvb.png",
  },
  {
    text: "TMCP Việt Nam Thịnh Vượng - Ngân hàng số CAKE by VPBank",
    value: 6,
    selected: false,
    description: "CAKE",
    imageSrc: "https://i.ibb.co/d0RYh8J/cake.png",
  },
  {
    text: "Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam",
    value: 7,
    selected: false,
    description: "CBB",
    imageSrc: "https://i.ibb.co/4gqTWkk/cbb.png",
  },
  {
    text: "Ngân hàng TNHH MTV CIMB Việt Nam",
    value: 8,
    selected: false,
    description: "CIMB",
    imageSrc: "https://i.ibb.co/9p25z0w/cimb.png",
  },
  // {
  //     text: "Ngân hàng Citibank, N.A. - Chi nhánh Hà Nội",
  //     value: 9,
  //     selected: false,
  //     description: "CITIBANK",
  //     imageSrc: "https://i.ibb.co/SKcfDH8/citibank.jpg"
  // },
  // {
  //     text: "Ngân hàng Hợp tác xã Việt Nam",
  //     value: 10,
  //     selected: false,
  //     description: "COOPBANK",
  //     imageSrc: "https://i.ibb.co/By6xjZK/coopbank.png"
  // },
  {
    text: "DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh",
    value: 11,
    selected: false,
    description: "DBS",
    imageSrc: "https://i.ibb.co/6wm7WP5/dbs.png",
  },
  // {
  //     text: "Ngân hàng TMCP Đông Á",
  //     value: 12,
  //     selected: false,
  //     description: "DOB",
  //     imageSrc: "https://i.ibb.co/cvbzZ17/dob.png"
  // },
  {
    text: "Ngân hàng TMCP Xuất Nhập khẩu Việt Nam",
    value: 13,
    selected: false,
    description: "EIB",
    imageSrc: "https://i.ibb.co/Qpm3WXn/eib.png",
  },
  {
    text: "Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu",
    value: 14,
    selected: false,
    description: "GPB",
    imageSrc: "https://i.ibb.co/556rdY9/gpb.png",
  },
  {
    text: "Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh",
    value: 15,
    selected: false,
    description: "HDB",
    imageSrc: "https://i.ibb.co/NmpWhB7/hdb.png",
  },
  // {
  //     text: "Ngân hàng TNHH MTV Hong Leong Việt Nam",
  //     value: 16,
  //     selected: false,
  //     description: "HLBVN",
  //     imageSrc: "https://i.ibb.co/QjWPCy0/hlbvn.png"
  // },
  {
    text: "Ngân hàng TNHH MTV HSBC (Việt Nam)",
    value: 17,
    selected: false,
    description: "HSBC",
    imageSrc: "https://i.ibb.co/NW511CS/hsbc.png",
  },
  // {
  //     text: "Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh",
  //     value: 18,
  //     selected: false,
  //     description: "IBK - HCM",
  //     imageSrc: "https://i.ibb.co/k4PHfcn/ibk.png"
  // },
  // {
  //     text: "Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội",
  //     value: 19,
  //     selected: false,
  //     description: "IBK - HN",
  //     imageSrc: "https://i.ibb.co/k4PHfcn/ibk.png"
  // },
  {
    text: "Ngân hàng TMCP Công thương Việt Nam",
    value: 20,
    selected: false,
    description: "ICB",
    imageSrc: "https://i.ibb.co/9YZ0h5K/icb.png",
  },
  {
    text: "Ngân hàng TNHH Indovina",
    value: 21,
    selected: false,
    description: "IVB",
    imageSrc: "https://i.ibb.co/X2DQNCk/ivb.png",
  },

  {
    text: "Ngân hàng TMCP Kiên Long",
    value: 27,
    selected: false,
    description: "KLB",
    imageSrc: "https://i.ibb.co/ncDchyQ/klb.png",
  },
  {
    text: "Ngân hàng TMCP Bưu điện Liên Việt",
    value: 28,
    selected: false,
    description: "LPBANK",
    imageSrc: "https://i.ibb.co/dgfG0yh/lpbank.png",
  },

  {
    text: "Ngân hàng TMCP Quân đội",
    value: 30,
    selected: false,
    description: "MB",
    imageSrc: "https://i.ibb.co/WKp3yqt/mb.png",
  },
  {
    text: "Ngân hàng TMCP Hàng Hải",
    value: 31,
    selected: false,
    description: "MSB",
    imageSrc: "https://i.ibb.co/MRzwdRz/msb.png",
  },
  {
    text: "Ngân hàng TMCP Nam Á",
    value: 32,
    selected: false,
    description: "NAB",
    imageSrc: "https://i.ibb.co/Z6QxrvK/nab.png",
  },
  {
    text: "Ngân hàng TMCP Quốc Dân",
    value: 33,
    selected: false,
    description: "NCB",
    imageSrc: "https://i.ibb.co/HX8Tpmx/ncb.png",
  },
  // {
  //     text: "Ngân hàng Nonghyup - Chi nhánh Hà Nội",
  //     value: 34,
  //     selected: false,
  //     description: "NHB HN",
  //     imageSrc: "https://i.ibb.co/VmtN5wQ/nhb-hn.png"
  // },
  {
    text: "Ngân hàng TMCP Phương Đông",
    value: 35,
    selected: false,
    description: "OCB",
    imageSrc: "https://i.ibb.co/2FG4xR3/ocb.png",
  },
  {
    text: "Ngân hàng Thương mại TNHH MTV Đại Dương",
    value: 36,
    selected: false,
    description: "Oceanbank",
    imageSrc: "https://i.ibb.co/NZxmTwX/oceanbank.png",
  },
  {
    text: "Ngân hàng TNHH MTV Public Việt Nam",
    value: 37,
    selected: false,
    description: "PBVN",
    imageSrc: "https://i.ibb.co/TM3Gt1x/pbvn.png",
  },
  {
    text: "Ngân hàng TMCP Xăng dầu Petrolimex",
    value: 38,
    selected: false,
    description: "PGB",
    imageSrc: "https://i.ibb.co/xj4hKZ3/pgb.png",
  },
  {
    text: "Ngân hàng TMCP Đại Chúng Việt Nam",
    value: 39,
    selected: false,
    description: "PVCB",
    imageSrc: "https://i.ibb.co/r3r4qPy/pvcb.png",
  },
  // {
  //     text: "Ngân hàng TMCP Sài Gòn",
  //     value: 40,
  //     selected: false,
  //     description: "SCVN",
  //     imageSrc: "https://i.ibb.co/TtYXDsB/scb.png"
  // },
  {
    text: "Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam",
    value: 41,
    selected: false,
    description: "DOB",
    imageSrc: "https://i.ibb.co/x66PDPt/scvn.png",
  },
  {
    text: "Ngân hàng TMCP Đông Nam Á",
    value: 42,
    selected: false,
    description: "SEAB",
    imageSrc: "https://i.ibb.co/Z2Zpjc6/seab.png",
  },
  {
    text: "Ngân hàng TMCP Sài Gòn Công Thương",
    value: 43,
    selected: false,
    description: "SGICB",
    imageSrc: "https://i.ibb.co/Z2Zpjc6/seab.png",
  },
  {
    text: "Ngân hàng TMCP Sài Gòn - Hà Nội",
    value: 44,
    selected: false,
    description: "SHB",
    imageSrc: "https://i.ibb.co/xHXjMYw/shb.png",
  },
  {
    text: "Ngân hàng TNHH MTV Shinhan Việt Nam",
    value: 45,
    selected: false,
    description: "SHBVN",
    imageSrc: "https://i.ibb.co/945JbNj/shbvn.png",
  },
  {
    text: "Ngân hàng TMCP Sài Gòn Thương Tín",
    value: 46,
    selected: false,
    description: "STB",
    imageSrc: "https://i.ibb.co/RHkSFmW/stb.png",
  },
  {
    text: "Ngân hàng TMCP Kỹ thương Việt Nam",
    value: 47,
    selected: true,
    description: "TCB",
    imageSrc: "https://i.ibb.co/fxwR1vc/tcb.png",
  },
  // {
  //     text: "Ngân hàng số Timo by Ban Viet Bank (Timo by Ban Viet Bank)",
  //     value: 48,
  //     selected: false,
  //     description: "TIMO",
  //     imageSrc: "https://i.ibb.co/2Wx9sDs/timo.png"
  // },
  {
    text: "Ngân hàng TMCP Tiên Phong",
    value: 49,
    selected: false,
    description: "TPB",
    imageSrc: "https://i.ibb.co/RDNmkSZ/tpb.png",
  },
  // {
  //     text: "Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh",
  //     value: 50,
  //     selected: false,
  //     description: "UOB",
  //     imageSrc: "https://i.ibb.co/Vp7pb5q/uob.png"
  // },
  // {
  //     text: "TMCP Việt Nam Thịnh Vượng - Ngân hàng số Ubank by VPBank",
  //     value: 51,
  //     selected: false,
  //     description: "Ubank",
  //     imageSrc: "https://i.ibb.co/swj5Nxj/ubank.png"
  // },
  {
    text: "Ngân hàng TMCP Việt Á",
    value: 52,
    selected: false,
    description: "VAB",
    imageSrc: "https://i.ibb.co/Xp6682P/vab.png",
  },
  {
    text: "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam",
    value: 53,
    selected: false,
    description: "VBA",
    imageSrc: "https://i.ibb.co/q5XZpq9/vba.png",
  },
  // {
  //     text: "Ngân hàng Chính sách Xã hội",
  //     value: 54,
  //     selected: false,
  //     description: "VBSP",
  //     imageSrc: "https://i.ibb.co/XspLxh7/vbsp.png"
  // },
  {
    text: "Ngân hàng TMCP Ngoại Thương Việt Nam",
    value: 55,
    selected: false,
    description: "VCB",
    imageSrc: "https://i.ibb.co/vH8z899/vcb.png",
  },
  {
    text: "Ngân hàng TMCP Bản Việt",
    value: 56,
    selected: false,
    description: "VCCB",
    imageSrc: "https://i.ibb.co/hVmQwKs/vccb.png",
  },
  {
    text: "Ngân hàng TMCP Quốc tế Việt Nam",
    value: 57,
    selected: false,
    description: "VIB",
    imageSrc: "https://i.ibb.co/WBymv48/vib.png",
  },
  {
    text: "Ngân hàng TMCP Việt Nam Thương Tín",
    value: 58,
    selected: false,
    description: "VIETBANK",
    imageSrc: "https://i.ibb.co/vQ8Q7SB/vietbank.png",
  },
  {
    text: "Ngân hàng TMCP Việt Nam Thịnh Vượng",
    value: 59,
    selected: false,
    description: "VPB",
    imageSrc: "https://i.ibb.co/wBYs9DZ/vpb.png",
  },
  {
    text: "Ngân hàng Liên doanh Việt - Nga",
    value: 60,
    selected: false,
    description: "VRB",
    imageSrc: "https://i.ibb.co/n6kXxfj/vrb.png",
  },
  {
    text: "Ngân hàng TNHH MTV Woori Việt Nam",
    value: 61,
    selected: false,
    description: "WVN",
    imageSrc: "https://i.ibb.co/5BM3DkQ/wvn.png",
  },
]

type FieldType = {
  name_bank?: string
  name_user?: string
  stk?: string
}
type NotificationType = "success" | "info" | "warning" | "error"

function AddBank({ onSuccess }: any) {
  const [form] = Form.useForm()
  const [api, contextHolder] = notification.useNotification()

  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>("optional")

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
  ) => {
    api[type]({
      message: message,
      placement: "bottomLeft",
    })
  }

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark
  }) => {
    setRequiredMarkType(requiredMarkValue)
  }

  const renderBank = useCallback(
    (item: any, i: any) => {
      return (
        <Select.Option value={item?.description} key={item?.value}>
          <div className="flex items-center">
            <img
              className="dd-option-image"
              src={item?.imageSrc}
              alt={item?.description}
            />
            <div className="">
              <div className="dd-option-text">{item?.text}</div>
              <div className="dd-desc">{item?.description}</div>
            </div>
          </div>
        </Select.Option>
      )
    },
    [ddData],
  )

  const onFinish: FormProps<FieldType>["onFinish"] = async (values: any) => {
    try {
      const response: any = await userApi.addWBank(values)
      if (response) {
        if (response?.status) {
          openNotificationWithIcon("success", response?.message)
          onSuccess()
        } else {
          openNotificationWithIcon("error", response?.message)
        }
      }

      return response.data
    } catch (error: any) {
      openNotificationWithIcon("error", "Xảy ra lỗi")
      return error?.response
    }
  }

  return (
    <div className="modal-body info">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        // onValuesChange={onRequiredTypeChange}
      >
        <Form.Item
          label={<span className="text-white">Chọn ngân hàng</span>}
          required
          name="name_bank"
        >
          <Select className="bg-transparent text-white">
            {ddData?.map(renderBank)}
          </Select>
        </Form.Item>
        <Form.Item
          label={<span className="text-white">Họ tên người nhận</span>}
          required
          name="name_user"
        >
          <Input
            placeholder="Vui lòng nhập họ tên người nhận"
            className="text-white"
          />
        </Form.Item>
        <Form.Item
          label={<span className="text-white">Tài khoản ngân hàng</span>}
          required
          name="stk"
        >
          <Input
            placeholder="Vui lòng nhập tài khoản ngân hàng "
            className="text-white"
            required
          />
        </Form.Item>
        <Form.Item>
          <Button className="w-full bg-orange" htmlType="submit" type="primary">
            Thêm thẻ ngân hàng
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </div>
  )
}

export default AddBank
