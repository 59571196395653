import { useMemo } from "react"
import "./style.scss"
import { useAppDispatch } from "src/app/hooks"
import { openLogin } from "src/features/modal"

function ItemMatch({
  title,
  team1,
  team2,
  isLive,
  minute,
  score1,
  score2,
}: any) {
  const dispath = useAppDispatch()
  const token = localStorage.getItem("token")

  const timeGame = useMemo(() => {
    if (isLive) {
      if (minute) {
        return minute + `'`
      } else {
        return "Live"
      }
    } else {
      return "Full time"
    }
  }, [isLive, minute])
  return (
    <div
      className="ls-match"
      onClick={() => {
        if (!token) {
          dispath(openLogin())
        }
      }}
    >
      <div className="ls-match__header">
        <span className="text">{title}</span>
      </div>
      <div className="ls-match__main">
        <div className="ls-match__team-home">
          <span className="text">{team1}</span>
          <div className="team-logo">
            {/* <img
              src="https://sports-cdn.world-media.cn/uploads/teamIcon/2219/c4IiDs_A2P5.png"
              alt="icon"
            /> */}
          </div>
        </div>
        <div className="ls-match__info">
          <div className="text-score">
            {score1} - {score2}
          </div>
          {/* <span className="text">07/09</span> */}
          <div className="text-time">{timeGame}</div>
        </div>
        <div className="ls-match__team-away">
          <div className="team-logo">
            {/* <img
              src="https://sports-cdn.world-media.cn/uploads/teamIcon/1251/ce0ffa7bsan.png"
              alt="icon"
            /> */}
          </div>
          <span className="text">{team2}</span>
        </div>
      </div>
    </div>
  )
}
export default ItemMatch
