import { createSlice } from "@reduxjs/toolkit"

export interface ToastState {
  toast: any
}

const initialState: ToastState = {
  toast: {},
}

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    openToast: (state, action) => {
      state.toast = action.payload
    },
  },
})

export const { openToast } =
  toastSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export default toastSlice.reducer
