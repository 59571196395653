import { Tabs, Tooltip } from "antd";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/app/store";
import useIsMobile from "src/customHook/useIsMobile";
import { ellipsisText } from "src/util/ellipsisText";


function Profile() {
    const dataUser = useSelector((state: RootState) => state.user.dataUser);
    const [visible, setVisible] = useState(false);
    const [tab, setTab] = useState('profile');
    const { isMobile } = useIsMobile(768);
    const navigate = useNavigate()

    const itemsTabs = [
        {
            label: `Tài khoản`,
            key: "profile",
        },
        {
            label: 'Lịch sử nạp tiền',
            key: "recharge",
        },
        {
            label: "Lịch sử rút tiền",
            key: "withdraw",
        },
        {
            label: "Khuyến mãi",
            key: "promotion",
        },
    ];

    const handleCopyToClipboard = () => {
        const textToCopy = `betcasino789.com/auth/register?ref=${dataUser?.code}`;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setVisible(true);
                setTimeout(function () {
                    setVisible(false);
                }, 3000);
            })
            .catch(err => {
                console.error('Error when copying to clipboard:', err);
                alert('Unable to copy to clipboard. Please try again.');
            });
    };

    return (
        <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4">

            <Tabs onChange={(e) => navigate(`/${e}`)} items={itemsTabs} defaultActiveKey={tab} />

            <div className="lazyContainer">
                <h1 className="z-2 self-end text-moon-24 text-bulma mb-5">
                    <span data-translation="profile.account">Tài khoản</span>
                </h1>
                <div className="flex gap-5 flex-col md:flex-row">
                    <div className="sc-kpDqfm sc-dAlyuH sc-eoVZPG csZnHb kRmlOF kOrTep flex-1">
                        <div className="grid h-max gap-4 rounded-moon-i-md border border-solid border-border3 p-4 py-6 [grid-area:info]">
                            <div className="grid grid-flow-row gap-4 xl:grid-flow-col">
                                <div className="grid gap-4">
                                    <div className="grid grid-flow-col grid-cols-[max-content] gap-1 align-baseline">
                                        <h3 className="text-moon-24">{dataUser?.username}</h3>
                                    </div>
                                    <div className="grid grid-flow-col grid-cols-[max-content] items-center gap-2">
                                        <span className="text-moon-14 text-trunks">
                                            {dataUser?.email}
                                        </span>
                                        <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="text-moon-24 text-roshi"
                                        >
                                            <path
                                                d="M25.5 16C25.5 21.2467 21.2467 25.5 16 25.5C10.7533 25.5 6.5 21.2467 6.5 16C6.5 10.7533 10.7533 6.5 16 6.5C21.2467 6.5 25.5 10.7533 25.5 16Z"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M11 15L15 19.5L20.2222 12.8334"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <hr className="h-px border-0 bg-beerus" />
                            <p className="text-moon-10-caption uppercase">
                                <span data-translation="profile.personal_info">THÔNG TIN CÁ NHÂN</span>
                            </p>
                            <form className="">
                                <div className="grid gap-4">
                                    {/* <div className="w-full max-w-full relative z-0">
                                        <label className="w-full block pb-2 text-moon-16 text-trunks">
                                            <span data-translation="general.name">Username</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-goku shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none before:box-border after:box-border placeholder:delay-75 placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input invalid:focus-visible:shadow-input-err invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err h-10 leading-10 rounded-moon-i-sm input-dt-shared"
                                            placeholder="username"
                                            name="username"
                                            data-testid="username"
                                            value={dataUser?.username}
                                        />
                                    </div> */}
                                    <div className="w-full max-w-full relative z-0">
                                        <label className="w-full block pb-2 text-moon-16 text-trunks">
                                            <span data-translation="profile.last_name">Số điện thoại</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-goku shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none before:box-border after:box-border placeholder:delay-75 placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input invalid:focus-visible:shadow-input-err invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err h-10 leading-10 rounded-moon-i-sm input-dt-shared"
                                            placeholder="phone number"
                                            name="phonenumber"
                                            data-testid="phonenumber"
                                            value={dataUser?.phone}
                                        />
                                    </div>
                                    {/* <div className="w-full max-w-full relative z-0">
                                        <div>
                                            <label
                                                className="w-full block pb-2 text-moon-16 text-trunks"
                                                htmlFor="lineId"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-goku shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none before:box-border after:box-border placeholder:delay-75 placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input invalid:focus-visible:shadow-input-err invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err h-10 leading-10 rounded-moon-i-sm input-dt-shared"
                                                name="email"
                                                placeholder="email"
                                                data-testid="email"
                                                value={dataUser?.email}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="w-full max-w-full relative z-0">
                                        <label className="w-full block pb-2 text-moon-16 text-trunks">
                                            <span data-translation="profile.address">Địa chỉ ví</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-goku shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none before:box-border after:box-border placeholder:delay-75 placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input invalid:focus-visible:shadow-input-err invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err h-10 leading-10 rounded-moon-i-sm input-dt-shared"
                                            name="walletaddress"
                                            placeholder="wallet address"
                                            data-testid="walletaddress"
                                            value={ellipsisText(dataUser?.wallet || '')}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className="sc-kpDqfm sc-dAlyuH csZnHb MLuZY">
                        <div className="grid gap-4 h-max rounded-moon-s-md bg-gohan p-6 max-w-[500px]">
                            <div className="relative flex flex-col gap-8 px-6 pt-6">
                                <h3 className="max-w-sm font-fraunces text-moon-32 font-light text-popo">
                                    <span data-translation="raf.invite_friends">
                                        Invite friends
                                    </span>
                                </h3>
                                <div className="grid max-w-md grid-flow-col grid-cols-[2,1] gap-2">
                                    <div>
                                        <button
                                            className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten w-full bg-goten"
                                            type="button"
                                            id="friendRefButton"
                                        >
                                            <p className="font-normal text-popo mr-4">
                                                {isMobile ? ellipsisText(`betcasino789.com/auth/register?ref=${dataUser?.code}`) : `betcasino789.com/auth/register?ref=${dataUser?.code}`}
                                            </p>
                                            <Tooltip title="COPIED" trigger="click" open={visible}>

                                                <span
                                                    aria-hidden="true"
                                                    className="text-moon-24 absolute block right-3 cursor-pointer"
                                                    onClick={handleCopyToClipboard}
                                                >
                                                    <svg
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="text-popo"
                                                    >
                                                        <path
                                                            d="M20.1923 10.1538H21.8462C22.9507 10.1538 23.8462 11.0492 23.8462 12.1538L23.8462 23.5C23.8462 24.6045 22.9507 25.5 21.8462 25.5H14.1539C13.0493 25.5 12.1539 24.6045 12.1539 23.5V21.8461M13.6154 7.23071V11.6153H9.23077M8.5 10.6853L12.6267 6.5H18.1923C19.2969 6.5 20.1923 7.39543 20.1923 8.5L20.1923 19.8462C20.1923 20.9507 19.2969 21.8462 18.1923 21.8462H10.5C9.39543 21.8462 8.5 20.9507 8.5 19.8462V10.6853Z"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                            <span
                                                aria-hidden="true"
                                                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <p className="max-w-screen-2sm text-moon-12 text-trunks">
                                    By proceeding, I agree to the Referral Terms & Conditions and understand that anyone with my invite link will be aware I use Betcasino.
                                </p>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
            {/* <div className="inline-container">
                <div className="inline-container">
                    <a href="/">
                        <p className="text-moon-12 text-trunks">
                            <img
                                alt=""
                                className=""
                                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                                height={32}
                                loading="lazy"
                            />
                        </p>
                    </a>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="m-0 text-2xl text-trunks"
                    >
                        <path
                            d="M13 10L19 16L13 22"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="inline-container">
                    <a href="/refer-a-friend">
                        <p className="text-moon-12 text-trunks">
                            <span data-translation="profile.refer-a-friend">
                                Profile
                            </span>
                        </p>
                    </a>
                </div>
            </div> */}
        </div>

    );
}
export default Profile;
