
import "./style.scss"
import { useState } from "react"
import IconDownOpen from "@assets/icons/DownOpen.svg"
import IconDownClose from "@assets/icons/DownClose.svg"

const CollapseCustom: React.FC<any> = ({ ...props }) => {

  const dataCollapse = [
    {
      id: 0,
      question: 'Hệ thống Giới thiệu Bạn bè hoạt động như thế nào?',
      answer: `<p>
          Người chơi đã đăng ký có thể tạo liên kết giới thiệu
          trên trang Giới thiệu bạn bè và gửi liên kết đến một
          người bạn chưa có tài khoản trên Betcasino.
          <br/>
              Người bạn được giới thiệu cần đăng ký thông qua liên kết
          giới thiệu này.
          <br/>
              Sau khi đăng ký, người giới thiệu sẽ thấy thanh tiến
          trình 30 ngày trên trang Giới thiệu bạn bè.
          <br/>
              Phần thưởng giới thiệu cuối cùng được xác định bằng số
          điểm mà người bạn được giới thiệu kiếm được trong 30
          ngày đầu tiên sau khi đăng ký.
          <br/>
              Người bạn được giới thiệu nhận được 1 điểm cho mỗi 1
          USDT đặt cược.
          <br/>
              Phần thưởng giới thiệu sẽ chỉ được trao cho người giới
          thiệu.Người bạn được giới thiệu sẽ được hưởng các ưu
          đãi chào mừng và tất cả các khuyến mãi khác dành riêng
          cho khách hàng mới.
        </p>`
    },
    {
      id: 1,
      question: 'Khi nào tôi sẽ nhận được phần thưởng của mình?',
      answer: `<p>
      Phần thưởng sẽ được trao sau 30 ngày kể từ khi người bạn
      được giới thiệu tạo tài khoản và sau khi hoàn tất các
      bước xem xét hay kiểm tra cần thiết.
    </p>`
    },

    {
      id: 2,
      question: 'Giải thưởng là gì?',
      answer: ` <p>
      Phần thưởng được xác định bằng số điểm mà người bạn được
      giới thiệu kiếm được trong 30 ngày đầu tiên sau khi đăng
      ký:
      <br/>
      <br/>
    </p>
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        border: "1px solid"
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            Cấp độ
          </th>
          <th
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            Điểm
          </th>
          <th
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            Phần thưởng
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            1
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            10,000 điểm
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            20 USDT tiền mặt
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            2
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            25,000 điểm
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            40 USDT tiền mặt
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            3
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            50,000 điểm
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            75 USDT tiền mặt
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            4
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            100,000 điểm
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            150 USDT tiền mặt
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            5
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            500,000 điểm
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            750 USDT tiền mặt
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            6
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            1,500,000 điểm
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            2,500 USDT tiền mặt
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            7
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            3,000,000 điểm
          </td>
          <td
            style={{
              border: "1px solid",
              textAlign: "center",
              padding: 8
            }}
          >
            5,000 USDT tiền mặt
          </td>
        </tr>
      </tbody>
    </table>`
    },
  ]


  const [open, setOpen] = useState<any>('')

  const items = dataCollapse?.map((el: any, index: number) => {

    return (
      <li className="collapse-wp" key={index}>

        <h5 className="collapse-title" onClick={() => open !== el?.id ? setOpen(el?.id) : setOpen('')}><span>{el?.question}</span><span className={open === el?.id ? 'open' : ''}><IconDownClose /></span></h5>

        {open === el?.id &&
          <p className="collapse-content"
            dangerouslySetInnerHTML={{ __html: el.answer }}
          />
        }
      </li>
    );
  });

  return (
    <div className="sc-aXZVg fKMbm">
      <ul className="CollapseCustom">
        {items}
      </ul>
    </div>
  )
}

export default CollapseCustom
