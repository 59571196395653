import "./style.scss"
import { useAppDispatch } from "src/app/hooks"
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules"
import "swiper/css"
import banner1 from "@assets/images/banner/1.webp"
import banner2 from "@assets/images/banner/2.webp"
import banner3 from "@assets/images/banner/3.webp"
import banner4 from "@assets/images/banner/4.webp"
// import banner5 from "@assets/images/banner/5.jpg"
// import banner6 from "@assets/images/banner/6.jpg"
// import banner7 from "@assets/images/banner/7.jpg"
// import banner8 from "@assets/images/banner/8.jpg"
// import banner9 from "@assets/images/banner/9.jpg"
// import banner10 from "@assets/images/banner/10.jpg"
// import banner11 from "@assets/images/banner/11.jpg"
import { Tabs } from "antd"
import type { TabsProps } from "antd"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import TabPane from "antd/es/tabs/TabPane"
import ItemMatch from "./components/ItemMatch"
import { mainAxios } from "src/services/axiosInstance"
import { SocketContext } from "src/sockets/socketInstance"
import { gameApi } from "src/api/gameApi"

const dataImage = [
  banner1,
  banner2,
  banner3,
  banner4,
  // banner5,
  // banner6,
  // banner7,
  // banner8,
  // banner9,
  // banner10,
  // banner11,
]

function HomePage() {
  const socket = useContext(SocketContext);
  const swiperRef: any = useRef(null);
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const token = localStorage.getItem("token")
  const [alignValue, setAlignValue] = useState("")
  const [dataMatch, setDataMatch] = useState([])

  const fetchApiList = async(_params?: any)=>{
    
    try {
      const response: any = await gameApi.getSoccer()
      if(response?.status){
        const data = response?.data?.[0]?.data || []
        setDataMatch(data)
      }
    } catch (error) {
      setDataMatch([])
    }

  }

  useEffect(() => {
    fetchApiList()
    if (swiperRef.current) {
      setTimeout(() => {
        swiperRef.current.swiper.autoplay.start();
      }, 3000);
    }

    socket.on("data-soccer", (msg: any) => {
      // console.log('msg', msg)
      setDataMatch(msg?.data)
    });

    return () => {
      socket.disconnect();
    };
  }, [])
  
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Hôm Nay",
    },
    // {
    //   key: "2",
    //   label: "Kết quả",
    // },
  ]

  const items2: any = [
    {
      key: "1",
      label: "Hôm Nay",
      icon: <i className="icon icon--sport50"></i>,
    },
    {
      key: "2",
      label: "Kết quả",
      icon: <i className="icon icon--sport50"></i>,
    },
  ]

  const onChange = (key: string) => {
    console.log(key)
  }

  const onChange2 = (key: string) => {
    console.log("key", key)
  }

  const filterGameDay = useMemo(() => 
    {
      return dataMatch?.slice(0,10)
    }, [dataMatch])

    const renderSwiper=useCallback(
      () => {
        return 
      },
      [dataImage],
    )
    
  return (
    <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 md:px-5 px-3 pb-0 pt-4">
      <div className="flex flex-col gap-8">
        <div className="promotions">
          <Swiper
            // spaceBetween={0}
            // slidesPerView={3}
            ref={swiperRef}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination,Autoplay]}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {dataImage?.map((v, i) => {
              return (
                <SwiperSlide key={i}>
                  <img src={v} alt="" />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="live-score">
          <Tabs
            defaultActiveKey="1"
            className="tab-header"
            items={items}
            onChange={onChange}
            centered
          />
          <Tabs
            defaultActiveKey="1"
            centered
            className="custom-tab px-4"
            onChange={onChange2}
          >
            <TabPane
              tab={
                <div className="flex gap-1 items-center">
                  <i className="icon icon--sport1"></i>
                  <span className="text">Bóng đá</span>
                </div>
              }
              key="1"
            ></TabPane>
            {/* <TabPane
              tab={
                <div className="flex gap-1 items-center">
                  <i className="icon icon--sport2"></i>
                  <span className="text">Bóng rổ</span>
                </div>
              }
              key="2"
            ></TabPane>
            <TabPane
              tab={
                <div className="flex gap-1 items-center">
                  <i className="icon icon--sport50"></i>
                  <span className="text">Cricket</span>
                </div>
              }
              key="3"
            ></TabPane> */}
          </Tabs>
          <div className="live-content">
            <div className="flex flex-wrap justify-between p-2">
              {
                filterGameDay?.map((v?:any)=> {
                  return <ItemMatch {...v} />
                })
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default HomePage
