import useIsMobile from "src/customHook/useIsMobile";

const useGameCardSize = (isBig = false, tournaments = false) => {
  // const { isDesktop, isTablet } = useAgentData();
  const { isMobile } = useIsMobile(768)

  const size = isBig ? 'big' : 'small';
  if (tournaments) {
    return sizes.desktop.tournaments;
  }
  if (!isMobile) {
    return sizes.desktop[size];
  }
  // if (isTablet) {
  //   return sizes.tablet[size];
  // }
  return sizes.mobile;
};

const sizes = {
  mobile: { width: 152, height: 116 },
  desktop: {
    big: { width: 202, height: 152 },
    small: { width: 164, height: 124 },
    tournaments: { width: 176, height: 132 },
  },
  tablet: {
    big: { width: 360, height: 272 },
    small: { width: 236, height: 176 },
  },
};

export default useGameCardSize;
