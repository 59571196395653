import { Text } from '@heathmont/moon-core';
import { Link } from 'react-router-dom';
import Img from 'src/components/Img';

type Props = {
  promotion: any;
  past?: boolean;
  showSummaryOnMobile?: boolean;
  position?: number;
  children?: any
};

const PromotionCard: React.FC<Props> = (props) => {
  const { promotion, past } = props;
  const { id, handle = '', largeImage, smallImage, link } = promotion;
  const { showSummaryOnMobile = true, children } = props;

  return (
    <Link
      className='articleLink'
      to={link || `/promotions/${handle}`}
      id={`link-${id}`}
      data-testid="promotion-card"
    >
      <>
        <Img
          style={{ borderRadius: '0.75rem' }}
          src={largeImage || smallImage}
          mono={past ? '868383' : undefined}
          useDpr2x
        />
        <div className='right-panel'>
          <div className='stack-16'>
            <h3 className='ArticleTitle'>{promotion?.name}</h3>
            <p className='ArticleSummer'>
              {promotion?.summary}
            </p>
          </div>
          <button
            className="read-more"
          >
            <div className="sc-gsFSXq eVpQll">
              <span data-translation="cms.promo.read_more">Đọc thêm</span>
            </div>
          </button>

        </div>
      </>
    </Link>
  );
};

export default PromotionCard;
