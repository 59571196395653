import { useLocation } from "react-router-dom";


const RightBlock = () => {
  const { pathname } = useLocation()

  return (
    <div className="hidden flex-grow md:block">
      <div className="relative">
        <div
          className="absolute grid h-screen w-[calc(100vw-380px)] grid-cols-minmax content-center justify-items-center gap-4 overflow-hidden bg-goku p-8 bg-[url(https://cdn.coingaming.io/bitcasino/images/casino-boosts/red-background.svg)] bg-cover"
          data-testid="login-banner"
        >
          <div className="text-trunks [&_iframe]:max-w-full [&_table]:text-base [&_img]:inline [&_p]:my-4 [&_p]:text-base [&_ol]:list-decimal [&_ol]:text-base [&_ol]:[margin-block:1rem] [&_ol]:[margin-inline:0] [&_ol]:[padding-inline-start:2.5rem] [&_ul]:list-disc [&_ul]:text-base [&_ul]:[margin-block:1rem] [&_ul]:[margin-inline:0] [&_ul]:[padding-inline-start:2.5rem] [&_[data-promotion-name]]:hidden [&_.pt-button.cms_visibility_logged_out]:hidden font-fraunces [&_p]:font-averta">
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n  .registration_image {\n    position: relative;\n    z-index: 1;\n    display: block !important;\n    height: 13.25rem;\n    width: auto;\n    max-width: 45.75rem;\n    margin: 2rem auto;\n    \n  }\n  .registration_background_pattern {\n    position: absolute;\n    top: 0;\n    right: 0;\n    display: block;\n    width: 100%;\n    height: auto;\n  }\n  .registration_title {\n    margin: 0 0 4.25rem;\n  \tfont-size: 1.5rem;\n    line-height: 2rem;\n    font-weight: 500;\n    text-align: center;\n  }\n  ul {\n    display: grid;\n    gap: 1.5rem;\n    align-items: start;\n    justify-content: center;\n    padding: 0 !important;\n    margin: 0 !important;\n    list-style-type: none;\n  }\n  .registration_option  {\n    display: grid;\n    gap: 0.75rem;\n    grid-auto-flow: column;\n  \tmax-width: 14.5rem;\n  }\n  .registration_option_image  {\n    display: block;\n    width: 2.5rem;\n    height: auto;\n  }\n  .registration_option_text {\n  \tdisplay: grid;\n    gap: 0.75rem;\n  }\n  .registration_option_text h2 {\n    margin: 0;\n  \tfont-size: 1.125rem;\n    line-height: 1.75rem;\n    font-weight: 500;\n  }\n  .registration_option_text p {\n    margin: 0;\n  \tfont-size: 0.75rem;\n    line-height: 1rem;\n  }\n  @media screen and (min-width: 992px) {\n    .registration_image {\n      height: 16.375rem;\n    }\n    .registration_title {\n      margin: 0 0 5vh;\n    \tfont-size: 3rem;\n      line-height: 3.5rem;\n    }\n    .registration_option {\n    \tgrid-auto-flow: row;\n    }\n    ul {\n    \tgrid-auto-flow: column;\n      gap: 0.75rem;\n      padding: 0 0 10vh !important;\n    }\n  }\n   @media screen and (min-width: 1200px) {\n     .registration_image {\n       width: 80%;\n       height: auto;\n    }\n    .registration_option {\n    \tgrid-auto-flow: column;\n    }\n  }\n"
              }}
            />
            <img
              alt=""
              className="registration_background_pattern"
              src="//heathmont.imgix.net/bitcasino/images/promotions/2023/pattern.png?auto=compress,format?auto=compress,format"
              loading="lazy"
            />
            <img
              alt=""
              className="registration_image"
              src={pathname === '/auth/register' ? '//heathmont.imgix.net/bitcasino/images/promotions/2023/Sing_up5.png?auto=compress,format?auto=compress,format' : 'https://heathmont.imgix.net/bitcasino/images/promotions/2023/Log-in4.png?auto=compress,format?auto=compress,format'}
              loading="lazy"
            />
            <h1 className="registration_title">
            Enjoy premium gaming
            </h1>
            <ul>
              <li className="registration_option">
                <img
                  alt=""
                  className="registration_option_image"
                  src="//heathmont.imgix.net/bitcasino/images/promotions/2023/VIP.png?auto=compress,format?auto=compress,format"
                  loading="lazy"
                />
                <div className="registration_option_text">
                  <h2>VIP experiences</h2>
                  <p>
                  Access invite-only events and red-carpet treatment
                  </p>
                </div>
              </li>
              <li className="registration_option">
                <img
                  alt=""
                  className="registration_option_image"
                  src="//heathmont.imgix.net/bitcasino/images/promotions/2023/wallet.png?auto=compress,format?auto=compress,format"
                  loading="lazy"
                />
                <div className="registration_option_text">
                  <h2>Instant withdrawal</h2>
                  <p>Experience industry-leading withdrawal speeds.</p>
                </div>
              </li>
              <li className="registration_option">
                <img
                  alt=""
                  className="registration_option_image"
                  src="//heathmont.imgix.net/bitcasino/images/promotions/2023/cashback-icon-register.png?auto=compress,format?auto=compress,format"
                  loading="lazy"
                />
                <div className="registration_option_text">
                  <h2>No bet, no reward</h2>
                  <p>Claim your reward without any wagering requirements.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightBlock;
