
function YourProgressle() {
    return (
        <div className="relative w-full pb-12 text-center text-bulma md:mx-auto">
            <div className="relative left-1/2 w-full -translate-x-1/2 md:mb-12">
                <div className="relative h-1 w-full bg-krillin" />
                <div
                    className="relative -top-4 left-1/2 -translate-x-1/2 md:-top-5"
                    style={{
                        content:
                            'url("//cdn.coingaming.io/bitcasino/images/loyalty_landing.line_diamonds.svg")'
                    }}
                />
            </div>
            <div className="mx-5 mb-4 grid gap-2">
                <h2 className="text-moon-24 font-fraunces font-light">
                    <span data-translation="loyalty_landing.your_progress">
                        Cấp độ của bạn
                    </span>
                </h2>
                <div className="text-moon-16 text-trunks">
                    <span data-translation="loyalty_landing.progress_text">
                        Bất cứ lúc nào, bạn có thể kiểm tra tiến độ cấp độ của bạn và điểm
                        kiếm được trong tài khoản của bạn.
                    </span>
                </div>
            </div>
            <div className="relative mb-8 block overflow-hidden xl:hidden first-of-type:[&_ul>li]:pl-5 last-of-type:[&_ul>li]:pr-5">
                <div data-testid="carousel-wrapper">
                    <div className="relative w-full">
                        <button
                            className="h-8 p-1 gap-1 rounded-moon-i-sm flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-moon-24 max-sm:hidden shadow-moon-sm bg-gohan text-bulma absolute top-1/2 -translate-y-1/2 origin-[top_center] z-5 -start-4"
                            type="button"
                            aria-label="Scroll left"
                        >
                            <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20.5 25L11.5 16L20.5 7"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span
                                aria-hidden="true"
                                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                            />
                        </button>
                        <ul className='flex overflow-x-auto overflow-y-hidden h-auto hidden-scroll [-webkit-overflow-scrolling:touch] [scrollbar-width:none] [-ms-overflow-style:-ms-autohiding-scrollbar] [&>li]:list-none [&>li]:before:absolute [&>li]:before:content-["\200B"] [&>*]:flex-[0_0_auto] [&>img]:h-full [&>img]:basis-auto [&>img]:w-auto snap-x snap-mandatory rtl:flex-row-reverse gap-3'>
                            <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                                        <h2 className="font-fraunces text-moon-24 text-piccolo">
                                            <span data-translation="loyalty_landing.progress_title_1">
                                                Điểm khách hàng thân thiết
                                            </span>
                                        </h2>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_first_text_1">
                                                    Bạn kiếm điểm cấp độ thân thiết với mỗi lần đặt cược
                                                    tiền thật
                                                </span>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_second_text_1">
                                                    Nhận điểm khi chơi cả hai trò chơi Khe và Bàn
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                                        <h2 className="font-fraunces text-moon-24 text-krillin">
                                            <span data-translation="loyalty_landing.progress_title_2">
                                                Đóng góp các điểm
                                            </span>
                                        </h2>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_first_text_2">
                                                    Tăng điểm của bạn theo từng cấp độ thân thiết
                                                </span>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_second_text_2">
                                                    Đặt cược mức cao hơn kiếm được nhiều điểm hơn
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                                        <h2 className="font-fraunces text-moon-24 text-cell">
                                            <span data-translation="loyalty_landing.progress_title_3">
                                                Cột mốc Phần thưởng
                                            </span>
                                        </h2>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_first_text_3">
                                                    Thợ may thực hiện các phần thưởng quan trọng dành riêng
                                                    cho bạn
                                                </span>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_second_text_3">
                                                    Nhận cược miễn phí trong các trò chơi yêu thích của bạn
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                                        <h2 className="font-fraunces text-moon-24 text-whis">
                                            <span data-translation="loyalty_landing.progress_title_4">
                                                Hoàn tiền
                                            </span>
                                        </h2>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_first_text_4">
                                                    Bạn có thể nhận được tiền hoàn lại ở bất kỳ cấp độ nào
                                                </span>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-[auto_1fr] gap-4">
                                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.progress_second_text_4">
                                                    Yêu cầu hoàn tiền của bạn ở mỗi cột mốc
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <button
                            className="h-8 p-1 gap-1 rounded-moon-i-sm flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-moon-24 max-sm:hidden shadow-moon-sm bg-gohan text-bulma absolute top-1/2 -translate-y-1/2 origin-[top_center] z-5 -end-4"
                            type="button"
                            aria-label="Scroll right"
                        >
                            <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.5 7L20.5 16L11.5 25"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span
                                aria-hidden="true"
                                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative mx-auto mb-12 hidden grid-cols-2 gap-y-4 rem:max-w-[992px] xl:grid xl:grid-cols-4 3xl:max-w-6xl">
                <img
                    alt=""
                    className="hidden lg:absolute lg:-left-24 lg:-top-16 lg:block"
                    src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.color_elements_b.svg?auto=compress,format"
                    loading="lazy"
                />
                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                        <h2 className="font-fraunces text-moon-24 text-piccolo">
                            <span data-translation="loyalty_landing.progress_title_1">
                                Điểm khách hàng thân thiết
                            </span>
                        </h2>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_first_text_1">
                                    Bạn kiếm điểm cấp độ thân thiết với mỗi lần đặt cược tiền thật
                                </span>
                            </div>
                        </div>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_second_text_1">
                                    Nhận điểm khi chơi cả hai trò chơi Khe và Bàn
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                        <h2 className="font-fraunces text-moon-24 text-krillin">
                            <span data-translation="loyalty_landing.progress_title_2">
                                Đóng góp các điểm
                            </span>
                        </h2>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_first_text_2">
                                    Tăng điểm của bạn theo từng cấp độ thân thiết
                                </span>
                            </div>
                        </div>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_second_text_2">
                                    Đặt cược mức cao hơn kiếm được nhiều điểm hơn
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                        <h2 className="font-fraunces text-moon-24 text-cell">
                            <span data-translation="loyalty_landing.progress_title_3">
                                Cột mốc Phần thưởng
                            </span>
                        </h2>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_first_text_3">
                                    Thợ may thực hiện các phần thưởng quan trọng dành riêng cho bạn
                                </span>
                            </div>
                        </div>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_second_text_3">
                                    Nhận cược miễn phí trong các trò chơi yêu thích của bạn
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-full xl:ml-4 xl:rounded-moon-s-sm xl:border xl:border-beerus xl:last-of-type:mr-4">
                    <div className="relative h-full w-80 border border-solid border-beerus p-4 px-6 py-8 text-left rem:min-h-[192px] xl:h-auto xl:w-auto xl:border-none [&>*]:mb-4 last:[&>*]:mb-0">
                        <h2 className="font-fraunces text-moon-24 text-whis">
                            <span data-translation="loyalty_landing.progress_title_4">
                                Hoàn tiền
                            </span>
                        </h2>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_first_text_4">
                                    Bạn có thể nhận được tiền hoàn lại ở bất kỳ cấp độ nào
                                </span>
                            </div>
                        </div>
                        <div className="grid grid-cols-[auto_1fr] gap-4">
                            <div className="rounded-full bg-trunks rem:mt-[10px] rem:h-[6px] rem:w-[6px] " />
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.progress_second_text_4">
                                    Yêu cầu hoàn tiền của bạn ở mỗi cột mốc
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    alt=""
                    className="hidden lg:absolute lg:-bottom-24 lg:-right-24 lg:block"
                    src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.color_elements_a.svg?auto=compress,format"
                    loading="lazy"
                />
            </div>
            <div className="grid gap-4 px-5 justify-items-center">
                <button
                    className="px-4 h-10 py-2 gap-2 rounded-moon-i-sm relative z-0 flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo text-moon-16"
                    type="button"
                >
                    <span data-translation="profile.create_account">Create account</span>
                    <span
                        aria-hidden="true"
                        className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                    />
                </button>
            </div>
        </div>
    );
}
export default YourProgressle;
