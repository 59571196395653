
function Tutorial2() {

  return (
    <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4 mian">
      <div className="lazyContainer">
        <div className="promotion">
          <div className="box">
            <div className="promotion-mian__title">
              <h1>Chế Độ Đãi Ngộ Đại Lý</h1>
            </div>
            <div className="promotion-grade">
              <div className="promotion-grade-th">
                <div className="item">
                  Level
                </div>
                <div className="item">
                  % Hoa Hồng
                </div>
                <div className="item">
                  Y/c Mốc Member
                </div>
                <div className="item">
                  Y/c Volum
                </div>
              </div>
              <div className="promotion-grade-tr">
                <div className="item">
                  0
                </div>
                <div className="item">

                </div>
                <div className="item">

                </div>
                <div className="item">

                </div>
              </div>
              <div className="promotion-grade-tr">
                <div className="item">
                  1
                </div>
                <div className="item">
                  0,5
                </div>
                <div className="item">
                  {'5->'}
                </div>
                <div className="item">
                  10.000
                </div>
              </div>
              <div className="promotion-grade-tr">
                <div className="item">
                  2
                </div>
                <div className="item">
                  1
                </div>
                <div className="item">
                  {'15->'}
                </div>
                <div className="item">
                  50.000
                </div>
              </div>
              <div className="promotion-grade-tr">
                <div className="item">
                  3
                </div>
                <div className="item">
                  1,5
                </div>
                <div className="item">
                  {'25->'}
                </div>
                <div className="item">
                  120.000
                </div>
              </div>
              <div className="promotion-grade-tr">
                <div className="item">
                  4
                </div>
                <div className="item">
                  2
                </div>
                <div className="item">
                  {'50->'}
                </div>
                <div className="item">
                  300.000
                </div>
              </div>
            </div>

            <div className="">
              <div className="promotion-txt">
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
export default Tutorial2;
