import DropDownMenu from "src/components/DropDownMenu/DropDownMenu"
import { clsx } from "clsx"
import ActionComponent from "./ActionComponent"
import logobetcasino from "@assets/images/logo-betcasino.png"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { MoneyConvert } from "src/util/format"
import useIsMobile from "src/customHook/useIsMobile"
import ModalLogin from "src/components/ModalLogin"
import { useState } from "react"
import ModalRegister from "src/components/ModalRegister"
import { useAppDispatch } from "src/app/hooks"
import { closeLogin, openLogin } from "src/features/modal"
import { useNavigate } from "react-router-dom"

const NavBar = () => {
  const dispath = useAppDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  const dataUser = useSelector((state: RootState) => state.user.dataUser)
  const isOpenLogin = useSelector((state: RootState) => state.modal.isOpenLogin)
  const { isMobile } = useIsMobile(768)
  // const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const handleLogout = () => {
    // localStorage.clear()
    localStorage.removeItem("token");
    navigate('/')
    window.location.reload()
  }

  return (
    <div className="bg-[#1f2a41] flex items-center justify-between py-1 px-4 gap-4">
      <a href="/">
        <span
          data-gtm-event-category="brand_logo"
          className="flex items-center logo-title"
        >
          <img src={logobetcasino} alt="" className="w-12" />
          BETCASINO
        </span>
      </a>
      <div className="md:flex-1">
        <DropDownMenu />
      </div>
      <div
        className={clsx(
          "hidden items-center justify-center gap-4 flex-wrap lg:flex",
          // !token && "invisible",
        )}
      >
        {
          token ? 
      
        <>
        <div className="grid cursor-pointer gap-1 text-right">
          <a href="/profile">
            <p
              className="text-moon-12 font-semibold text-white"
              data-testid="username"
            >
              {dataUser?.username}
            </p>
          </a>
          <div className="inline-container gap-1 font-averta">
            <p
              className="text-moon-12 text-trunks"
              data-testid="balance-currency"
            >
              {/* {ellipsisText(wallet)} */}
              {MoneyConvert(dataUser?.money)} VND
            </p>
          </div>
        </div>
 
        <ActionComponent />
        <a>
          <button
            onClick={handleLogout}
            className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 bg-krillin text-bulma font-normal"
            type="button"
            data-testid="tw-button"
            id="logoutButton"
          >
            <span data-translation="profile.logout">Đăng xuất</span>
            <span
              aria-hidden="true"
              className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
            />
          </button>
        </a>
        </>
          :
        <>
          <a>
            <button
              className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-bulma bg-transparent ring-inset ring-1 ring-trunks hover:ring-trunks w-full font-normal shadow-none hover:shadow-none"
              type="button"
              // onClick={() => setOpenLogin(!openLogin)}
              onClick={() => dispath(openLogin())}
            >
              <span data-translation="profile.login">Đăng nhập</span>
              <span
                aria-hidden="true"
                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
              />
            </button>
          </a>
          <a>
            <button
              className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo anim-pulse animate-[pulse2_1.5s_infinite] w-full font-normal"
              type="button"
              data-testid="tw-button"
              onClick={() => setOpenRegister(!openRegister)}

            >
              <span data-translation="profile.signup">Đăng ký</span>
              <span
                aria-hidden="true"
                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
              />
            </button>
          </a>
        </>
          }
      </div>
      <ModalLogin 
        open={isOpenLogin} setOpen={() => dispath(closeLogin())}
      />
      <ModalRegister open={openRegister} setOpen={setOpenRegister}/>

    </div>
  )
}

export default NavBar
