
function KeFuMenu() {

  return (
    <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4 mian">
      <div className="lazyContainer">
        <div data-v-72438f70="" className="mian">
          <div data-v-72438f70="" className="banner">
            <img
              data-v-72438f70=""
              width="100%"
              height="205px"
              src="https://xosovn.club/images/cskh.webp"
              className="img"
            />
          </div>
          <div data-v-72438f70="" className="list">
            {/* <div data-v-72438f70="" class="item c-row c-row-between c-row-middle" onclick="location.href='https://t.me/cskh_xosovn'">
              <div data-v-72438f70="" class="c-row c-row-middle">
                  <img data-v-72438f70="" height="35px" width="35px" src="/images/icon_Telegram.webp" class="img">
                  <div data-v-72438f70="" class="name m-l-10">Telegram</div>
              </div><i data-v-72438f70="" class="van-icon van-icon-arrow"
                  style="color: rgb(65, 65, 65); font-size: 20px;">
              </i>
          </div> */}
            <div data-v-eed3ea8f="" className="promotion-list">
              <div data-v-eed3ea8f="" className="promotion-list__container">
                <div data-v-eed3ea8f="" className="promotion-list__container-item w-full">
                  <div
                    data-v-eed3ea8f=""
                    className="promotion-list__container-item_title"
                  >
                    <span data-v-eed3ea8f="">STT</span>
                    <span data-v-eed3ea8f="">Tên đại lý</span>
                    <span data-v-eed3ea8f="">Liên hệ</span>
                  </div>
                  {/* <div
                    data-v-eed3ea8f=""
                    className="promotion-list__container-item__content"
                  >
                    <div data-v-eed3ea8f="" className="itme">
                      <div data-v-eed3ea8f="" className="icon-LV-ke">
                        1
                      </div>
                    </div>
                    <div data-v-eed3ea8f="" className="item">
                      CSKH
                    </div>
                    <div data-v-eed3ea8f="" className="item">
                      <div
                        className="icon"
                      >
                        <img src="https://xosovn.club/images/Telegram.webp" />
                      </div>
                    </div>
                  </div> */}
                  {/* <div data-v-eed3ea8f="" class="promotion-list__container-item__content">
                      <div data-v-eed3ea8f="" class="itme">
                        <div data-v-eed3ea8f="" class="icon-LV">2</div>
                      </div>
                      <div data-v-eed3ea8f="" class="item">Tổng Đại Lý Anh Thư</div>
                      <div data-v-eed3ea8f="" class="item">
                        <div class="icon" onclick="location.href='https://t.me/tongdailyxosovn01'">
                          <img src="/images/Telegram.webp" />
                        </div>
                        </div>
                    </div> */}
                  {/* <div
                    data-v-eed3ea8f=""
                    className="promotion-list__container-item__content"
                  >
                    <div data-v-eed3ea8f="" className="itme">
                      <div data-v-eed3ea8f="" className="icon-LV-ke">
                        2
                      </div>
                    </div>
                    <div data-v-eed3ea8f="" className="item">
                      Tổng Đại Lý Hắc Long
                    </div>
                    <div data-v-eed3ea8f="" className="item">
                      <div
                        className="icon"
                      >
                        <img src="https://xosovn.club/images/Telegram.webp" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            data-v-7692a079=""
            data-v-72438f70=""
            className="Loading c-row c-row-middle-center"
            style={{ display: "none" }}
          >
            <div data-v-7692a079="" className="van-loading van-loading--circular">
              <span
                data-v-7692a079=""
                className="van-loading__spinner van-loading__spinner--circular"
                style={{ color: "rgb(242, 65, 59)" }}
              >
                <svg
                  data-v-7692a079=""
                  viewBox="25 25 50 50"
                  className="van-loading__circular"
                >
                  <circle data-v-7692a079="" cx={50} cy={50} r={20} fill="none" />
                </svg>
              </span>
            </div>
          </div>
        </div>

      </div>
      <div className="inline-container">
        <div className="inline-container">
          <a href="/">
            <p className="text-moon-12 text-trunks">
              <img
                alt=""
                className=""
                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                height={32}
                loading="lazy"
              />
            </p>
          </a>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="m-0 text-2xl text-trunks"
          >
            <path
              d="M13 10L19 16L13 22"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="inline-container">
          <a href="/refer-a-friend">
            <p className="text-moon-12 text-trunks">
              <span data-translation="profile.refer-a-friend">
                Promotion
              </span>
            </p>
          </a>
        </div>
      </div>
    </div>

  );
}
export default KeFuMenu;
