import { useState } from "react";
import { userApi } from "src/api/userApi";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import logobetcasino from "@assets/images/logo-betcasino.png"

type NotificationType = 'success' | 'info' | 'warning' | 'error';

function LoginPage() {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    const openNotificationWithIcon = (type: NotificationType, message: string) => {
        api[type]({
            message: message,
            placement: 'bottomLeft'
        });
    };

    const [form, setForm] = useState({
        phone: '',
        password: '',
        uses2FA: false
    });

    const handleChange = (event: any, check?: boolean) => {
        if (check) {
            setForm({
                ...form,
                [event.target.name]: event.target.checked,
            });
        } else {
            setForm({
                ...form,
                [event.target.id]: event.target.value,
            });
        }

    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            let payload: any = {
                "phone": form.phone,
                pwd: form.password,
            }


            const response: any = await userApi.login(payload)

            if (response) {
                if (response?.status) {
                    openNotificationWithIcon('success', response?.message);
                    localStorage.setItem('token', JSON.stringify(response?.token))

                    setTimeout(() => {
                        navigate("/")
                    }, 1000)
                } else {
                    openNotificationWithIcon('error', response?.message)
                }
            }

            return response.data
        } catch (error: any) {
            return error?.response
        }
    }

    return (
        <div className="bg-goten h-screen w-full overflow-scroll p-4 scroll-hidden md:w-[380px] md:p-5">
            <div className="grid gap-4">
                <div className="lazyContainer">
                    <div className="sc-ikkxIA sc-kdBSHD iwfydD jiunLZ">
                        <div className="sc-ikkxIA sc-kdBSHD iwfydD bsfnGj">
                            <div className="grid gap-12">
                                <a className="self-center text-bulma" href="/">
                                    <span data-gtm-event-category="brand_logo" className="flex items-center logo-title">
                                        <img src={logobetcasino} alt="" className="w-12" />
                                        BETCASINO
                                    </span>
                                </a>
                                <h1 className="text-moon-24 text-bulma font-fraunces font-normal">
                                    <span data-translation="profile.login">Log in</span>
                                </h1>
                            </div>
                            <div className="sc-ikkxIA sc-kdBSHD YQvOj gbphfC mt-8">
                                <form onSubmit={handleSubmit}>
                                    <div className="sc-ikkxIA sc-kdBSHD iwfydD gbphfC">
                                        <div className="w-full max-w-full relative z-0 my-6">
                                            <div>
                                                <label
                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                    htmlFor="phone"
                                                >
                                                    Phone Number
                                                </label>
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                    name="phone"
                                                    placeholder=""
                                                    data-testid="phone"
                                                    autoCapitalize="off"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full max-w-full relative z-0 my-6">
                                            <div>
                                                <label
                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                    htmlFor="password"
                                                >
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                    name="password"
                                                    placeholder=""
                                                    data-testid="password"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <li className="sc-hZDyAQ IiJkP">
                                            <label className="check-box">
                                                <input
                                                    name="uses2FA"
                                                    data-testid="uses2FA"
                                                    className="input-checkbox"
                                                    type="checkbox"
                                                    checked={form.uses2FA}
                                                    onChange={(e) => handleChange(e, true)}
                                                />
                                                <div className="sc-bmzYkS fotCte">
                                                    <span className={`check-box ${form.uses2FA ? 'active' : ''}`}>
                                                        <div className="check-box-item" />
                                                    </span>
                                                    <p className="checkbox-title">
                                                        I use Google Authenticator
                                                    </p>
                                                </div>
                                            </label>
                                        </li>
                                        <li className="sc-hZDyAQ IiJkP my-8">
                                            <button
                                                className="sc-dCFHLb jmbuMX button-active"
                                                type="submit"
                                                disabled={!form.phone || !form.password}
                                            >
                                                <div className="sc-fhzFiK fClrap">
                                                    <span data-translation="profile.login">Log in</span>
                                                </div>
                                            </button>
                                        </li>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="sc-ikkxIA sc-kdBSHD YQvOj cymAuG">
                            <a href="/forgot-password/reset">
                                <p
                                    color="piccolo.100"

                                    className="sc-jsJBEP sc-fBdRDi fBYSmT kkgITS text-red text-moon-16"
                                >
                                    <span data-translation="profile.forgot_password">
                                        Forgot password?
                                    </span>
                                </p>
                            </a>
                            <div className="sc-jIGnZt hMiH text-red text-moon-14 flex my-2">
                                <p color="trunks.100" className="sc-jsJBEP vTpdQ text-gray text-moon-14 mr-2">
                                    <span data-translation="profile.dont_have_an_account">
                                        Don't have an account ?
                                    </span>
                                </p>
                                <a href="/auth/register?redirect=%2Fvip">
                                    <p color="piccolo.100" className="sc-jsJBEP cGlAfe">
                                        <span data-translation="profile.create_account">
                                        Create account
                                        </span>
                                    </p>
                                </a>
                            </div>
                            <div className="sc-ikkxIA sc-kdBSHD YQvOj gDUXgx mt-2">
                                <p color="trunks.100" className="sc-jsJBEP cVgxvH text-gray text-moon-12">
                                    This site is protected by reCAPTCHA and {" "}
                                    <a href="https://policies.google.com/privacy">
                                        {" "}
                                         the Google Privacy Policy{" "}
                                    </a>{" "}
                                    and{" "}
                                    <a href="https://policies.google.com/terms của Google ">
                                        {" "}
                                        Terms{" "}
                                    </a>{" "}
                                    of Service apply.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {contextHolder}
        </div>
    );
}
export default LoginPage;
