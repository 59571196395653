export const MoneyConvert = (value: any, toFixed?: boolean) => {
  if (value === undefined || value === null || value === "") return 0
  if (toFixed) {
    return Number(value)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  if (Number.isInteger(Number(value)))
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export const stringwithhyphen = (array: string[] | undefined) => {
  return array?.map((element) => element).join("-")
}

export const phoneNumberEncrypt = (phoneNumber: string) => {
  const newPhoneNumber =
    phoneNumber.substring(0, 3) +
    phoneNumber.substring(4, 8).replace(/[0-9]/g, "*") +
    phoneNumber.substring(9, phoneNumber.length)
  return newPhoneNumber
}

export const getFormatNumber = (value: number) => {
  const format =
    value &&
    value.toLocaleString('en-US', {
      minimumFractionDigits: 6,
      // style: 'currency', currency: 'EUR'
    })
  const b = format ? format.replace(/\.?0+$/, '') : 0
  return b
}

export const formatMoney = (value: any, isRound?: boolean, decimals?: number, isFormat?: boolean) => {
  // eslint-disable-next-line no-param-reassign
  value = String(value).replace(/\D{?!.}[$&+,:;=?@#|]/g, '')
  if (value.indexOf('.') < 0 && isFormat) {
    // eslint-disable-next-line no-param-reassign
    value = String(value).replace(/\D/g, '')
    const n = value.replace(/(\d{1,3})(?=(\d{3})+(?!\d))/g, '$1,')
    return n
  }
  if (value.indexOf('.') < 0) {
    return value.replace(/(\d{1,3})(?=(\d{3})+(?!\d))/g, '$1,')
  }

  if (value === '' || value === undefined || value === null) return ''
  // eslint-disable-next-line prefer-template
  const arr = (value + '').split('.')

  const natural = arr[0].replace(/(\d{1,2})(?=(\d{3})+(?!\d))/g, '$1,')
  if (isRound) return natural
  // eslint-disable-next-line prefer-template
  if (arr[1] === undefined) return natural
  // eslint-disable-next-line prefer-template
  if (decimals) return natural + '.' + arr[1].substring(0, decimals)
  // eslint-disable-next-line prefer-template
  return natural + '.' + arr[1]
}