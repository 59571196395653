import logobetcasino from "@assets/images/logo-betcasino.png"

function Welcome() {
    return (
        <div className="grid grid-cols-minmax gap-8 text-bulma">
            <div
                className="relative overflow-hidden rounded-b-moon-s-lg bg-cover pb-4 lg:p-10"
                style={{
                    backgroundImage:
                        "url(//cdn.coingaming.io/bitcasino/images/loyalty-landing/lp-bg.jpg)"
                }}
            >
                <div
                    style={{
                        backgroundImage:
                            "url(//cdn.coingaming.io/bitcasino/images/loyalty-landing/golden-pattern.png)"
                    }}
                    className="absolute inset-0 bg-cover mix-blend-lighten"
                />
                <div className="relative mx-auto grid grid-cols-[auto] pt-5 xl:max-w-7xl xl:grid-flow-col xl:grid-cols-[40%_60%] xl:text-left text-popo">
                    <div className="relative z-1 text-center xl:ml-16 xl:grid xl:content-start xl:justify-items-start xl:text-left [&_button]:hidden [&_button]:xl:flex">
                        <div className="grid gap-8 px-5 text-center font-fraunces xl:mb-6 xl:mt-40 xl:justify-self-end xl:p-0 xl:text-left 2xl:mt-44">
                            <h1 className="text-moon-32 font-normal xl:text-moon-64 xl:font-light">
                                <span data-translation="loyalty_landing.welcome">
                                    Chào mừng bạn tới CLB khách hàng thân thiết!
                                </span>
                            </h1>
                            <h3 className="text-moon-20 font-light">
                                <span data-translation="loyalty_landing.get_rewarded">
                                    Nhận thưởng khi chơi các trò chơi yêu thích của bạn!
                                </span>
                            </h3>
                        </div>
                        <div className="hidden xl:grid xl:gap-3">
                            <div className="grid grid-flow-col items-center justify-start gap-x-10">
                                <img
                                    alt=""
                                    className="w-16"
                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/fun.png?auto=compress,format&w=144"
                                    width={144}
                                    loading="eager"
                                />
                                <div className="pt-4">
                                    <h2 className="font-fraunces text-moon-32">
                                        <span data-translation="loyalty_landing.fun">Vui </span>
                                    </h2>
                                    <div className="text-trunks text-moon-18">
                                        <span data-translation="loyalty_landing.fun_text">
                                            Hoàn tiền lên đến 10% và nhận vòng quay miễn phí trên các
                                            trò chơi yêu thích của bạn.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-flow-col items-center justify-start gap-x-10">
                                <img
                                    alt=""
                                    className="w-16"
                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/fast.png?auto=compress,format&w=144"
                                    width={144}
                                    loading="eager"
                                />
                                <div className="pt-4">
                                    <h2 className="font-fraunces text-moon-32">
                                        <span data-translation="loyalty_landing.fast">Nhanh</span>
                                    </h2>
                                    <div className="text-trunks text-moon-18">
                                        <span data-translation="loyalty_landing.fast_text">
                                            Nhận phần thưởng và hệ số điểm tức thì
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-flow-col items-center justify-start gap-x-10">
                                <img
                                    alt=""
                                    className="w-16"
                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/fair.png?auto=compress,format&w=144"
                                    width={144}
                                    loading="eager"
                                />
                                <div className="pt-4">
                                    <h2 className="font-fraunces text-moon-32">
                                        <span data-translation="loyalty_landing.fair">Công bằng</span>
                                    </h2>
                                    <div className="text-trunks text-moon-18">
                                        <span data-translation="loyalty_landing.fair_text">
                                            Phần thưởng bằng tiền thật cho mọi người và không yêu cầu
                                            đặt cược để nhận
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative z-0 mx-4 bg-none bg-center bg-no-repeat [&_>_button]:block">
                        <div className="theme-bitcasino-light">
                            <div className="lazyContainer">
                                <img
                                    alt=""
                                    className="absolute -right-12 z-1 hidden xl:block top-16 rem:w-[510px] 2xl:-right-12 2xl:rem:w-[510px] rtl:right-auto rtl:-left-14 rtl:-scale-x-100"
                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/top.png?auto=compress,format"
                                    loading="eager"
                                />
                                <img
                                    alt=""
                                    className="absolute right-0 -bottom-6 z-2 hidden animate-[wiggle_2s_ease-in-out_3] xl:block w-72"
                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/sword.png?auto=compress,format"
                                    loading="eager"
                                />
                                <img
                                    alt=""
                                    className="absolute bottom-0 hidden animate-[zoominout_2s_ease-in-out_4] xl:block right-24 w-12"
                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/ball.png?auto=compress,format"
                                    loading="eager"
                                />
                                <img
                                    alt=""
                                    className="absolute hidden animate-[zoominout_2s_ease-in-out_4] xl:block bottom-8 right-4 w-20"
                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/element.png?auto=compress,format"
                                    loading="eager"
                                />
                                <div
                                    className="relative mb-8 w-full rounded-moon-s-sm bg-gohan p-4 rem:max-w-[368px] [&_>_div]:first-of-type:relative [&_>_div]:first-of-type:z-1 lg:mx-auto lg:mb-10 lg:mt-40 lg:p-8 xl:mt-40 xl:p-8"
                                    id="sign-up"
                                >
                                    <div className="sc-dhKdcB sc-kpDqfm oflqs eLAABE">
                                        <div className="grid content-start gap-6">
                                            <div className="grid gap-12">
                                                <a className="self-center text-bulma" href="/">
                                                    <span data-gtm-event-category="brand_logo" className="flex items-center logo-title">
                                                        <img src={logobetcasino} alt="" className="w-12" />
                                                        BETCASINO
                                                    </span>
                                                </a>
                                                <h1 className="text-moon-24 text-bulma font-fraunces font-normal">
                                                    <span data-translation="profile.signup">Sign up</span>
                                                </h1>
                                            </div>
                                            <div className="grid gap-2">
                                                <form>
                                                    <div className="sc-dhKdcB oflqs">
                                                        <div className="w-full max-w-full relative z-0">
                                                            <div>
                                                                <label
                                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                                    htmlFor="username"
                                                                >
                                                                    Username
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="username"
                                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                                    name="username"
                                                                    placeholder=""
                                                                    data-testid="username"
                                                                    autoCapitalize="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-full max-w-full relative z-0">
                                                            <div>
                                                                <label
                                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                                    htmlFor="email"
                                                                >
                                                                    E-mail
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                                    name="email"
                                                                    placeholder=""
                                                                    data-testid="email"
                                                                    autoCapitalize="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        <li className="sc-dAlyuH bnGnEl">
                                                            <div className="sc-dhKdcB sc-kpDqfm jdKwkA fhZtSN grid">
                                                                <div className="flow-column time-item grid">
                                                                    <label className="sc-fHjqPf">
                                                                        <span className="title-select">Ngày</span>
                                                                        <select
                                                                            name="day"
                                                                            data-testid="day"
                                                                            className="sc-feUZmu sc-fUnMCh select-custom"
                                                                        >
                                                                            <option value="" />
                                                                            <option value={1}>01</option>
                                                                            <option value={2}>02</option>
                                                                            <option value={3}>03</option>
                                                                            <option value={4}>04</option>
                                                                            <option value={5}>05</option>
                                                                            <option value={6}>06</option>
                                                                            <option value={7}>07</option>
                                                                            <option value={8}>08</option>
                                                                            <option value={9}>09</option>
                                                                            <option value={10}>10</option>
                                                                            <option value={11}>11</option>
                                                                            <option value={12}>12</option>
                                                                            <option value={13}>13</option>
                                                                            <option value={14}>14</option>
                                                                            <option value={15}>15</option>
                                                                            <option value={16}>16</option>
                                                                            <option value={17}>17</option>
                                                                            <option value={18}>18</option>
                                                                            <option value={19}>19</option>
                                                                            <option value={20}>20</option>
                                                                            <option value={21}>21</option>
                                                                            <option value={22}>22</option>
                                                                            <option value={23}>23</option>
                                                                            <option value={24}>24</option>
                                                                            <option value={25}>25</option>
                                                                            <option value={26}>26</option>
                                                                            <option value={27}>27</option>
                                                                            <option value={28}>28</option>
                                                                            <option value={29}>29</option>
                                                                            <option value={30}>30</option>
                                                                            <option value={31}>31</option>
                                                                        </select>
                                                                    </label>
                                                                    <label className="sc-fHjqPf">
                                                                        <span className="title-select">Tháng</span>
                                                                        <select
                                                                            name="month"
                                                                            data-testid="month"
                                                                            className="sc-feUZmu sc-fUnMCh select-custom"
                                                                        >
                                                                            <option value="" />
                                                                            <option value={1}>01</option>
                                                                            <option value={2}>02</option>
                                                                            <option value={3}>03</option>
                                                                            <option value={4}>04</option>
                                                                            <option value={5}>05</option>
                                                                            <option value={6}>06</option>
                                                                            <option value={7}>07</option>
                                                                            <option value={8}>08</option>
                                                                            <option value={9}>09</option>
                                                                            <option value={10}>10</option>
                                                                            <option value={11}>11</option>
                                                                            <option value={12}>12</option>
                                                                        </select>
                                                                    </label>
                                                                    <label className="sc-fHjqPf">
                                                                        <span className="title-select">Năm</span>
                                                                        <select
                                                                            name="year"
                                                                            data-testid="year"
                                                                            className="sc-feUZmu sc-fUnMCh select-custom"
                                                                        >
                                                                            <option value="" />
                                                                            <option value={2005}>2005</option>
                                                                            <option value={2004}>2004</option>
                                                                            <option value={2003}>2003</option>
                                                                            <option value={2002}>2002</option>
                                                                            <option value={2001}>2001</option>
                                                                            <option value={2000}>2000</option>
                                                                            <option value={1999}>1999</option>
                                                                            <option value={1998}>1998</option>
                                                                            <option value={1997}>1997</option>
                                                                            <option value={1996}>1996</option>
                                                                            <option value={1995}>1995</option>
                                                                            <option value={1994}>1994</option>
                                                                            <option value={1993}>1993</option>
                                                                            <option value={1992}>1992</option>
                                                                            <option value={1991}>1991</option>
                                                                            <option value={1990}>1990</option>
                                                                            <option value={1989}>1989</option>
                                                                            <option value={1988}>1988</option>
                                                                            <option value={1987}>1987</option>
                                                                            <option value={1986}>1986</option>
                                                                            <option value={1985}>1985</option>
                                                                            <option value={1984}>1984</option>
                                                                            <option value={1983}>1983</option>
                                                                            <option value={1982}>1982</option>
                                                                            <option value={1981}>1981</option>
                                                                            <option value={1980}>1980</option>
                                                                        </select>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <div className="my-6">
                                                            <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-end gap-4">
                                                                <div className="grid">
                                                                    <label className="sc-fHjqPf">
                                                                        <span className="title-select">
                                                                            Country Code
                                                                        </span>
                                                                        <select
                                                                            placeholder=""
                                                                            data-testid="countryCode"
                                                                            className="sc-feUZmu sc-fUnMCh select-custom country-input sc-dtInlm kOIRpr"
                                                                        >
                                                                            <option value="" />
                                                                            <option label="US (+1)" value="US">
                                                                                US (+1)
                                                                            </option>
                                                                            <option label="VN (+84)" value="VN">
                                                                                VN (+84)
                                                                            </option>
                                                                        </select>
                                                                    </label>
                                                                </div>
                                                                <div className="w-full max-w-full relative z-0">
                                                                    <div>
                                                                        <label
                                                                            className="w-full block pb-2 text-moon-16 text-trunks"
                                                                            htmlFor=""
                                                                        >
                                                                            Phone Number
                                                                        </label>
                                                                        <input
                                                                            type="tel"
                                                                            id=""
                                                                            className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                                            name=""
                                                                            placeholder=""
                                                                            data-testid="phone"
                                                                            defaultValue=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p
                                                                data-testid="phone-form-error"
                                                                role="alert"
                                                                className="sc-gFqAkR cosowq"
                                                            />
                                                        </div>
                                                        <div className="w-full max-w-full relative z-0">
                                                            <div>
                                                                <label
                                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                                    htmlFor="password"
                                                                >
                                                                    Password
                                                                </label>
                                                                <input
                                                                    type="password"
                                                                    id="password"
                                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                                    name="password"
                                                                    placeholder=""
                                                                    data-testid="password"
                                                                />
                                                            </div>
                                                        </div>
                                                        <li className="sc-dAlyuH bnGnEl my-4">
                                                            <label className="check-box">
                                                                <input
                                                                    name="acceptsTerms"
                                                                    data-testid="acceptsTerms"
                                                                    className="input-checkbox"
                                                                    type="checkbox"
                                                                />
                                                                <div className="sc-cPiKLX bdFipL">
                                                                    <span className="check-box">
                                                                        <div className="check-box-item" />
                                                                    </span>
                                                                    <p className="checkbox-title">
                                                                        Tôi đồng ý với Điều khoản &amp; Điều kiện và
                                                                        Chính sách Bảo mật
                                                                    </p>
                                                                </div>
                                                            </label>
                                                        </li>
                                                        <li className="sc-dAlyuH bnGnEl my-4">
                                                            <label className="check-box">
                                                                <input
                                                                    name="acceptsTerms"
                                                                    data-testid="acceptsTerms"
                                                                    className="input-checkbox"
                                                                    type="checkbox"
                                                                />
                                                                <div className="sc-cPiKLX bdFipL">
                                                                    <span className="check-box">
                                                                        <div className="check-box-item" />
                                                                    </span>
                                                                    <p className="checkbox-title">
                                                                        Tôi đồng ý nhận thông tin tiếp thị về các phần thưởng và ưu đãi độc quyền của Betcasino
                                                                    </p>
                                                                </div>
                                                            </label>
                                                        </li>
                                                        {/* <li
                                                            data-testid="recaptcha"
                                                            className="sc-dAlyuH bnGnEl"
                                                        >
                                                            <div
                                                                data-testid="recaptcha-form-error"
                                                                className="sc-kOPcWz ORZrD"
                                                            >
                                                                <div id="" className="g-recaptcha">
                                                                    <div style={{ width: 304, height: 78 }}>
                                                                        <div>
                                                                            <iframe
                                                                                title="reCAPTCHA"
                                                                                width={304}
                                                                                height={78}
                                                                                role="presentation"
                                                                                name="a-q74m28moowng"
                                                                                frameBorder={0}
                                                                                scrolling="no"
                                                                                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                                                                src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6Lcalj4UAAAAAPrrtyU1Oomh5Z78mHaH4Xd68s2j&co=aHR0cHM6Ly9iaXRjYXNpbm8uaW86NDQz&hl=vi&v=fGZmEzpfeSeqDJiApS_XZ4Y2&theme=light&size=normal&cb=u2lkt8wy74l9"
                                                                            />
                                                                        </div>
                                                                        <textarea
                                                                            id="g-recaptcha-response"
                                                                            name="g-recaptcha-response"
                                                                            className="g-recaptcha-response"
                                                                            style={{
                                                                                width: 250,
                                                                                height: 40,
                                                                                border: "1px solid rgb(193, 193, 193)",
                                                                                margin: "10px 25px",
                                                                                padding: 0,
                                                                                resize: "none",
                                                                                display: "none"
                                                                            }}
                                                                            defaultValue={""}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p role="alert" className="sc-gFqAkR cosowq" />
                                                        </li> */}
                                                        <p role="alert" className="sc-gFqAkR cosowq" />
                                                        <li className="sc-dAlyuH bnGnEl my-8">
                                                            <button
                                                                className="sc-fhzFiK iXBvlY sc-tagGq blRYfq button-active"
                                                                data-testid="register-submit"
                                                            >
                                                                <div className="sc-jxOSlx gMqhfH ">
                                                                    <span data-translation="profile.create_account">
                                                                    Create account
                                                                    </span>
                                                                </div>
                                                            </button>
                                                        </li>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="sc-dhKdcB sc-kpDqfm kJvqhw iJekll mb-8">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline cursor-pointer bg-transparent p-0 font-[inherit] no-underline text-trunks hover:text-piccolo my-6"
                                                href="/help-center/help-terms-and-conditions/bitcasino-terms-and-conditions-of-use-of-services-of-the-company"
                                            >
                                                <p
                                                    color="piccolo.100"
                                                    className="sc-jlZhew kSjvPM text-red text-moon-14"
                                                >
                                                    <span data-translation="profile.terms_and_conditions">
                                                        Terms & Conditions
                                                    </span>
                                                </p>
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline cursor-pointer bg-transparent p-0 font-[inherit] no-underline text-trunks hover:text-piccolo my-6"
                                                href="/help-center/help-terms-and-conditions/bitcasino-privacy-policy"
                                            >
                                                <p
                                                    color="piccolo.100"
                                                    className="sc-jlZhew kSjvPM text-red text-moon-14"
                                                >
                                                    <span data-translation="support.privacy_policy">
                                                    Privacy Policy
                                                    </span>
                                                </p>
                                            </a>
                                            <div className="sc-Nxspf gIzJbt text-red text-moon-14 flex">
                                                <p

                                                    color="trunks.100"
                                                    className="sc-jlZhew jcBjDG text-gray text-moon-14 mr-2"
                                                >
                                                    <span data-translation="general.already_have_an_account">
                                                        Already have an account ?
                                                    </span>
                                                </p>
                                                <a href="/auth/login">
                                                    <p

                                                        color="piccolo.100"
                                                        className="sc-jlZhew kSjvPM"
                                                    >
                                                        <span data-translation="profile.login">
                                                        Log in
                                                        </span>
                                                    </p>
                                                </a>
                                            </div>
                                            <div className="sc-dhKdcB sc-kpDqfm kJvqhw bmZFbe mt-2">
                                                <p
                                                    color="trunks.100"
                                                    className="sc-jlZhew dBcWxl text-gray text-moon-12"
                                                >
                                                    This site is protected by reCAPTCHA and {" "}
                                                    <a href="https://policies.google.com/privacy">
                                                        {" "}
                                                         the Google Privacy Policy{" "}
                                                    </a>{" "}
                                                    and{" "}
                                                    <a href="https://policies.google.com/terms của Google ">
                                                        {" "}
                                                        Terms{" "}
                                                    </a>{" "}
                                                    of Service apply.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="-mt-9 flex w-1/2 justify-center">
                                            <div className="w-full relative">
                                                <div
                                                    className="relative"
                                                    data-testid="language-switcher"
                                                    data-headlessui-state=""
                                                >
                                                    <button
                                                        className="w-full"
                                                        id="headlessui-listbox-button-:r0:"
                                                        type="button"
                                                        aria-haspopup="listbox"
                                                        aria-expanded="false"
                                                        data-headlessui-state=""
                                                    >
                                                        <div className="relative" />
                                                    </button>
                                                    <button
                                                        id="headlessui-listbox-button-:r1:"
                                                        aria-haspopup="listbox"
                                                        aria-expanded="false"
                                                        data-headlessui-state=""
                                                        className="flex items-center justify-between gap-2 w-full bg-gohan border-beerus shadow-input hover:shadow-input-hov transition-shadow duration-200 focus:shadow-input-focus focus:outline-none h-10 py-2 px-3 rounded-moon-i-sm [&_span]:text-trunks"
                                                    >
                                                        <span className="flex w-full flex-col items-start text-start overflow-hidden">
                                                            <span className="text-moon-16 w-full text-bulma truncate">
                                                                English
                                                            </span>
                                                        </span>
                                                        <svg
                                                            width="1em"
                                                            height="1em"
                                                            viewBox="0 0 32 32"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="text-trunks flex-shrink-0 transition-transform text-moon-24"
                                                        >
                                                            <path
                                                                d="M22 13L16 19L10 13"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Welcome;
