import { Carousel } from '@heathmont/moon-core-tw';

type Props = {
  items?: any;
};

const CarouselContent: React.FC<Props> = ({ items }) => {
  if (!items) {
    return null;
  }
  if (typeof items === 'function') {
    return <>{items}</>;
  }
  return (
    <>
      {items.map((item: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Carousel.Item key={index} className="bg-transparent">
          {item}
        </Carousel.Item>
      ))}
    </>
  );
};

export default CarouselContent;
