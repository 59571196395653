import { mergeClassnames } from '@heathmont/moon-core-tw';
import { Link } from 'react-router-dom';
import TextTrimmer from 'src/components/TextTrimmer';
import useIsMobile from 'src/customHook/useIsMobile';

type Props = { game: any; href?: string };

const GameName: React.FC<Props> = ({ game, href }) => {
  const { isMobile } = useIsMobile(768)

  const fontSize = isMobile ? 'text-moon-14' : 'text-moon-18';
  return (
    <Link to={href ?? game.href ?? ''}>
      <TextTrimmer>
        <p
          className={mergeClassnames(
            'text-moon-14 font-semibold text-bulma',
            `font-fraunces font-light ${fontSize}`,
          )}
        >
          {game.name}
        </p>
      </TextTrimmer>
    </Link>
  );
};

export default GameName;
