import CollapseCustom from "src/components/CollapseCustom";

function ReferFriend() {
    return (
        <div className="mx-auto mb-12 mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4">
            <div className="lazyContainer">
                <div>
                    <div className="flex w-full gap-4 rounded-3xl bg-[#fbf7f7] h-[360px]">
                        <div className="relative flex w-3/4 flex-col gap-8 px-6 pt-6">
                            <h3 className="max-w-sm font-fraunces text-moon-32 font-light text-popo">
                                <span data-translation="raf.invite_friends">
                                    Mời bạn bè và nhận tới 5.000 USDT
                                </span>
                            </h3>
                            <p className="text-moon-16 text-trunks">
                                <span data-translation="raf.join">
                                    Betcasino.io tốt hơn khi có bạn bè! Hãy tham gia vào Các cuộc đua,
                                    ưu đãi và dự đoán giá Bitcoin.
                                </span>
                            </p>
                            <div>
                                <a href="/auth/register?redirect=%2Frefer-a-friend">
                                    <button
                                        className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo font-normal"
                                        type="button"
                                    >
                                        <span data-translation="raf.sign_up_button">
                                            Hãy đăng ký và mời bạn bè
                                        </span>
                                        <span
                                            aria-hidden="true"
                                            className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                        />
                                    </button>
                                </a>
                            </div>
                            <p className="max-w-screen-2sm text-moon-12 text-trunks">
                                Bằng cách tiếp tục, tôi đồng ý với Điều khoản &amp; Điều kiện giới
                                thiệu và hiểu rằng bất kỳ ai có liên kết mời của tôi sẽ biết tôi sử
                                dụng Betcasino.
                            </p>
                        </div>
                        <div className="relative h-full w-full">
                            <div className="absolute inset-0">
                                <img
                                    alt=""
                                    className="h-full w-full object-cover"
                                    src="//heathmont.imgix.net/bitcasino/images//refer-a-friend/gold-element.png?auto=compress,format"
                                    loading="lazy"
                                />
                            </div>
                            <div className="absolute inset-0">
                                <img
                                    alt=""
                                    className="h-full w-full object-cover"
                                    src="//heathmont.imgix.net/bitcasino/images//refer-a-friend/faded-background.png?auto=compress,format"
                                    loading="lazy"
                                />
                            </div>
                            <div className="absolute inset-0 flex justify-end">
                                <img
                                    alt=""
                                    className="h-full w-1/2 object-cover"
                                    src="//heathmont.imgix.net/bitcasino/images//refer-a-friend/speaker.svg?auto=compress,format"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full flex-col gap-4 md:mt-8 md:gap-10">
                        <span className="w-full font-fraunces text-2xl font-light text-bulma md:ml-6 md:text-center md:text-3xl">
                            <span data-translation="raf.how_it_works.title">
                                Cách thức hoạt động như thế nào?
                            </span>
                        </span>
                        <div className="flex w-full md:flex-col">
                            <div className="relative flex items-center justify-center md:w-full md:flex-col">
                                <span className="absolute h-52 w-1 md:h-[2px] md:w-[70%] styles_dottedLine__IQKOf hidden" />
                            </div>
                            <div className="flex flex-col justify-center gap-8 md:flex-row md:gap-4">
                                <div className="max-w-sm gap-4 md:flex-col md:items-center md:p-4 md:px-2 md:text-center grid text-center md:max-w-xs">
                                    <div className="flex justify-center">
                                        <img
                                            alt=""
                                            className="w-52"
                                            src="//heathmont.imgix.net/bitcasino/images//refer-a-friend/raf-step-1.png?auto=compress,format"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-1 md:pt-0">
                                        <p className="mb-2 font-averta text-base font-semibold md:mb-3 md:mt-8 md:text-xl">
                                            <span data-translation="raf.how_it_works.step_title_1">
                                                Hãy chia sẻ liên kết để mời của bạn
                                            </span>
                                        </p>
                                        <p className="font-averta text-sm text-trunks md:px-12 md:text-base">
                                            <span data-translation="raf.how_it_works.step_title_descrition_1">
                                                Hãy giới thiệu một người bạn mới đến với Betcasino và đảm
                                                bảo rằng họ sử dụng liên kết của bạn để đăng ký.
                                            </span>
                                        </p>
                                        <p className="cursor-pointer text-moon-14 font-bold text-piccolo underline md:text-center" />
                                    </div>
                                    <div />
                                </div>
                                <div className="max-w-sm gap-4 md:flex-col md:items-center md:p-4 md:px-2 md:text-center grid text-center md:max-w-xs">
                                    <div className="flex justify-center">
                                        <img
                                            alt=""
                                            className="w-52"
                                            src="//heathmont.imgix.net/bitcasino/images//refer-a-friend/raf-step-2.png?auto=compress,format"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-1 md:pt-0">
                                        <p className="mb-2 font-averta text-base font-semibold md:mb-3 md:mt-8 md:text-xl">
                                            <span data-translation="raf.how_it_works.step_title_2">
                                                Tăng cấp và nhận
                                            </span>
                                        </p>
                                        <p className="font-averta text-sm text-trunks md:px-12 md:text-base">
                                            <span data-translation="raf.how_it_works.step_title_descrition_2">
                                                Quá trình 30 ngày bắt đầu ngay sau khi người dùng được giới
                                                thiệu đã tạo một tài khoản.
                                            </span>
                                        </p>
                                        <p className="cursor-pointer text-moon-14 font-bold text-piccolo underline md:text-center" />
                                    </div>
                                    <div />
                                </div>
                                <div className="max-w-sm gap-4 md:flex-col md:items-center md:p-4 md:px-2 md:text-center grid text-center md:max-w-xs">
                                    <div className="flex justify-center">
                                        <img
                                            alt=""
                                            className="w-52"
                                            src="//heathmont.imgix.net/bitcasino/images//refer-a-friend/raf-step-3.png?auto=compress,format"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="flex flex-col pt-1 md:pt-0">
                                        <p className="mb-2 font-averta text-base font-semibold md:mb-3 md:mt-8 md:text-xl">
                                            <span data-translation="raf.how_it_works.step_title_3">
                                                Nhận 5,000 USDT
                                            </span>
                                        </p>
                                        <p className="font-averta text-sm text-trunks md:px-12 md:text-base">
                                            <span data-translation="raf.how_it_works.step_title_descrition_3">
                                                Mức độ hoạt động của người bạn của bạn sẽ quyết định phần
                                                thưởng của bạn.
                                            </span>
                                        </p>
                                        <p className="cursor-pointer text-moon-14 font-bold text-piccolo underline md:text-center">
                                            <span data-translation="raf.see_the_rules">
                                                Xem các quy tắc
                                            </span>
                                        </p>
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center py-4">
                        <div>
                            <a href="/auth/register?redirect=%2Frefer-a-friend">
                                <button
                                    className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo font-normal"
                                    type="button"
                                >
                                    <span data-translation="raf.sign_up_button">
                                        Hãy đăng ký và mời bạn bè
                                    </span>
                                    <span
                                        aria-hidden="true"
                                        className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                    />
                                </button>
                            </a>
                        </div>
                    </div>
                    <hr className="relative my-6 h-px w-full bg-beerus" />
                    <div className="flex justify-center">
                        <div className="grid gap-6">
                            <div className="grid grid-flow-col">
                                <h3 className="font-fraunces text-moon-24 font-light text-center">
                                    <span data-translation="raf_question">raf_question</span>
                                </h3>
                            </div>
                            <div className="flex max-w-xl flex-col self-start rounded-lg border border-beerus bg-goku min-w-[720px] border-none px-4">
                                <div className="grid gap-4">
                                    <div>
                                        <CollapseCustom/>
                                    </div>
                                </div>
                                <div className="py-2">
                                    <div id="show-more" className="sc-gEvEer sc-fqkvVR jZERKg gQycbC show-more">
                                        <span color="bulma.100" className="sc-eqUAAy ggBgnA">
                                            {" "}
                                            Show more
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inline-container">
                <div className="inline-container">
                    <a href="/">
                        <p className="text-moon-12 text-trunks">
                            <img
                                alt=""
                                className=""
                                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                                height={32}
                                loading="lazy"
                            />
                        </p>
                    </a>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="m-0 text-2xl text-trunks"
                    >
                        <path
                            d="M13 10L19 16L13 22"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="inline-container">
                    <a href="/refer-a-friend">
                        <p className="text-moon-12 text-trunks">
                            <span data-translation="profile.refer-a-friend">
                                Giới thiệu một người bạn
                            </span>
                        </p>
                    </a>
                </div>
            </div>
        </div>

    );
}
export default ReferFriend;
