import { SearchOutlined } from '@ant-design/icons';

function MyTeam() {
    return (
        <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4 mian">
            <div className="lazyContainer">
                <div data-v-0ff6946a="" className="promotion">
                    <div data-v-0ff6946a="" className="box">
                        <div data-v-74b533e9="" data-v-78d66333="" className="searchbar-container">
                            <input
                                data-v-74b533e9=""
                                type="text"
                                auto-complete="new-password"
                                autoComplete="off"
                                className="searchbar-container__searchbar ipt"
                                placeholder="Tìm kiếm UID cấp dưới"
                                maxLength={30}
                            />
                        </div>
                        <div data-v-0ff6946a="" className="c-row c-row-between m-t-10 filter flex">
                            <div data-v-399e003d="" className="item">
                                <select className="form-control ipt" id="selectBac">
                                    <option value={1}>
                                        F1
                                    </option>
                                    <option value={2}>F2</option>
                                    <option value={3}>F3</option>
                                    <option value={4}>F4</option>
                                    <option value={5}>F5</option>
                                    <option value={6}>F6</option>
                                </select>
                            </div>
                            <span className="searchIcon btn-search search-select w-[40px] h-[40px] cursor-pointer">
                                <SearchOutlined />
                            </span>
                        </div>
                        <div data-v-78d66333="" className="header-container">
                            <div data-v-78d66333="">
                                <div data-v-78d66333="" className="num" id="fRecharge">
                                    0
                                </div>
                                <div data-v-78d66333="">Số người nạp tiền</div>
                            </div>
                            <div data-v-78d66333="" className="line">
                                <div data-v-78d66333="" className="num" id="fRechargeMoney">
                                    0&nbsp;₫
                                </div>
                                <div data-v-78d66333="">Số tiền nạp</div>
                            </div>
                            <div data-v-78d66333="">
                                <div data-v-78d66333="" className="num" id="fBet">
                                    0
                                </div>
                                <div data-v-78d66333="">số người đặt cược</div>
                            </div>
                            <div data-v-78d66333="" className="line">
                                <div data-v-78d66333="" className="num" id="fBetMoney">
                                    0&nbsp;₫
                                </div>
                                <div data-v-78d66333="">Tiền cược</div>
                            </div>
                            <div data-v-78d66333="">
                                <div data-v-78d66333="" className="num" id="fNapdau">
                                    0
                                </div>
                                <div data-v-78d66333="">Số người nạp đầu</div>
                            </div>
                            <div data-v-78d66333="" className="line">
                                <div data-v-78d66333="" className="num" id="fNapdauMoney">
                                    0&nbsp;₫
                                </div>
                                <div data-v-78d66333="">Nạp đầu</div>
                            </div>
                        </div>
                        <div
                            data-v-adddb413=""
                            data-v-78d66333=""
                            className="infiniteScroll"
                            id="refreshdad78369470b4102926434aa9f721cde"
                        />
                    </div>
                </div>

            </div>
            <div className="inline-container">
                <div className="inline-container">
                    <a href="/">
                        <p className="text-moon-12 text-trunks">
                            <img
                                alt=""
                                className=""
                                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                                height={32}
                                loading="lazy"
                            />
                        </p>
                    </a>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="m-0 text-2xl text-trunks"
                    >
                        <path
                            d="M13 10L19 16L13 22"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="inline-container">
                    <a href="/refer-a-friend">
                        <p className="text-moon-12 text-trunks">
                            <span data-translation="profile.refer-a-friend">
                                Promotion
                            </span>
                        </p>
                    </a>
                </div>
            </div>
        </div>

    );
}
export default MyTeam;
