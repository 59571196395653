import { Outlet } from "react-router-dom"
import RightBlock from "./styles/RightBlock"

function LoginRegisterLayout() {
  return (
    <div className="theme-bitcasino-light">
      <div className="flex">
        <Outlet />
        <RightBlock />
      </div>
    </div>
  )
}

export default LoginRegisterLayout
