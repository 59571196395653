import ProviderGames from './ProviderGames';
import ProviderInfo from './ProviderInfo';

type Props = {
  name: string;
  handle: string;
  count: number;
  games: any[];
  sendToGtm?: (action?: string) => void;
};

const ProviderCardDesktop: React.FC<Props> = ({
  name,
  count,
  handle,
  games,
  sendToGtm,
}) => {

  return (
    <div className='ProviderCardContainer'>
      <ProviderInfo
        name={name}
        handle={handle}
        count={count}
        sendToGtm={sendToGtm}
      />
      <ProviderGames games={games} sendToGtm={sendToGtm} />
    </div>
  );
};

export default ProviderCardDesktop;
