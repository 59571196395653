import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
// import counterReducer from "../features/counter/counterSlice"
import { combineReducers } from "redux"
import { PERSIST, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import drawerSlice from "src/features/drawer/drawerSlice"
import headerSlice from "src/features/header/headerSlice"
import paymentFormSlice from "src/features/paymentForm/paymentFormSlice"
import userSlice from "src/features/user/userSlice"
import languageSelectedSlice from "src/features/languageSelected/languageSelectedSlice"
import openToastSlice from "src/features/openToast/openToastSlice"
import modalSlice from "src/features/modal"

// import { PERSIST } from "@reduxjs/toolkit/constants/actionTypes";

const rootPersistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth"],
}

const rootReducer = combineReducers({
  // counter: counterReducer,
  paymentForm: paymentFormSlice,
  drawer: drawerSlice,
  header: headerSlice,
  user: persistReducer({ key: "user", storage }, userSlice),
  langSelected: languageSelectedSlice,
  toast: openToastSlice,
  modal: modalSlice,
})
const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const persistor = persistStore(store)
