import { Link } from 'react-router-dom';
import Img from 'src/components/Img';

type Props = {
  name: string;
  handle: string;
  count: number;
  sendToGtm?: (action?: string) => void;
};




const ProviderInfo: React.FC<Props> = ({ name, handle, count, sendToGtm }) => (
  <div className='stack-desktop'>
    <Link
      to={`/providers/${handle}`}
      onClick={() => sendToGtm}
    >
      <>
        <Img
          style={{
            height:'56px'
          }}
          src={`https://heathmont.imgix.net/bitcasino/images/providers/${handle}.svg`}
          alt={name}
          height={56}
        />
        <h4 className='desktop-heading' color="trunks.100">
          {name}
        </h4>
        <span color="trunks.100" className='count-mobile'>
          {count} trò chơi
        </span>
      </>
    </Link>
    <Link
      to={`/providers/${handle}`}
      onClick={() => sendToGtm}
    >
      <span className='button-all-provider'>
        Hiển thị toàn bộ các trò chơi
      </span>
    </Link>
  </div>
);

export default ProviderInfo;
