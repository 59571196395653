import { Modal, notification } from "antd"
import { useEffect, useMemo, useState } from "react"
import "./style.scss"
import { userApi } from "src/api/userApi"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useAppDispatch } from "src/app/hooks"
import { closeLogin, openLogin } from "src/features/modal"

export interface Props {
  open?: boolean
  setOpen?: any
}
type NotificationType = "success" | "info" | "warning" | "error"

function ModalLogin({ open, setOpen }: Props) {
  const [api, contextHolder] = notification.useNotification()
  const navigate = useNavigate()
  const isOpenLogin = useSelector((state: RootState) => state.modal.isOpenLogin)
  const account = localStorage.getItem("account") || ""

  const gameProduct = useSelector((state: RootState) => state.user.gameProduct)
  const gameIBC = gameProduct.filter(
    (v: { product_name: string }) => v?.product_name === "icb",
  )?.[0]
  const link = `/categories/game/${gameIBC?.id}?ProductCode=${gameIBC?.product_code}&GameType=${gameIBC?.game_type}`
  // const link = `/categories/game/884?ProductCode=1046&GameType=SPORT_BOOK`
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
  ) => {
    api[type]({
      message: message,
      placement: "bottomLeft",
    })
  }

  const [isShowPass, setShowPass] = useState(false)
  const [isSave, setIsSave] = useState(false)

  const [form, setForm] = useState({
    phone: "",
    password: "",
    // uses2FA: false,
  })

  useEffect(() => {
    if (account) {
      const valueAccount = JSON.parse(account) || ''
      setForm(valueAccount)
      setIsSave(true)
    }
  }, [account])

  const handleChange = (event: any, check?: boolean) => {
    if (check) {
      setForm({
        ...form,
        [event.target.name]: event.target.checked,
      })
    } else {
      setForm({
        ...form,
        [event.target.id]: event.target.value,
      })
    }
  }
  const handleCheck = (event: any) => {
    setIsSave(event.target.checked)
  }

  const handleOk = async () => {
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    try {
      let payload: any = {
        phone: form.phone,
        pwd: form.password,
      }

      const response: any = await userApi.login(payload)
      if (response) {
        if (response?.status) {
          openNotificationWithIcon("success", response?.message)
          localStorage.setItem("token", JSON.stringify(response?.token))
          localStorage.setItem("account", JSON.stringify({
            phone: form.phone,
            password: form.password,
          }))
          handleOk()

          setTimeout(() => {
            navigate(link)
          }, 1000)
        } else {
          openNotificationWithIcon("error", response?.message)
        }
      }

      return response.data
    } catch (error: any) {
      return error?.response
    }
  }

  const checkLogin = useMemo(() => {
    return !form.phone || !form.password
  }, [form.phone, form.password])

  return (
    <>
      <Modal
        open={isOpenLogin}
        title="Đăng nhập"
        onOk={handleOk}
        okText={"I Sent It"}
        onCancel={handleCancel}
        width={400}
        footer={
          [
            // <button
            //   className={`px-4 h-10 py-2 gap-2 text-moon-14 rounded relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none bg-piccolo text-goten w-full font-normal`}
            //   type="button"
            //   data-testid="tw-button"
            //   onClick={() => setAmount(1000)}
            // >
            //   <span className="number">Cancel</span>
            // </button>,
          ]
        }
      >
        <div className="modal-body info">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="sc-ikkxIA sc-kdBSHD iwfydD gbphfC">
              <div className="w-full max-w-full relative z-0 mb-6">
                <div className="textfield">
                  <div className="textfield__icon">
                    <i className="icon icon--my-account"></i>
                  </div>
                  {/* <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Tên truy cập" /> */}

                  <input
                    type="text"
                    id="phone"
                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma box-border relative z-[2] focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                    name="phone"
                    placeholder=""
                    data-testid="phone"
                    autoCapitalize="off"
                    autoComplete="off"
                    onChange={handleChange}
                    defaultValue={form.phone}
                  />
                </div>
              </div>
              <div className="w-full max-w-full relative z-0 my-6">
                <div className="textfield">
                  <div className="textfield__icon">
                    <i className="icon icon--lock"></i>
                  </div>
                  <input
                    type={!isShowPass ? "password" : "text"}
                    id="password"
                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                    name="password"
                    placeholder=""
                    defaultValue={form.password}
                    data-testid="password"
                    onChange={handleChange}
                  />
                  <div
                    className="textfield__icon2"
                    onClick={() => setShowPass((pre) => !pre)}
                  >
                    {isShowPass ? (
                      <i className="icon icon--visibility"></i>
                    ) : (
                      <i className="icon icon--visibility-off"></i>
                    )}
                  </div>
                </div>
              </div>
              <li className="sc-hZDyAQ IiJkP">
                <label className="check-box">
                  <input
                    name="uses2FA"
                    data-testid="uses2FA"
                    className="input-checkbox"
                    type="checkbox"
                    checked={isSave}
                    onChange={handleCheck}
                  />
                  <div className="sc-bmzYkS fotCte">
                    <span className={`check-box ${isSave ? "active" : ""}`}>
                      <div className="check-box-item" />
                    </span>
                    <p className="text-white">Lưu mật khẩu</p>
                  </div>
                </label>
              </li>
              <li className="sc-hZDyAQ IiJkP mt-5">
                <button
                  className="sc-dCFHLb jmbuMX button-active h-[50px]"
                  type="submit"
                  disabled={checkLogin}
                >
                  <div className="sc-fhzFiK fClrap">
                    <span data-translation="profile.login">Đăng nhập</span>
                  </div>
                </button>
              </li>
            </div>
          </form>
        </div>
      </Modal>
      {contextHolder}
    </>
  )
}

export default ModalLogin
