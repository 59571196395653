import { createSlice } from "@reduxjs/toolkit"
type PAYMENT_STATUS =
  | "CREATE"
  | "REQUEST"
  | "EXPIRED"
  | "RECEIVED"
  | "SUCCESS"
  | "REJECTED"
  | "WAITING"
  | null
export interface ModalState {
  status: PAYMENT_STATUS
  data: {
    payer?: string
    phoneNumber?: string
    trxUID: string
    amount?: string
    note?: string
    transactionTime?: string
    address?: string
    branchName?: string
    brandName?: string
    postCode?: string
  }
}

const initialState: ModalState = {
  status: null,
  data: {
    trxUID: "",
  },
}

export const paymentFormModal = createSlice({
  name: "paymentFormModal",
  initialState,
  reducers: {
    createPayment: (state, action) => {
      state.status = "CREATE"
      state.data = action.payload
    },
    requestPayment: (state, action) => {
      state.status = "REQUEST"
      state.data = { ...state.data, ...action.payload }
    },
    expiredPayment: (state) => {
      state.status = "EXPIRED"
    },
    successPayment: (state, action) => {
      state.status = "RECEIVED"
      state.data = action.payload
    },
    rejectedPayment: (state, action) => {
      state.status = "REJECTED"
      state.data = action.payload
    },

    waitingPayment: (state) => {
      state.status = "WAITING"
    },
    completePayment: (state) => {
      state.status = null
      state.data = initialState.data
    },
  },
})

export const {
  createPayment,
  requestPayment,
  expiredPayment,
  rejectedPayment,
  successPayment,
  waitingPayment,
  completePayment,
} = paymentFormModal.actions

export default paymentFormModal.reducer
