import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
import { Outlet } from "react-router-dom"
import { useAppDispatch } from "src/app/hooks"
import { useEffect } from "react"
import { userApi } from "src/api/userApi"
import { setListGameProduct, setUserData } from "src/features/user/userSlice"
import { gameApi } from "src/api/gameApi"
import NavBar from "./NavBar/NavBar"
import { notification } from "antd"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import useIsMobile from "src/customHook/useIsMobile"

function MainLayout() {
  const { isMobile } = useIsMobile(768)
  const token = localStorage.getItem("token")
  const dispath = useAppDispatch();
  const toast = useSelector((s: RootState) => s.toast.toast);

  const [api, contextHolder] = notification.useNotification();
  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  useEffect(() => {
    if (token) {
      fetchAPI()
    }

    async function fetchAPI() {
      const response: any = await userApi.getProfile()
      if (response?.status) {
        dispath(setUserData(response?.data))
      }
    }
  }, [token])

  useEffect(() => {
    fetchListGame()

    async function fetchListGame() {
      const response: any = await gameApi.getGameProduct()
      if (response?.status) {
        dispath(setListGameProduct(response?.data))
      }
    }
  }, []);

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      placement: 'bottomLeft'
    });
  };

  useEffect(() => {
    if (toast?.content) {
      openNotificationWithIcon(toast?.type, toast?.content)
    }
  }, [toast]);

  return (
    <div className="group/layout flex text-bulma">
      {/* <Sidebar /> */}
      <div id="scroll-container" className="w-full min-h-screen bg-goku pb-3 flex flex-col">
        <div className="w-full flex-1">
        <div className="sticky top-0 z-20 block bg-goku">
          <NavBar />
          {/* {isMobile &&<Header />} */}
        </div>
        <Outlet />
        {/* <Footer /> */}
      </div>
      {contextHolder}
      </div>
    </div>
  )
}

export default MainLayout
