import { Col, Drawer, Modal, Row, Tooltip, notification } from "antd"
import { useEffect, useMemo, useState } from "react"
import useIsMobile from "src/customHook/useIsMobile"
import ArrowLeft from "@assets/icons/arrowLeft.svg"
import ArrowRight from "@assets/icons/arrowRight.svg"
import CloseBlack from "@assets/icons/CloseBlack.svg"
import qrcode from "@assets/images/usdt-qr.jpg"

import { PRICE_USDT } from "src/constant"
import { depositApi } from "src/api/depositApi"
import { ellipsisText } from "src/util/ellipsisText"
import { MoneyConvert, formatMoney } from "src/util/format"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useAppDispatch } from "src/app/hooks"
import { openToast } from "src/features/openToast/openToastSlice"
import AddBank from "./AddBank"
import Payment from "./Payment"
import { userApi } from "src/api/userApi"
import { setUserData } from "src/features/user/userSlice"
import { useNavigate } from "react-router-dom"

export interface Props {
  open: boolean
  setOpen: any
}
const dataMoney = [
  {
    value: 50,
    label: "50K",
  },
  {
    value: 500,
    label: "500K",
  },
  {
    value: 10000,
    label: "10M",
  },
  {
    value: 20000,
    label: "20M",
  },
  {
    value: 100000,
    label: "100M",
  },
  {
    value: 1000000,
    label: "1000M",
  },
]
type NotificationType = "success" | "info" | "warning" | "error"

function DepositVN({ open, setOpen }: Props) {
  const navigate = useNavigate()
  const [api] = notification.useNotification()
  const { isMobile } = useIsMobile(768)
  const [amount, setAmount] = useState(50000)
  const [password, setPassword] = useState("")
  const dispath = useAppDispatch()
  const [openSent, setOpenSent] = useState(false)
  const [openAddBank, setOpenAddBank] = useState(false)
  const [openFormWithdraw, setOpenFormWithdraw] = useState(false)
  const [active, setActive] = useState(0)
  const [dataRecharge, setDataRecharge] = useState({})
  const [dataListBankDeposit, setDataListBankDeposit] = useState([])
  const [bankCode, setBankCode] = useState("")
  const [modal, contextHolder] = Modal.useModal()
  const dataUser = useSelector((state: RootState) => state.user.dataUser)
  const money = Number(dataUser?.money || 0)

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
  ) => {
    api[type]({
      message: message,
      placement: "bottomLeft",
    })
  }

  const onClose = () => {
    openFormWithdraw ? setOpenFormWithdraw(false) : setOpen(false)
  }

  // useEffect(() => {
  //   fetchListBankDeposit()
  // }, [])

  const handleOk = async () => {
    setOpenSent(false)

    try {
      // let payload: any = {
      //   money: amount.toString(),
      // }
      // const response: any = await depositApi.rechargeUsdt(payload)
      // if (response?.status) {
      //   dispath(openToast({ type: "success", content: response?.message }))
      // } else {
      //   dispath(openToast({ type: "error", content: response?.message }))
      // }
    } catch (error: any) {
      console.log(error)
      // dispath(
      //   openToast({ type: "error", content: error?.response?.data?.message }),
      // )
    }
    // setOpenSent(false)
  }

  const handleWithdrawal = async () => {
    try {
      let payload: any = {
        money: amount.toString(),
        password: password,
      }

      const response: any = await depositApi.withdrawalUsdt(payload)
      if (response?.status) {
        dispath(openToast({ type: "success", content: response?.message }))
      } else {
        dispath(openToast({ type: "error", content: response?.message }))
      }
    } catch (error: any) {
      dispath(
        openToast({ type: "error", content: error?.response?.data?.message }),
      )
    }
    setOpenSent(false)
  }

  async function fetchListBankDeposit() {
    const response: any = await depositApi.getBankListDeposit()
    if (response?.status) {
      const data = response?.datas?.data
      setBankCode(data?.[0]?.bank_code)
      setDataListBankDeposit(data ?? [])
    }
  }

  async function fetchAPIProfile() {
    const response: any = await userApi.getProfile()
    if (response?.status) {
      dispath(setUserData(response?.data))
    }
  }

  const handleOkAddBank = async () => {
    setOpenAddBank(false)
    fetchAPIProfile()
  }
  const handleCancelAddBank = () => {
    setOpenAddBank(false)
  }
  const confirm = () => {
    modal.confirm({
      onOk() {
        handleWithdrawal()
      },
      title: "Confirm",
      okText: "Confirm",
      cancelText: "Cancel",
      wrapClassName: "confirm-popup",
    })
  }

  const handleCancel = () => {
    setOpenSent(false)
  }

  const handleAmount = (e: any) => {
    setAmount(Number(e.target.value))
  }
  const handleDeposit = async () => {
    try {
      const payload = {
        money: amount,
        type: "bank",
        // bankCode: bankCode
      }
      const response: any = await depositApi.recharge(payload)

      if (response?.status) {
        const orderId = response?.datas?.orderId
        const link = `https://kingpay.center/choose-bank?orderId=${orderId}`
        setDataRecharge(response)
        // setOpenSent(true)
        setOpen(false)
        // navigate(link)
        window.open(link)
      }
    } catch (error: any) {
      openNotificationWithIcon("error", error?.message)
    }
  }

  const stkEllipsis = useMemo(() => {
    const stk = dataUser?.userBank?.[0]?.stk
    return stk
      ? `${stk?.substring(0, 2)}***${stk?.substring(
          stk?.length - 2,
          stk?.length,
        )}`
      : null
  }, [dataUser?.userBank?.[0]?.stk])
  return (
    <>
      <Drawer
        placement="right"
        size={"default"}
        className={"bg-drawer cs-tw"}
        onClose={onClose}
        closeIcon={
          openFormWithdraw ? (
            <ArrowLeft className="text-white" />
          ) : (
            <CloseBlack />
          )
        }
        width={isMobile ? "100%" : 350}
        open={open}
      >
        <div className="flex flex-col" data-testid="cashier_home">
          <div className="flex flex-col">
            {openFormWithdraw ? (
              <div className="overflow-y-auto overflow-x-hidden px-6 pb-12 pt-6 text-white">
                <div className="stack m-0 gap-7 p-0">
                  <div
                    className="stack gap-6"
                    data-testid="cashier_withdraw_amount"
                  >
                    <div className="stack gap-1">
                      <p className="text-moon-20 font-medium">
                        <span data-translation="cashier.withdraw_USDT">
                          Rút tiền
                        </span>
                      </p>
                      <p className="text-moon-14 text-white">
                        <span data-translation="cashier.current_balance">
                          Số dư hiện tại: {formatMoney(money, false, 4)} VND
                        </span>
                      </p>
                    </div>
                    <div className="stack gap-3" data-testid="wd_amount_input">
                      <p className="text-moon-10 font-medium uppercase text-white">
                        <span data-translation="cashier.transaction.amount">
                          Số tiền
                        </span>
                      </p>
                      <div className="stack gap-2">
                        <div className="w-full max-w-full relative rounded-moon-i-sm border border-solid border-border3 text-white bg-transparent z-0">
                          <input
                            type="text"
                            className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-white transition-shadow box-border relative z-[2] bg-transparent shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible:shadow-input-focus focus-visible::outline-none h-14 leading-[3.5rem] rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus-visible:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus-visible:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-white placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-xl-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                            name="amount"
                            role="textbox"
                            placeholder=""
                            dir="auto"
                            value={amount}
                            onChange={handleAmount}
                          />
                        </div>
                      </div>
                      <div className="stack grid-flow-col grid-cols-3 gap-2">
                        <button className="border border-solid border-border3 text-white bg-transparent ring-0 rounded-md p-4 text-moon-14 font-medium text-white">
                          25%
                        </button>
                        <button className="border border-solid border-border3 text-white bg-transparent ring-0 rounded-md p-4 text-moon-14 font-medium text-white">
                          50%
                        </button>
                        <button className="border border-solid border-border3 text-white bg-transparent ring-0 rounded-md p-4 text-moon-14 font-medium text-white">
                          100%
                        </button>
                      </div>
                    </div>

                    <div className="stack gap-2">
                      <div className="w-full max-w-full relative rounded-moon-i-sm border border-solid border-border3 text-white bg-transparent z-0">
                        <input
                          className="text-white block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-transparent shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible:shadow-input-focus focus-visible::outline-none h-14 leading-[3.5rem] rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus-visible:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus-visible:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-white placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-xl-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                          name="amount"
                          type="password"
                          placeholder="Mật khẩu"
                          dir="auto"
                          value={password}
                          onChange={(e: any) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <hr className="relative -left-6 -right-6 h-px w-[calc(100%_+_48px)] border-0 bg-beerus text-beerus" />
                    <button
                      className="px-4 h-12 py-3 gap-2 text-moon-16 rounded-moon-i-sm relative z-0 flex justify-center items-center button-active font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 text-goten bg-piccolo"
                      type="button"
                      disabled={amount <= 0 || !password}
                      onClick={confirm}
                    >
                      <span data-translation="cashier.next">Rút tiền</span>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* <section className="grid p-6 gap-3">
                  <div className="text-moon-12 font-semibold uppercase text-white">
                    <span data-translation="funds.balance">
                      NGÂN HÀNG ĐỊA PHƯƠNG{" "}
                    </span>
                  </div>
                  <div>
                    {
                      dataListBankDeposit?.map((item: any,i) => {
                        return <div className={`stack gap-2 mb-2`} data-testid="balance" key={i}
                          onClick={()=> setBankCode(item?.bank_code)}
                        >
                        <div
                          className={`stack cursor-pointer flex items-center justify-center gap-3 rounded-moon-s-sm border-beerus p-2 ${item?.bank_code === bankCode ? 'bg-active' : ' bg-card'}`}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="stack items-center justify-items-center gap-0 text-white">
                            {item?.bank_name}
                          </div>
                        </div>
                      </div>
                      })
                    }
               
                  </div>
                </section> */}
                {/* <hr className="border-beerus" /> */}
                <section className="grid p-6 gap-6">
                  <div
                    data-testid="Tab_Switch"
                    className="stack auto-cols-fr grid-flow-col gap-0.5 rounded bg-card p-1"
                  >
                    <div
                      className={`stack cursor-pointer place-items-center rounded px-0 py-1 font-medium text-white ${
                        active === 0 ? "bg-active" : "bg-transparent"
                      }`}
                      role="button"
                      onClick={() => (setActive(0), setAmount(50000))}
                      tabIndex={0}
                    >
                      <p className="whitespace-nowrap text-moon-12">
                        <span data-translation="wallet.deposit">Nạp</span>
                      </p>
                    </div>
                    <div
                      className={`stack cursor-pointer place-items-center rounded px-0 py-1 font-medium text-white ${
                        active === 1 ? "bg-active" : "bg-transparent"
                      }`}
                      role="button"
                      onClick={() => (setActive(1), setAmount(0))}
                      tabIndex={1}
                    >
                      <p className="whitespace-nowrap text-moon-12">
                        <span data-translation="wallet.withdraw">Rút</span>
                      </p>
                    </div>
                  </div>
                  {active === 1 && (
                    <div style={{ opacity: 1, transform: "none" }}>
                      <div className="stack">
                        {dataUser?.userBank?.length ? (
                          <div
                            className="stack cursor-pointer  grid-flow-col grid-cols-[max-content_auto_max-content] items-center gap-5 rounded-lg pb-2 pe-2 ps-2 pt-2 transition-all duration-500 bg-active ps-1 hover:ps-2.5  h-14"
                            role="button"
                            tabIndex={0}
                            onClick={() => setOpenFormWithdraw(true)}
                            data-testid="method_item_tether_withdraw-usdt"
                          >
                            <div className="stack h-10 gap-0">
                              <p className="truncate text-moon-14 font-medium leading-6 text-white">
                                <span data-translation="cashier.USDT">
                                  Rút về tài khoản
                                </span>
                              </p>
                              <p className="truncate text-moon-12 text-white">
                                <span data-translation="cashier.withdraw_USDT">
                                  {stkEllipsis}
                                </span>
                              </p>
                            </div>
                            <div className="stack h-10 grid-flow-col items-center justify-items-end gap-6">
                              <ArrowRight />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="stack cursor-pointer  grid-flow-col grid-cols-[max-content_auto_max-content] items-center gap-5 rounded-lg pb-2 pe-2 ps-2 pt-2 transition-all duration-500 bg-active ps-1 hover:ps-2.5  h-14"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              setOpenAddBank(true)
                              setOpen(false)
                            }}
                            data-testid="method_item_tether_withdraw-usdt"
                          >
                            <div className="stack gap-0">
                              <p className="truncate text-moon-14 font-medium leading-6 text-white">
                                <span data-translation="cashier.USDT">
                                  Thêm tài khoản
                                </span>
                              </p>
                            </div>
                            <div className="stack grid-flow-col items-center justify-items-end gap-6">
                              <ArrowRight />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </section>

                {active === 0 && (
                  <section className="grid p-6 gap-6">
                    <div className="boxStyle exchangeUsdt">
                      <div className="title c-row c-row-middle c-row-between mb-5">
                        <div className="c-row flex items-center">
                          <span className="m-l-10 text-moon-12 font-semibold uppercase text-white block">
                            Chọn số tiền
                          </span>
                        </div>
                      </div>

                      <Row gutter={[12, 12]}>
                        {dataMoney?.map((item, i) => {
                          return (
                            <Col lg={8} sm={8} xs={8} key={i}>
                              <button
                                className={`px-4 h-10 py-2 gap-2 text-moon-14 rounded relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-noneborder border border-solid border-border3 text-white ${
                                  amount === item?.value * 1000
                                    ? "bg-active"
                                    : "bg-transparent"
                                } w-full font-normal`}
                                type="button"
                                data-testid="tw-button"
                                onClick={() => setAmount(item?.value * 1000)}
                              >
                                <span className="number">{item?.label}</span>
                              </button>
                            </Col>
                          )
                        })}
                      </Row>
                      <div className="pay-box mt-5">
                        <div className="flex bg-transparent items-center ps-1 gap-2 moon-search-input rounded border border-solid border-border3">
                          <div className="w-12 flex items-center justify-center border-r">
                            <span className="text-white text-lg font-bold">
                              đ
                            </span>
                          </div>
                          <input
                            spellCheck="false"
                            className="moon-search-input py-2 px-0 border-0 w-full focus:outline-none focus:border-0 focus:ring-0 bg-transparent text-moon-18 placeholder-white text-white"
                            type="number"
                            value={amount}
                            onChange={handleAmount}
                          />
                        </div>
                      </div>
                      <button
                        className="sc-dCFHLb jmbuMX button-active mt-5"
                        onClick={() => {
                          handleDeposit()
                        }}
                        disabled={amount <= 0}
                      >
                        <div className="sc-fhzFiK fClrap">
                          <span>Nạp tiền</span>
                        </div>
                      </button>
                    </div>
                    {/* <div style={{ opacity: 1, transform: "none" }}>
                      <div className="stack gap-6">
                        <div className="stack gap-6">
                          <div className="stack gap-2">
                            <div className="text-moon-10 font-semibold uppercase text-white">
                              <span data-translation="cashier.quick_tips">
                                QUICK TIPS
                              </span>
                            </div>
                            <div
                              className="stack gap-0 rounded-lg bg-goku p-3"
                              data-testid="warning-undefined"
                            >
                              <p className="text-moon-14">
                                <span data-translation="cashier.deposit.warning_confirm_address">
                                  Please always check your deposit wallet
                                  address on our site before making your
                                  transactions.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </section>
                )}
              </>
            )}
          </div>
        </div>
      </Drawer>

      <Modal
        open={openSent}
        title="Quá trình thanh toán"
        onOk={handleOk}
        okText={"Tiếp tục thanh toán"}
        cancelText={"Hủy"}
        onCancel={handleCancel}
        className="add-bank"
      >
        <Payment amount={amount} dataPayment={dataRecharge} />
      </Modal>

      <Modal
        open={openAddBank}
        title="Thêm tài khoản ngân hàng"
        onOk={handleOkAddBank}
        okText={"Thêm thẻ ngân hàng"}
        onCancel={handleCancelAddBank}
        className="add-bank"
        footer={false}
      >
        <AddBank onSuccess={handleOkAddBank} />
      </Modal>
      {contextHolder}
    </>
  )
}

export default DepositVN
