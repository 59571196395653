import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import "./index.scss"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "@assets/locales/en/translation.json"
import kor from "@assets/locales/kor/translation.json"
import { Web3ContextProvider } from "./lib/components/Web3ContextProvider"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      kor: {
        translation: kor,
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <Web3ContextProvider>
    <Provider store={store}>
      <App />
    </Provider>
  // </Web3ContextProvider>
)
