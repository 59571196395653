import { mergeClassnames } from '@heathmont/moon-core-tw';
import { Image } from 'antd';

type Props = any & { width: number; height: number };

const EagerImg: React.FC<Props> = ({
  id,
  src,
  className,
  width,
  height,
  onLoad,
  onClick,
  dataAction,
  style,
  alt = '',
}) => {
  if (!src) {
    return <img src={src} alt={alt} className={className} />;
  }
  return (
    <Image
      id={id}
      alt={alt}
      src={src}
      width={width}
      height={height}
      onClick={onClick}
      onLoad={onLoad}
      className={mergeClassnames(className)}
      data-action={dataAction}
      style={style}
    />
  );
};

export default EagerImg;
