type Props = { children?: any, height?: string };

const TextTrimmer: React.FC<Props> = ({ children, height }) => (
  <div
    className="line-clamp-1"
    style={{
      height,
    }}
  >
    {children}
  </div>
);

export default TextTrimmer;
