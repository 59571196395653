import { notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userApi } from "src/api/userApi";
import logobetcasino from "@assets/images/logo-betcasino.png"

type NotificationType = 'success' | 'info' | 'warning' | 'error';

function RegisterPage() {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const ref = params.get('ref');


    const openNotificationWithIcon = (type: NotificationType, message: string) => {
        api[type]({
            message: message,
            placement: 'bottomLeft'
        });
    };

    const [form, setForm] = useState({
        username: '',
        email: '',
        day: '',
        month: '',
        year: '',
        countryCode: '',
        phone: '',
        password: '',
        invitecode: '',
        acceptsTerms1: false,
        acceptsTerms2: false
    });

    useEffect(() => {
        if (ref) {
            setForm({
                ...form,
                'invitecode': ref,
            });
        }
    }, [ref]);

    const handleChange = (event: any, check?: boolean) => {
        if (check) {
            setForm({
                ...form,
                [event.target.name]: event.target.checked,
            });
        } else {
            setForm({
                ...form,
                [event.target.id]: event.target.value,
            });
        }

    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            let payload: any = {
                "username": form.username,
                "email": form.email,
                "phone": form.phone,
                pwd: form.password,
                invitecode: form.invitecode,
                // dateofbirth: `${form.year}-${form.month}-${form.day}`
            }


            const response: any = await userApi.register(payload)
            if (response) {
                if (response?.status) {
                    openNotificationWithIcon('success', response?.message);
                    setTimeout(() => {
                        navigate("/auth/login")
                    }, 1000)
                } else {
                    openNotificationWithIcon('error', response?.message)
                }
            }
            return response.data
        } catch (error: any) {
            return error?.response
        }
    }

    return (
        <div className="bg-goten h-screen w-full overflow-scroll p-4 scroll-hidden md:w-[380px] md:p-5">
            <div className="grid gap-4">
                <div className="lazyContainer">
                    <div className="sc-dhKdcB sc-kpDqfm oflqs eLAABE">
                        <div className="grid content-start gap-6">
                            <div className="grid gap-12">
                                <a className="self-center text-bulma" href="/">
                                    <span data-gtm-event-category="brand_logo" className="flex items-center logo-title">
                                        <img src={logobetcasino} alt="" className="w-12" />
                                        BETCASINO
                                    </span>
                                </a>
                                <h1 className="text-moon-24 text-bulma font-fraunces font-normal">
                                    <span data-translation="profile.signup">Sign up</span>
                                </h1>
                            </div>
                            <div className="grid gap-2">
                                <form onSubmit={handleSubmit}>
                                    <div className="sc-dhKdcB oflqs">
                                        <div className="w-full max-w-full relative z-0 my-4">
                                            <div>
                                                <label
                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                    htmlFor="username"
                                                >
                                                    Username
                                                </label>
                                                <input
                                                    type="text"
                                                    id="username"
                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                    name="username"
                                                    placeholder=""
                                                    data-testid="username"
                                                    autoCapitalize="off"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full max-w-full relative z-0 my-4">
                                            <div>
                                                <label
                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                    htmlFor="email"
                                                >
                                                    E-mail
                                                </label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                    name="email"
                                                    placeholder=""
                                                    data-testid="email"
                                                    autoCapitalize="off"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <li className="sc-dAlyuH bnGnEl">
                                            <div className="sc-dhKdcB sc-kpDqfm jdKwkA fhZtSN grid">
                                                <div className="flow-column time-item grid">
                                                    <label className="sc-fHjqPf">
                                                        <span className="title-select">Day</span>
                                                        <select
                                                            name="day"
                                                            id="day"
                                                            data-testid="day"
                                                            className="sc-feUZmu sc-fUnMCh select-custom"
                                                            onChange={handleChange}
                                                        >
                                                            <option value="" />
                                                            <option value={1}>01</option>
                                                            <option value={2}>02</option>
                                                            <option value={3}>03</option>
                                                            <option value={4}>04</option>
                                                            <option value={5}>05</option>
                                                            <option value={6}>06</option>
                                                            <option value={7}>07</option>
                                                            <option value={8}>08</option>
                                                            <option value={9}>09</option>
                                                            <option value={10}>10</option>
                                                            <option value={11}>11</option>
                                                            <option value={12}>12</option>
                                                            <option value={13}>13</option>
                                                            <option value={14}>14</option>
                                                            <option value={15}>15</option>
                                                            <option value={16}>16</option>
                                                            <option value={17}>17</option>
                                                            <option value={18}>18</option>
                                                            <option value={19}>19</option>
                                                            <option value={20}>20</option>
                                                            <option value={21}>21</option>
                                                            <option value={22}>22</option>
                                                            <option value={23}>23</option>
                                                            <option value={24}>24</option>
                                                            <option value={25}>25</option>
                                                            <option value={26}>26</option>
                                                            <option value={27}>27</option>
                                                            <option value={28}>28</option>
                                                            <option value={29}>29</option>
                                                            <option value={30}>30</option>
                                                            <option value={31}>31</option>
                                                        </select>
                                                    </label>
                                                    <label className="sc-fHjqPf">
                                                        <span className="title-select">Month</span>
                                                        <select
                                                            name="month"
                                                            data-testid="month"
                                                            id="month"
                                                            className="sc-feUZmu sc-fUnMCh select-custom"
                                                            onChange={handleChange}
                                                        >
                                                            <option value="" />
                                                            <option value={1}>01</option>
                                                            <option value={2}>02</option>
                                                            <option value={3}>03</option>
                                                            <option value={4}>04</option>
                                                            <option value={5}>05</option>
                                                            <option value={6}>06</option>
                                                            <option value={7}>07</option>
                                                            <option value={8}>08</option>
                                                            <option value={9}>09</option>
                                                            <option value={10}>10</option>
                                                            <option value={11}>11</option>
                                                            <option value={12}>12</option>
                                                        </select>
                                                    </label>
                                                    <label className="sc-fHjqPf">
                                                        <span className="title-select">Year</span>
                                                        <select
                                                            name="year"
                                                            data-testid="year"
                                                            id="year"
                                                            className="sc-feUZmu sc-fUnMCh select-custom"
                                                            onChange={handleChange}
                                                        >
                                                            <option value="" />
                                                            <option value={2005}>2005</option>
                                                            <option value={2004}>2004</option>
                                                            <option value={2003}>2003</option>
                                                            <option value={2002}>2002</option>
                                                            <option value={2001}>2001</option>
                                                            <option value={2000}>2000</option>
                                                            <option value={1999}>1999</option>
                                                            <option value={1998}>1998</option>
                                                            <option value={1997}>1997</option>
                                                            <option value={1996}>1996</option>
                                                            <option value={1995}>1995</option>
                                                            <option value={1994}>1994</option>
                                                            <option value={1993}>1993</option>
                                                            <option value={1992}>1992</option>
                                                            <option value={1991}>1991</option>
                                                            <option value={1990}>1990</option>
                                                            <option value={1989}>1989</option>
                                                            <option value={1988}>1988</option>
                                                            <option value={1987}>1987</option>
                                                            <option value={1986}>1986</option>
                                                            <option value={1985}>1985</option>
                                                            <option value={1984}>1984</option>
                                                            <option value={1983}>1983</option>
                                                            <option value={1982}>1982</option>
                                                            <option value={1981}>1981</option>
                                                            <option value={1980}>1980</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </li> */}
                                        <div className="my-6">
                                            <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-end gap-4">
                                                <div className="grid">
                                                    <label className="sc-fHjqPf">
                                                        <span className="title-select">
                                                            Country Code
                                                        </span>
                                                        <select
                                                            placeholder=""
                                                            value={form.countryCode}
                                                            data-testid="countryCode"
                                                            id="countryCode"
                                                            onChange={handleChange}
                                                            className="sc-feUZmu sc-fUnMCh select-custom country-input sc-dtInlm kOIRpr"
                                                        >
                                                            <option label="VN (+84)" value="VN">
                                                                VN (+84)
                                                            </option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="w-full max-w-full relative z-0">
                                                    <div>
                                                        <label
                                                            className="w-full block pb-2 text-moon-16 text-trunks"
                                                            htmlFor=""
                                                        >
                                                            Phone Number
                                                        </label>
                                                        <input
                                                            type="tel"
                                                            id="phone"
                                                            className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                            name=""
                                                            placeholder=""
                                                            data-testid="phone"
                                                            defaultValue=""
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <p
                                                data-testid="phone-form-error"
                                                role="alert"
                                                className="sc-gFqAkR cosowq"
                                            />
                                        </div>
                                        <div className="w-full max-w-full relative z-0">
                                            <div>
                                                <label
                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                    htmlFor="password"
                                                >
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                    name="password"
                                                    placeholder=""
                                                    data-testid="password"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full max-w-full relative z-0 my-6">
                                            <div>
                                                <label
                                                    className="w-full block pb-2 text-moon-16 text-trunks"
                                                    htmlFor="invitecode"
                                                >
                                                    Invite Code
                                                </label>
                                                <input
                                                    type="text"
                                                    id="invitecode"
                                                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                                                    name="invitecode"
                                                    placeholder=""
                                                    data-testid="invitecode"
                                                    onChange={handleChange}
                                                    defaultValue={ref || ''}
                                                />
                                            </div>
                                        </div>
                                        <li className="sc-dAlyuH bnGnEl my-4">
                                            <label className="check-box">
                                                <input
                                                    name="acceptsTerms1"
                                                    data-testid="acceptsTerms1"
                                                    className="input-checkbox"
                                                    type="checkbox"
                                                    checked={form.acceptsTerms1}
                                                    onChange={(e) => handleChange(e, true)}
                                                />
                                                <div className="sc-cPiKLX bdFipL">
                                                    <span className={`check-box ${form.acceptsTerms1 ? 'active' : ''}`}>
                                                        <div className="check-box-item" />
                                                    </span>
                                                    <p className="checkbox-title">

                                                        I agree to Terms & Conditions and Privacy Policy
                                                    </p>
                                                </div>
                                            </label>
                                        </li>
                                        <li className="sc-dAlyuH bnGnEl my-4">
                                            <label className="check-box">
                                                <input
                                                    name="acceptsTerms2"
                                                    data-testid="acceptsTerms2"
                                                    className={`input-checkbox`}
                                                    type="checkbox"
                                                    checked={form.acceptsTerms2}
                                                    onChange={(e) => handleChange(e, true)}
                                                />
                                                <div className="sc-cPiKLX bdFipL">
                                                    <span className={`check-box ${form.acceptsTerms2 ? 'active' : ''}`}>
                                                        <div className="check-box-item" />
                                                    </span>
                                                    <p className="checkbox-title">
                                                        I agree to receive marketing communication about exclusive Betcasino rewards and promotions
                                                    </p>
                                                </div>
                                            </label>
                                        </li>
                                        {/* <li
                                            data-testid="recaptcha"
                                            className="sc-dAlyuH bnGnEl"
                                        >
                                            <div
                                                data-testid="recaptcha-form-error"
                                                className="sc-kOPcWz ORZrD"
                                            >
                                                <div id="" className="g-recaptcha">
                                                    <div style={{ width: 304, height: 78 }}>
                                                        <div>
                                                            <iframe
                                                                title="reCAPTCHA"
                                                                width={304}
                                                                height={78}
                                                                role="presentation"
                                                                name="a-q74m28moowng"
                                                                frameBorder={0}
                                                                scrolling="no"
                                                                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                                                src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6Lcalj4UAAAAAPrrtyU1Oomh5Z78mHaH4Xd68s2j&co=aHR0cHM6Ly9iaXRjYXNpbm8uaW86NDQz&hl=vi&v=fGZmEzpfeSeqDJiApS_XZ4Y2&theme=light&size=normal&cb=u2lkt8wy74l9"
                                                            />
                                                        </div>
                                                        <textarea
                                                            id="g-recaptcha-response"
                                                            name="g-recaptcha-response"
                                                            className="g-recaptcha-response"
                                                            style={{
                                                                width: 250,
                                                                height: 40,
                                                                border: "1px solid rgb(193, 193, 193)",
                                                                margin: "10px 25px",
                                                                padding: 0,
                                                                resize: "none",
                                                                display: "none"
                                                            }}
                                                            defaultValue={""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <p role="alert" className="sc-gFqAkR cosowq" />
                                        </li> */}
                                        <p role="alert" className="sc-gFqAkR cosowq" />
                                        <li className="sc-dAlyuH bnGnEl my-8">
                                            <button
                                                className="sc-fhzFiK iXBvlY sc-tagGq blRYfq button-active"
                                                data-testid="register-submit"
                                                disabled={!form.acceptsTerms1 || !form.acceptsTerms2}
                                            >
                                                <div className="sc-jxOSlx gMqhfH ">
                                                    <span data-translation="profile.create_account">
                                                        Create account
                                                    </span>
                                                </div>
                                            </button>
                                        </li>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="sc-dhKdcB sc-kpDqfm kJvqhw iJekll mb-8">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline cursor-pointer bg-transparent p-0 font-[inherit] no-underline text-trunks hover:text-piccolo my-6"
                                href="/help-center/help-terms-and-conditions/bitcasino-terms-and-conditions-of-use-of-services-of-the-company"
                            >
                                <p
                                    color="piccolo.100"
                                    className="sc-jlZhew kSjvPM text-red text-moon-14"
                                >
                                    <span data-translation="profile.terms_and_conditions">
                                        Terms & Conditions
                                    </span>
                                </p>
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline cursor-pointer bg-transparent p-0 font-[inherit] no-underline text-trunks hover:text-piccolo my-6"
                                href="/help-center/help-terms-and-conditions/bitcasino-privacy-policy"
                            >
                                <p
                                    color="piccolo.100"
                                    className="sc-jlZhew kSjvPM text-red text-moon-14"
                                >
                                    <span data-translation="support.privacy_policy">
                                        Privacy Policy
                                    </span>
                                </p>
                            </a>
                            <div className="sc-Nxspf gIzJbt text-red text-moon-14 flex">
                                <p

                                    color="trunks.100"
                                    className="sc-jlZhew jcBjDG text-gray text-moon-14 mr-2"
                                >
                                    <span data-translation="general.already_have_an_account">
                                        Already have an account ?
                                    </span>
                                </p>
                                <a href="/auth/login">
                                    <p

                                        color="piccolo.100"
                                        className="sc-jlZhew kSjvPM"
                                    >
                                        <span data-translation="profile.login">
                                            Log in
                                        </span>
                                    </p>
                                </a>
                            </div>
                            <div className="sc-dhKdcB sc-kpDqfm kJvqhw bmZFbe mt-2">
                                <p
                                    color="trunks.100"
                                    className="sc-jlZhew dBcWxl text-gray text-moon-12"
                                >
                                    This site is protected by reCAPTCHA and {" "}
                                    <a href="https://policies.google.com/privacy">
                                        {" "}
                                        the Google Privacy Policy{" "}
                                    </a>{" "}
                                    and{" "}
                                    <a href="https://policies.google.com/terms của Google ">
                                        {" "}
                                        Terms{" "}
                                    </a>{" "}
                                    of Service apply.
                                </p>
                            </div>
                        </div>
                        <div className="-mt-9 flex w-1/2 justify-center">
                            <div className="w-full relative">
                                <div
                                    className="relative"
                                    data-testid="language-switcher"
                                    data-headlessui-state=""
                                >
                                    <button
                                        className="w-full"
                                        id="headlessui-listbox-button-:r0:"
                                        type="button"
                                        aria-haspopup="listbox"
                                        aria-expanded="false"
                                        data-headlessui-state=""
                                    >
                                        <div className="relative" />
                                    </button>
                                    <button
                                        id="headlessui-listbox-button-:r1:"
                                        aria-haspopup="listbox"
                                        aria-expanded="false"
                                        data-headlessui-state=""
                                        className="flex items-center justify-between gap-2 w-full bg-gohan border-beerus shadow-input hover:shadow-input-hov transition-shadow duration-200 focus:shadow-input-focus focus:outline-none h-10 py-2 px-3 rounded-moon-i-sm [&_span]:text-trunks"
                                    >
                                        <span className="flex w-full flex-col items-start text-start overflow-hidden">
                                            <span className="text-moon-16 w-full text-bulma truncate">
                                                English
                                            </span>
                                        </span>
                                        <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="text-trunks flex-shrink-0 transition-transform text-moon-24"
                                        >
                                            <path
                                                d="M22 13L16 19L10 13"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {contextHolder}
        </div>

    );
}
export default RegisterPage;
