
function PromotionDes() {

    return (
        <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4 mian">
            <div className="lazyContainer">
                <div data-v-14912091="" className="promotion">
                    <div data-v-14912091="" className="box">
                        <div data-v-0ff6946a="" className="c-row c-row-between m-t-10 filter">
                            <div data-v-399e003d="" className="item">
                                <select className="form-control ipt" id="selectBac">
                                    <option value={1}>
                                        Toàn bộ
                                    </option>
                                    <option value={2}>Hoa hồng xổ số </option>
                                </select>
                            </div>
                        </div>
                        <div data-v-14912091="" className="">
                            <div data-v-14912091="" className="list">
                                <div data-v-14912091="" role="feed" className="van-list">
                                    <div id="van-list" className="myCommission__container-content">
                                        <div
                                            data-v-6d7407ce=""
                                            className="myCommission__container-content__item"
                                        >
                                            <div
                                                data-v-6d7407ce=""
                                                className="myCommission__container-content__item-header"
                                            >
                                                <span data-v-6d7407ce="">Hoa hồng xổ số</span>
                                                <span data-v-6d7407ce="" className="time">
                                                    2024-04-07
                                                </span>
                                            </div>
                                            <div
                                                data-v-6d7407ce=""
                                                className="myCommission__container-content__item-body"
                                            >
                                                <div data-v-6d7407ce="">
                                                    <span data-v-6d7407ce="">Số người đặt cược</span>
                                                    <span data-v-6d7407ce="" className="amount grey fw fBet">
                                                        0
                                                    </span>
                                                </div>
                                                <div data-v-6d7407ce="">
                                                    <span data-v-6d7407ce="">Số tiền đặt cược</span>
                                                    <span data-v-6d7407ce="" className="amount grey fw fBetMoney">
                                                        0
                                                    </span>
                                                </div>
                                                <div data-v-6d7407ce="">
                                                    <span data-v-6d7407ce="">Số người nạp tiền</span>
                                                    <span data-v-6d7407ce="" className="amount grey fw fRecharge">
                                                        0
                                                    </span>
                                                </div>
                                                <div data-v-6d7407ce="">
                                                    <span data-v-6d7407ce="">Số tiền nạp</span>
                                                    <span
                                                        data-v-6d7407ce=""
                                                        className="amount grey fw fRechargeMoney"
                                                    >
                                                        0
                                                    </span>
                                                </div>
                                                <div data-v-6d7407ce="">
                                                    <span data-v-6d7407ce="">Cấp đại lý</span>
                                                    <span data-v-6d7407ce="" className="amount grey fw fRank">
                                                        0
                                                    </span>
                                                </div>
                                                <div data-v-6d7407ce="">
                                                    <span data-v-6d7407ce="">Thanh toán hoa hồng</span>
                                                    <span
                                                        data-v-6d7407ce=""
                                                        className="amount orange fw totalRoses"
                                                    >
                                                        0
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="van-list__finished-text">Không còn nữa</div> */}
                                    <div className="van-list__placeholder" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="inline-container">
                <div className="inline-container">
                    <a href="/">
                        <p className="text-moon-12 text-trunks">
                            <img
                                alt=""
                                className=""
                                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                                height={32}
                                loading="lazy"
                            />
                        </p>
                    </a>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="m-0 text-2xl text-trunks"
                    >
                        <path
                            d="M13 10L19 16L13 22"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="inline-container">
                    <a href="/refer-a-friend">
                        <p className="text-moon-12 text-trunks">
                            <span data-translation="profile.refer-a-friend">
                                Promotion
                            </span>
                        </p>
                    </a>
                </div>
            </div>
        </div>

    );
}
export default PromotionDes;
