import ProviderCard from "./ProviderCard";
import providers from "@assets/mockdata/providers_connection.json"

function Providers() {
  
  return (
    <div className="mx-auto mb-12 mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4">
      <div className="lazyContainer">
        <div className="sc-aXZVg fQEBxw">
          <h1 className="z-2 self-end text-moon-24 text-bulma">
            <span data-translation="casino.menu_item.all_providers">
            All Providers
            </span>
          </h1>
          <div className="provider-wp">
            {providers?.map(({node}: any) => (
              <ProviderCard
                key={node?.id}
                name={node?.name || ''}
                handle={node?.handle || ''}
                count={node?.games?.totalCount ?? 0}
                games={
                  node?.games?.edges?.map(
                    ({ node }: any) => node,
                  )
                }
              />
            ))}
          </div>
        </div>
      </div>
      <div className="inline-container">
        <div className="inline-container">
          <a href="/">
            <p className="text-moon-12 text-trunks">
              <img
                alt=""
                className=""
                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                height={32}
                loading="lazy"
              />
            </p>
          </a>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="m-0 text-2xl text-trunks"
          >
            <path
              d="M13 10L19 16L13 22"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="inline-container">
          <p className="text-moon-12 text-bulma">
            <span data-translation="casino.providers">Nhà cung cấp</span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default Providers;
