const getGameHref = (isSportsbet: boolean, href: string | undefined) => {
  if (!href) {
    return '';
  }
  if (isSportsbet) {
    return href.replace('/casino', '/casino/play/');
  }
  if (href?.startsWith('/casino/')) {
    return href.replace('/casino/', '/play/');
  }
  return href;
};

export default getGameHref;
