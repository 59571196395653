import GameCard from "src/components/GameCard"
// import datagame from "@assets/mockdata/all_games.json"
import datagame from "@assets/mockdata/all_games_evolution.json"

import SelectCheckbox from "src/components/SelectForm/SelectCheckbox"
import SelectButton from "src/components/SelectForm/SelectButton"
import { useEffect, useState } from "react"
import { gameApi } from "src/api/gameApi"
import { useLocation, useParams } from "react-router-dom"
import Maintenance from "@assets/images/maintenance.svg"
import useIsMobile from "src/customHook/useIsMobile"

function queryParamsToObject(query: string) {
  const params = new URLSearchParams(query)
  const obj: any = {}
  for (const [key, value] of params) {
    obj[key] = value
  }
  return obj
}

function Baccarat() {
  const { isMobile } = useIsMobile(768)
  const { id } = useParams()
  const location = useLocation()
  const params = queryParamsToObject(location.search)
  const [urlGame, setUrlGame] = useState("")
  const [error, setError] = useState(false)

  const openGame = async () => {
    const payload = {
      gameType: params.GameType,
      productCode: params.ProductCode,
      platform: isMobile? 'mobile' : 'web',
      GameID: params?.GameID
    }
    try {
        const response: any = await gameApi.lauchGame(payload)
        console.log('response :>> ', response);
        if (response?.status) {
          setUrlGame(response?.Url)
          setError(false)
        } else {
          setError(true)
        }
    } catch (error) {
        setError(true)
    }
 
  }

  useEffect(() => {
    setUrlGame("")
    openGame()
  }, [id,isMobile])

  const items = datagame?.map(({ node }: any, index: number) => {
    if (!node) {
      return null
    }
    return (
      <GameCard key={node.id} game={node} showGrid big onClick={openGame} />
    )
  })

  return (
    <main className="mx-auto mt-0 grid w-full grid-cols-minmax gap-4  xl:py-0 pb-0 pt-4 flex-1 min-h-[85vh] page-content">
      <div className="flex flex-col gap-6 h-full">
        {
          <>
            <>
              {urlGame && (
                <div className="rounded-moon-s-sm bg-gohan py-0 pb-4 h-full">
                  {/* <div
                                            className="flex h-16 w-full items-center justify-between"
                                        >
                                            <div
                                                className="inline-container gap-2"
                                            >
                                                <div
                                                    className="cursor-pointer"
                                                >
                                                    <svg
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="text-moon-24 fill-trunks text-trunks"
                                                        data-gtm-vis-first-on-screen11336045_328={763}
                                                        data-gtm-vis-total-visible-time11336045_328={100}
                                                        data-gtm-vis-has-fired11336045_328={1}
                                                    >
                                                        <path
                                                            d="M14.5227 7.55188C15.0692 6.23782 16.9308 6.23782 17.4773 7.55189L18.7987 10.729C19.0291 11.2829 19.5501 11.6615 20.1482 11.7094L23.5781 11.9844C24.9968 12.0981 25.572 13.8685 24.4911 14.7944L21.8779 17.0329C21.4222 17.4232 21.2232 18.0357 21.3625 18.6193L22.1608 21.9663C22.4911 23.3507 20.9851 24.4449 19.7705 23.703L16.834 21.9094C16.322 21.5967 15.678 21.5967 15.166 21.9094L12.2295 23.703C11.0149 24.4449 9.50893 23.3507 9.83915 21.9663L10.6375 18.6193C10.7768 18.0357 10.5778 17.4232 10.1221 17.0329L7.50885 14.7944C6.42799 13.8685 7.00323 12.0981 8.42188 11.9844L11.8518 11.7094C12.4499 11.6615 12.9709 11.2829 13.2013 10.729L14.5227 7.55188Z"
                                                            stroke="currentColor"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                >
                                                    <p
                                                        className="block text-moon-10-caption font-normal uppercase text-trunks"
                                                    >
                                                        {params.ProductCode}
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                  <div className="relative w-full overflow-hidden rounded-moon-s-sm bg-popo pb-[56.3%] h-full">
                    <div
                      className="fixed top-0 left-0 h-full w-full z-10 md:absolute"
                      id="game-fullscreen-container"
                    >
                      <iframe
                        id="gameIframe"
                        src={urlGame}
                        allow="fullscreen"
                        className="h-full w-full border-0"
                      />
                    </div>
                  </div>
                </div>
              )  }

              {
                error && (
                    <>
                      <div className="flex-1 flex flex-col items-center justify-center">
                        {/* <img src={maintenance} alt="" /> */}
                        <div className="md:text-3xl text-xl text-piccolo">
                        Trò chơi đang bảo trì , bạn vui lòng vào lại sau
                        </div>
                        <div className="w-[380px]">
                            <Maintenance />
                        </div>
                      </div>
                      <div className="grid grid-cols-[repeat(2,1fr)] gap-4 xsm:grid-cols-[repeat(3,1fr)] md:grid-cols-[repeat(4,1fr)] xl:grid-cols-[repeat(5,1fr)] 2xl:grid-cols-[repeat(6,1fr)]">
                        {/* {items} */}
                      </div>
                    </>
                  )
              }
            </>
          </>
        }
      </div>
    </main>
  )
}
export default Baccarat
