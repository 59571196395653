import { Carousel } from '@heathmont/moon-core-tw';
type Props = {
  direction: 'left' | 'right';
  className?: string;
  iconClassName?: string;
};

const CarouselButton: React.FC<Props> = ({ direction, className, iconClassName }) => {
  if (direction === 'right') {
    return (
      <Carousel.RightArrow className={className}>
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="rem:stroke-[4px] text-trunks rem:text-[10px]  "
        >
          <path
            d="M11.5 7L20.5 16L11.5 25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

      </Carousel.RightArrow>
    );
  }
  return (
    <Carousel.LeftArrow className={className}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="rem:stroke-[4px] text-trunks rem:text-[10px]  "
      >
        <path
          d="M20.5 25L11.5 16L20.5 7"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Carousel.LeftArrow>
  );
};

export default CarouselButton;
