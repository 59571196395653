import { Col, Drawer, Dropdown, Menu, Row, Tabs } from "antd"
import React, { useCallback, useMemo } from "react"
import "./DropDownMenu.scss"
import { Link } from "react-router-dom"
import cq9 from "@assets/icons/cq9.png"
import ap from "@assets/icons/ap.png"
import bg from "@assets/icons/bg.png"
import dg from "@assets/icons/dg.png"
import evo from "@assets/icons/evo.png"
import icb from "@assets/icons/icb.png"
import jdb from "@assets/icons/jdb.png"
import wm from "@assets/icons/wm.png"

import subMenu1 from "@assets/images/menu/1.png"
import subMenu2 from "@assets/images/menu/2.png"
import subMenu3 from "@assets/images/menu/3.png"
import subMenu4 from "@assets/images/menu/4.png"
import subMenu5 from "@assets/images/menu/5.png"
import subMenu6 from "@assets/images/menu/6.png"
import subMenu7 from "@assets/images/menu/7.png"

const listImage: any = [
  subMenu1,
  subMenu2,
  subMenu3,
  subMenu4,
  subMenu5,
  subMenu6,
  subMenu7,
]
const listIcon2: any = {
  1: evo,
  2: bg,
  3: cq9,
  4: cq9,
  5: wm,
  6: icb,
  7: dg,
  8: ap,
  9: jdb,
}

const listMenuTitle = [
  "Live Casino",
  "Slot",
  "Sport Book",
  "Virtual Sport",
  "Lottery",
  "Qipai",
  "P2P",
  "Fishing",
  "Others",
  "Cock Fighting",
  "Bonus",
  "Jackpot",
  "ESport",
]

const listMenu: any = {
  SLOT: "Slot",
  LIVE_CASINO: "Live Casino",
  SPORT_BOOK: "Sport book",
  VIRTUAL_SPORT: "Virtual sport",
  LOTTERY: "Lottery",
  QIPAI: "Qipai",
  P2P: "P2p",
  FISHING: "Fishing",
  COCK_FIGHTING: "Cock fighting",
  BONUS: "Bonus",
  ESPORT: "Esport",
  POKER: "Poker",
  OTHERS: "Others",
}
const DropDownMenu = ({ onLogout, menuList ,onClose }: any) => {
  const items = useMemo(() => {
    return menuList.map((_: any, i: number) => {
      const id = String(i + 1)
      const convert = _.children.map((item: any, index: any) => {
        return (
          <Col span={12} className="text-center">
            <Link key={index} className="sports-card" to={item.link} onClick={onClose}>
              <div className="sports-card__title">
                <span className="text">{item?.text}</span>
              </div>
              <div className="sports-card__image">
                <img src={item?.image_url || listImage[index]} alt="" />
              </div>
            </Link>
          </Col>
        )
      })

      return {
        label: (
          <div className="menu-item">
            <span className="c-lobby-icon">
              <img src={_.iconMobile} alt="" className="icon-menu" />
            </span>
            {_.text}
          </div>
        ),
        key: id,
        children: <Row gutter={[5, 5]}>{convert}</Row>,
      }
    })
  }, [menuList])

  const itemLogout = {
    label: (
      <button
        onClick={onLogout}
        className="px-2 h-10 py-2 gap-2 text-moon-12 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 bg-krillin text-bulma font-normal"
        type="button"
        data-testid="tw-button"
        id="logoutButton"
      >
        <span data-translation="profile.logout">Đăng xuất</span>
        <span
          aria-hidden="true"
          className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
        />
      </button>
    ),
    key: "logout",
  }
  return (
    <Tabs
      tabPosition={"left"}
      className="tabMenu"
      items={[...items, itemLogout]}
    />
  )
}

export default DropDownMenu
