import { createSlice } from "@reduxjs/toolkit"

export interface DrawerState {
  isOpenLogin: boolean
}

const initialState: DrawerState = {
  isOpenLogin: false,
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    openLogin: (state) => {
      state.isOpenLogin = true
    },
    closeLogin: (state) => {
      state.isOpenLogin = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
})

export const { openLogin, closeLogin } = modalSlice.actions
export default modalSlice.reducer
