import { rem } from '@heathmont/moon-utils';

type Props = {
  width: number;
  showGrid?: boolean;
  onClick?: () => void;
  children?: any
};

const GameCardTemplate: React.FC<Props> = ({
  onClick,
  width,
  showGrid,
  children,
}) => (
  <div
    className="grid grid-cols-minmax gap-2"
    data-testid="thumb-wrapper"
    style={{
      maxWidth: showGrid ? '' : rem(width),
    }}
    onClick={onClick}
  >
    {children}
  </div>
);

export default GameCardTemplate;
