const getGamesList = ({ gameCount }: { gameCount: number }) => {
  if (gameCount === 9) {
    return { cols: '3', count: 9 };
  }
  if (gameCount >= 4) {
    return { cols: '2', count: 4 };
  }
  return { cols: '1', count: 1 };
};

export default getGamesList;
