import PromotionsView from "./PromotionsView";

function Promotions() {
    return (
        <div className="mx-auto mb-12 mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4">
            <div className="lazyContainer">
                <div className="sc-dAlyuH sc-jlZhew ckqzvz kUaSUV">
                    <div className="sc-dAlyuH sc-jlZhew sc-fUnMCh ckqzvz kXQpzG gvGeRj">
                        <div className="sc-dAlyuH sc-jlZhew sc-feUZmu ckqzvz ehLjPo loeWGH">
                            <a href="/promotions">
                                <div
                                    data-testid="menu-link-all_promotions"
                                    className="sc-gFqAkR jZpuiV active"
                                >
                                    <span data-translation="general.menu_item.all_promotions">
                                        Tất cả các chương trình ưu đãi
                                    </span>
                                </div>
                            </a>
                        </div>
                        <PromotionsView/>
                        <div className="sc-fPXMVe cdrazg" style={{display:'flex', justifyContent:'center'}}>
                            <button className="sc-iGgWBj dsSpFl" >
                                <div className="sc-gsFSXq eVpQll">
                                    <span data-translation="promotion.show_past_promotions" style={{fontFamily:'system-ui', fontWeight:500}}>
                                        Hiển thị chương trình ưu đãi trong quá khứ
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inline-container">
                <div className="inline-container">
                    <a href="/">
                        <p className="text-moon-12 text-trunks">
                            <img
                                alt=""
                                className=""
                                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                                height={32}
                                loading="lazy"
                            />
                        </p>
                    </a>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="m-0 text-2xl text-trunks"
                    >
                        <path
                            d="M13 10L19 16L13 22"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="inline-container">
                    <a href="/promotions">
                        <p className="text-moon-12 text-trunks">
                            <span data-translation="general.menu_item.promo">Ưu đãi</span>
                        </p>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default Promotions;
