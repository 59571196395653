import { useState } from "react"
import useOutsideClick from "src/util/useOutsideClick"

type Props = {
  label?: string,
  placehoder?: string,
  content?: any
}

function SelectCheckbox({ label, placehoder, content }: Props) {
  const [open, setOpen] = useState(false);

  const items = content?.map((el: any, index: number) => {

    return <span
      key={index}
      id="headlessui-listbox-option-:r16:"
      role="option"
      tabIndex={-1}
      aria-selected="false"
      data-headlessui-state=""
    >
      <button
        className="flex gap-2 justify-between items-center p-2 bg-transparent rounded-moon-i-sm text-moon-14 text-bulma focus:outline-none focus:shadow-focus cursor-pointer hover:bg-bulma/[0.04] transition w-full"
        type="button"
      >
        <span className="flex w-6 h-6 justify-center items-center relative">
          <span
            role="checkbox"
            aria-checked="false"
            aria-label="Checkbox"
            className="absolute top-1 start-1 flex w-4 h-4 items-center justify-center shadow-[0_0_0_1px_inset] transition-colors text-moon-16 rounded-moon-s-xs shadow-trunks text-goten moon-checked:shadow-none moon-checked:bg-piccolo"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="transition-opacity opacity-0"
            >
              <path
                d="M7 14.9412L13.6667 22L25 10"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </span>
        <span className="block grow text-start text-bulma overflow-hidden">
          <span className="text-moon-14 text-trunks">{el?.name}</span>
        </span>
      </button>
    </span>;
  });

  const handleClickOutside = () => {
    setOpen(false)
  }
  const ref = useOutsideClick(handleClickOutside)

  return (
    <div ref={ref} className="relative">
      <label
        className="block text-bulma pb-2 text-moon-16"
        id="headlessui-listbox-label-:R5l2rbb79mmmja:"
        data-headlessui-state=""
      >
        <div className="text-moon-14 text-trunks">{label}</div>
      </label>
      <div className="relative">
        <button
          onClick={() => setOpen(!open)}
          id="headlessui-listbox-button-:R9l2rbb79mmmja:"
          aria-haspopup="listbox"
          aria-expanded="false"
          data-headlessui-state=""
          className="flex items-center justify-between gap-2 w-full bg-gohan border-beerus shadow-input hover:shadow-input-hov transition-shadow duration-200 focus:shadow-input-focus focus:outline-none h-12 p-3 rounded-moon-i-sm text-moon-14"
          aria-labelledby="headlessui-listbox-label-:R5l2rbb79mmmja: headlessui-listbox-button-:R9l2rbb79mmmja:"
        >
          <span className="flex w-full flex-col items-start text-start overflow-hidden">
            <span className="flex w-full gap-2 items-center">
              <span className="text-moon-16 w-full text-trunks text-start truncate">
                {placehoder}
              </span>
            </span>
          </span>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-trunks flex-shrink-0 transition-transform text-moon-24"
          >
            <path
              d="M22 13L16 19L10 13"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        {
          open &&
          <ul
            className="w-60 z-5 absolute p-1 my-2 rounded-moon-s-md box-border bg-gohan shadow-moon-lg overflow-y-auto focus:outline-none max-h-96"
            aria-multiselectable="true"
            aria-labelledby="headlessui-listbox-label-:r0:"
            aria-orientation="vertical"
            id="headlessui-listbox-options-:r2:"
            role="listbox"
            tabIndex={0}
            data-headlessui-state="open"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              transform: "translate(0px, 48px)"
            }}
            data-popper-reference-hidden="false"
            data-popper-escaped="false"
            data-popper-placement="bottom-start"
          >
            {items}
          </ul>
        }
      </div>
    </div>
  )
}

export default SelectCheckbox
