import { createSlice } from "@reduxjs/toolkit"
import enLangImg from "@assets/images/your-logo.png"
import { RootState } from "src/app/store"

export interface LanguageState {
  langSelected: any
}

const initialState: LanguageState = {
  langSelected: {
    id: "en",
    name: "English",
    img: enLangImg,
  },
}

export const languageSelectedSlice = createSlice({
  name: "languageSelected",
  initialState,
  reducers: {
    setLangSelected: (state, action) => {
      state.langSelected = action.payload
    },
  },
})

export const { setLangSelected } = languageSelectedSlice.actions

export const langSelect = (state: RootState) => state.langSelected.langSelected

export default languageSelectedSlice.reducer
