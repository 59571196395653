export enum ApiUrl {
  LOGIN = "/api/webapi/login",
  SIGN_UP = "/api/webapi/register",
  ADD_WALLET = "/api/webapi/addWallet",
  ADD_BANK = "/api/webapi/addBank",
  RECHARGE_USDT = "/api/webapi/rechargeUsdt",
  RECHARGE = "/api/webapi/recharge",
  BANK_LIST_DEPOSIT = "/api/webapi/getBankListDeposit",
  WITHDRAWAL_USDT = "/api/webapi/withdrawalUsdt",
  PROFILE = "/api/webapi/profile",
  LAUCH_GAME = "/api/webapi/lauchGame",
  GET_GAME_PRODUCT = "/api/webapi/getGameProduct",
  RECHARGE_LIST = "/api/webapi/recharge/list",
  WITHDRAW_LIST = "/api/webapi/withdraw/list",
  PROMOTION = "/api/webapi/promotion",
  SOCCER = "/api/webapi/getDataSoccer",
  INFOR_BANK = "/api/webapi/promotion",
  BANK_DEPOSIT_UPDATE = "/api/v1/public/transaction/deposit/update",
  
}
