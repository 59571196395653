import { useEffect, useState } from "react"
import useOutsideClick from "src/util/useOutsideClick"

type Props = {
  label?: string,
  content?: any
}

function SelectButton({ label, content }: Props) {
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState<any>('');

  const handleClickOutside = () => {
    setOpen(false)
  }
  const ref = useOutsideClick(handleClickOutside)

  useEffect(() => {
    if (!select) {
      setSelect(content?.[0])
    }
  }, [content])


  const items = content?.map((el: any, index: number) => {

    return <div
      onClick={() => setSelect(el)}
      key={index} className={`inline-container flex h-10 items-center justify-center rounded-moon-s-xs font-medium m-1 cursor-pointer border border-solid border-beerus hover:bg-piccolo hover:text-goku ${el?.id === select?.id ? 'bg-piccolo text-goku' : ''}`}>
      {
        el?.id === select?.id &&
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="m-2 stroke-[4px] text-moon-16"
        >
          <path
            d="M25 11.5L16 20.5L7 11.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }

      <span className="m-2 text-moon-14">{el?.name}</span>
    </div>;
  });

  return (
    <>
      <p className="text-moon-14 text-trunks">
        <span data-translation="general.sort_by">{label}</span>
      </p>
      <div ref={ref} className="relative" data-headlessui-state="">
        <div
          role="button"
          aria-expanded="false"
          data-headlessui-state=""
          id="headlessui-popover-button-:R5n2rbb79mmmja:"
        >
          <button
            onClick={() => setOpen(!open)}
            className="ps-4 pe-3 h-12 py-3 gap-2 text-moon-16 rounded-moon-i-sm relative z-0 flex items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 [&_+_span]:active:scale-90 [&_.hover]:hover:bg-bulma/[.07] btn-secondary ring-inset ring-1 ring-trunks hover:ring-bulma justify-between bg-gohan !font-averta font-normal text-trunks shadow-input hover:shadow-input focus:shadow-input-focus md:min-w-[11.25rem]"
            type="button"
          >
            {select?.name}
            <span aria-hidden="true" className="text-moon-24">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-moon-24 text-trunks"
              >
                <path
                  d="M11 13L16 8L21 13"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11 19L16 24L21 19"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              aria-hidden="true"
              className="hover z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out] hover:bg-bulma/[.07]"
            />
          </button>
        </div>
        {
          open &&
          <div
            data-popper-reference-hidden="false"
            data-popper-escaped="false"
            data-popper-placement="bottom-end"
            className="z-[999999] rounded-moon-s-md box-border shadow-moon-lg overflow-y-auto focus:outline-none w-60 p-3"
            id="headlessui-popover-panel-:r8:"
            tabIndex={-1}
            data-headlessui-state="open"
            style={{
              position: "absolute",
              inset: "0px 0px auto auto",
              transform: "translate(0px, 56px)"
            }}
          >
            {items}
          </div>
        }
      </div>
    </>
  )
}

export default SelectButton
