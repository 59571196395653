import { useTranslation } from "react-i18next"
import NotFoundBg from "@assets/images/notfound/data_notfound.png"

type Props = {}

function NotFound({}: Props) {
  const { t } = useTranslation()
  return (
    <div className="container lg:pt-[90px] lg:pb-[300px] pt-[63px] pb-[74px]">
      <img src={NotFoundBg} alt="notfound" className="mx-auto mt-[70px]" />
    </div>
  )
}

export default NotFound
