import axios from "axios";

// eslint-disable-next-line no-underscore-dangle

const _axios = axios.create({
  baseURL: "https://api.betcasino789.com/",
});

const mainAxios = {
  request: async (parameter: any) => {
    const { methodType, url, payload, requiresToken, config, params } =
      parameter;

    return new Promise((resolve, reject) => {
      // axios request default options
      const headers = config && config.headers ? config.headers : {};

      if (headers.contentType) {
        headers["Content-Type"] = headers.contentType;
        delete headers.contentType;
      } else {
        headers["Content-Type"] = "application/json";
      }

      // if API endpoint requires a token
      if (requiresToken) {

        const acToken = localStorage.getItem("token");
          
        if (acToken) headers["Authorization"] = JSON.parse(acToken);
      }

      _axios
        .request({
          url,
          method: methodType,
          data: payload,
          params,
          headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status >= 400 && err.response.status < 500) {
              const errData = err.response.data;
              console.log("::: Error Code :", errData.code);
              console.log("::: Error Message :", errData.message);
              // alert(errData.message)
            } else {
              const errData = err.response.data;
              console.log("::: Error Code :", errData.code);
              // alert('서버에러:' + errData.message)
            }
          }
          reject(err);
        });
    });
  },

  getRequest: async function (parameter: any) {
    parameter.methodType = "GET";
    return this.request(parameter);
  },

  postRequest: async function (parameter: any) {
    parameter.methodType = "POST";
    return this.request(parameter);
  },

  putRequest: async function (parameter: any) {
    parameter.methodType = "PUT";
    return this.request(parameter);
  },

  patchRequest: async function (parameter: any) {
    parameter.methodType = "PATCH";
    return this.request(parameter);
  },

  deleteRequest: async function (parameter: any) {
    parameter.methodType = "DELETE";
    return this.request(parameter);
  },
};

export { mainAxios };
