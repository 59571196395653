import { Tooltip } from '@heathmont/moon-core-tw';

const DesktopInfoTooltip: React.FC<any> = ({ trigger, content }) => (
  <Tooltip>
    <Tooltip.Trigger>
      <span>{trigger}</span>
    </Tooltip.Trigger>
    <Tooltip.Content className="z-20 p-4">
      {content}
      <Tooltip.Arrow />
    </Tooltip.Content>
  </Tooltip>
);

export default DesktopInfoTooltip;
