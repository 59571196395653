import { Modal, notification } from "antd"
import { useEffect, useState } from "react"
// import "./style.scss"
import { userApi } from "src/api/userApi"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "src/app/hooks"
import { openLogin } from "src/features/modal"

export interface Props {
  open: boolean
  setOpen: any
}
type NotificationType = "success" | "info" | "warning" | "error"

function ModalRegister({ open, setOpen }: Props) {
  const dispath = useAppDispatch()
  const [api, contextHolder] = notification.useNotification()
  const navigate = useNavigate()

  const search = window.location.search
  const params = new URLSearchParams(search)
  const ref = params.get("ref")

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
  ) => {
    api[type]({
      message: message,
      placement: "bottomLeft",
    })
  }
  const [isShowPass, setShowPass] = useState(false)

  const [form, setForm] = useState({
    // username: "",
    // email: "",
    // day: "",
    // month: "",
    // year: "",
    // countryCode: "",
    phone: "",
    password: "",
    invitecode: "LIXI68",
    acceptsTerms1: false,
    acceptsTerms2: false,
  })

  useEffect(() => {
    if (ref) {
      setForm({
        ...form,
        invitecode: ref,
      })
    }
  }, [ref])

  const handleChange = (event: any, check?: boolean) => {
    if (check) {
      setForm({
        ...form,
        [event.target.name]: event.target.checked,
      })
    } else {
      setForm({
        ...form,
        [event.target.id]: event.target.value,
      })
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    try {
      let payload: any = {
        // username: form.username,
        // email: form.email,
        phone: form.phone,
        pwd: form.password,
        invitecode: form.invitecode,
        // dateofbirth: `${form.year}-${form.month}-${form.day}`
      }
      const response: any = await userApi.register(payload)
      if (response) {
        if (response?.status) {
          openNotificationWithIcon("success", response?.message)
          // setTimeout(() => {
          //   navigate("/auth/login")
          // }, 1000)
          localStorage.setItem("account", JSON.stringify({
            phone: form.phone,
            password: form.password,
          }))
          handleOk()
          dispath(openLogin())
        } else {
          openNotificationWithIcon("error", response?.message)
        }
      }
      return response.data
    } catch (error: any) {
      return error?.response
    }
  }

  const handleOk = async () => {
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        title="Đăng Ký"
        onOk={handleOk}
        okText={"I Sent It"}
        onCancel={handleCancel}
        width={400}
        footer={
          [
            // <button
            //   className={`px-4 h-10 py-2 gap-2 text-moon-14 rounded relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none bg-piccolo text-goten w-full font-normal`}
            //   type="button"
            //   data-testid="tw-button"
            //   onClick={() => setAmount(1000)}
            // >
            //   <span className="number">Cancel</span>
            // </button>,
          ]
        }
      >
        <div className="modal-body info">
          <form onSubmit={handleSubmit}>
            <div className="sc-dhKdcB oflqs">
              <div className="w-full max-w-full relative z-0 my-4">
                <label
                  className="w-full block pb-2 text-moon-16 text-hit"
                  htmlFor="phone"
                >
                  Số điện thoại
                </label>
                <div className="textfield">
                  <input
                    type="tel"
                    id="phone"
                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma box-border relative z-[2] focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                    name=""
                    placeholder=""
                    data-testid="phone"
                    defaultValue=""
                    onChange={handleChange}
                  />
                  {/* <input
                    type="text"
                    id="username"
                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-hit transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-hit placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                    name="username"
                    placeholder=""
                    data-testid="username"
                    autoCapitalize="off"
                    onChange={handleChange}
                  /> */}
                </div>
              </div>
              {/* <div className="w-full max-w-full relative z-0 my-4">
                <div>
                  <label
                    className="w-full block pb-2 text-moon-16 text-hit"
                    htmlFor="email"
                  >
                    E-mail
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-hit placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                    name="email"
                    placeholder=""
                    data-testid="email"
                    autoCapitalize="off"
                    onChange={handleChange}
                  />
                </div>
              </div> */}

              {/* <div className="my-6">
                <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-end gap-4">
                  <div className="grid">
                    <label className="sc-fHjqPf">
                      <span className="title-select">Country Code</span>
                      <select
                        placeholder=""
                        value={form.countryCode}
                        data-testid="countryCode"
                        id="countryCode"
                        onChange={handleChange}
                        className="sc-feUZmu sc-fUnMCh select-custom country-input sc-dtInlm kOIRpr"
                      >
                        <option label="VN (+84)" value="VN">
                          VN (+84)
                        </option>
                      </select>
                    </label>
                  </div>
                  <div className="w-full max-w-full relative z-0">
                    <div>
                      <label
                        className="w-full block pb-2 text-moon-16 text-hit"
                        htmlFor=""
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-hit placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                        name=""
                        placeholder=""
                        data-testid="phone"
                        defaultValue=""
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <p
                  data-testid="phone-form-error"
                  role="alert"
                  className="sc-gFqAkR cosowq"
                />
              </div> */}
              <div className="w-full max-w-full relative z-0">
                <label
                  className="w-full block pb-2 text-moon-16 text-hit"
                  htmlFor="password"
                >
                   Mật khẩu
                </label>
                <div className="textfield">
                  <input
                    type={!isShowPass ? "password" : "text"}
                    id="password"
                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                    name="password"
                    placeholder=""
                    onChange={handleChange}
                  />

                  <div
                    className="textfield__icon2"
                    onClick={() => setShowPass((pre) => !pre)}
                  >
                    {isShowPass ? (
                      <i className="icon icon--visibility"></i>
                    ) : (
                      <i className="icon icon--visibility-off"></i>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full max-w-full relative z-0 my-6">
                <div>
                  <label
                    className="w-full block pb-2 text-moon-16 text-hit"
                    htmlFor="invitecode"
                  >
                    Mã giới thiệu
                  </label>
                  <input
                    type="text"
                    id="invitecode"
                    className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-hit placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
                    name="invitecode"
                    placeholder=""
                    data-testid="invitecode"
                    onChange={handleChange}
                    defaultValue={ref || "LIXI68"}
                  />
                </div>
              </div>
              <li className="sc-dAlyuH bnGnEl my-4">
                <label className="check-box">
                  <input
                    name="acceptsTerms1"
                    data-testid="acceptsTerms1"
                    className="input-checkbox"
                    type="checkbox"
                    checked={form.acceptsTerms1}
                    onChange={(e) => handleChange(e, true)}
                  />
                  <div className="sc-cPiKLX bdFipL">
                    <span
                      className={`check-box ${
                        form.acceptsTerms1 ? "active" : ""
                      }`}
                    >
                      <div className="check-box-item" />
                    </span>
                    <p className="checkbox-title">
                    Tôi đồng ý với Điều khoản & Điều kiện và Chính sách quyền riêng tư
                    </p>
                  </div>
                </label>
              </li>
              <li className="sc-dAlyuH bnGnEl my-4">
                <label className="check-box">
                  <input
                    name="acceptsTerms2"
                    data-testid="acceptsTerms2"
                    className={`input-checkbox`}
                    type="checkbox"
                    checked={form.acceptsTerms2}
                    onChange={(e) => handleChange(e, true)}
                  />
                  <div className="sc-cPiKLX bdFipL">
                    <span
                      className={`check-box ${
                        form.acceptsTerms2 ? "active" : ""
                      }`}
                    >
                      <div className="check-box-item" />
                    </span>
                    <p className="checkbox-title">
                    Tôi đồng ý nhận thông tin tiếp thị về độc quyền
                    Phần thưởng và khuyến mãi
                    </p>
                  </div>
                </label>
              </li>

              <p role="alert" className="sc-gFqAkR cosowq" />
              <li className="sc-dAlyuH bnGnEl my-8">
                <button
                  className="sc-fhzFiK iXBvlY sc-tagGq blRYfq button-active"
                  data-testid="register-submit"
                  disabled={!form.acceptsTerms1 || !form.acceptsTerms2}
                >
                  <div className="sc-jxOSlx gMqhfH ">
                    <span data-translation="profile.create_account">
                      Tạo tài khoản
                    </span>
                  </div>
                </button>
              </li>
            </div>
          </form>
        </div>
      </Modal>
      {contextHolder}
    </>
  )
}

export default ModalRegister
