import { createSlice } from "@reduxjs/toolkit"

export interface HeaderState {
  show: boolean
  wallet: string
}

const initialState: HeaderState = {
  show: true,
  wallet: "",
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const headerSlice = createSlice({
  name: "header",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    show: (state) => {
      state.show = true
    },
    hide: (state) => {
      state.show = false
    },
    addWalletUser: (state, action) => {
      state.wallet = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
})

export const { show, hide, addWalletUser } = headerSlice.actions
export default headerSlice.reducer
