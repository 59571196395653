import React, { useState } from "react"
import Deposit from "src/components/Deposit"
import AddWallet from "src/components/AddWallet"
import { RootState } from "src/app/store"
import { useSelector } from "react-redux"
import { ellipsisText } from "src/util/ellipsisText"
import { MoneyConvert } from "src/util/format"
import useIsMobile from "src/customHook/useIsMobile"
import clsx from "clsx"
import ModalLogin from "src/components/ModalLogin"
import DepositVN from "src/components/DepositVN"

const ActionComponent = () => {
  const { isMobile } = useIsMobile(768)
  const [open, setOpen] = useState(false);
  const [openVN, setOpenVN] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);

  const wallet = useSelector((state: RootState) => state.header.wallet);

  return (
    <>
      <div className="flex gap-2">

        {/* {!wallet &&
          <a>
            <button
              className={clsx("px-2 h-8 md:h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-bulma bg-transparent ring-inset ring-1 ring-trunks hover:ring-bulma w-full font-normal shadow-none hover:shadow-none", !isMobile && "px-4")}
              type="button"
              onClick={() => setOpenWallet(!openWallet)}
            >
              <span data-translation="profile.login">Add Wallet</span>
              <span
                aria-hidden="true"
                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
              />
            </button>
          </a>
        } */}
          <a>
          <button
            className={clsx("px-2 h-8 md:h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo anim-pulse animate-[pulse2_1.5s_infinite] font-normal", !isMobile && "px-4")}
            type="button"
            data-testid="tw-button"
            onClick={() => setOpenVN(!openVN)}
          >
            <span data-translation="profile.signup">Nạp/Rút</span>
            <span
              aria-hidden="true"
              className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
            />
          </button>
        </a>
        <a>
          <button
            className={clsx("px-2 h-8 md:h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo anim-pulse animate-[pulse2_1.5s_infinite] font-normal", !isMobile && "px-4")}
            type="button"
            data-testid="tw-button"
            onClick={() => setOpen(!open)}
          >
            <span data-translation="profile.signup">In/Out $</span>
            <span
              aria-hidden="true"
              className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
            />
          </button>
        </a>
      </div>

      <Deposit open={open} setOpen={setOpen} />
      <DepositVN open={openVN} setOpen={setOpenVN} />
    </>
  )
}

export default ActionComponent
