import { ApiUrl } from "src/constant/endpoints"
import { mainAxios } from "src/services/axiosInstance"

export const depositApi = {
  rechargeUsdt(payload: any) {
    return mainAxios.request({
      url: ApiUrl.RECHARGE_USDT,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
  withdrawalUsdt(payload: any) {
    return mainAxios.request({
      url: ApiUrl.WITHDRAWAL_USDT,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
  recharge(payload: any) {
    return mainAxios.request({
      url: ApiUrl.RECHARGE,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
  getBankListDeposit(payload?: any) {
    return mainAxios.request({
      url: ApiUrl.BANK_LIST_DEPOSIT,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
  setDepositUpdate(payload?: any) {
    return mainAxios.request({
      url: ApiUrl.BANK_DEPOSIT_UPDATE,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
}
