import { ApiUrl } from "src/constant/endpoints"
import { mainAxios } from "src/services/axiosInstance"

export const gameApi = {
  lauchGame(payload: any) {
    return mainAxios.request({
      url: ApiUrl.LAUCH_GAME,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
  getGameProduct() {
    return mainAxios.request({
      url: ApiUrl.GET_GAME_PRODUCT,
      methodType: "POST",
    })
  },
  getSoccer() {
    return mainAxios.request({
      url: ApiUrl.SOCCER,
      methodType: "GET",
    })
  },
}
