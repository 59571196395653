import CustomCarousel from "src/components/Carousel/CustomCarousel";

function Benefits() {

    const dataBenefits = [{
        img: 'https://heathmont.imgix.net/bitcasino/images/loyalty-beginner-small.png?auto=compress,format',
        beginner: 'Người bắt đầu',
        point: '0 - 4',
        levels_points_multiplier: 1,
        levels_reward: ''
    },
    {
        img: 'https://heathmont.imgix.net/bitcasino/images/loyalty-fan-small.png?auto=compress,format',
        beginner: 'Người hâm mộ',
        point: '5 - 3749',
        levels_points_multiplier: 1,
        levels_reward: '1 phần thưởng quan trọng'
    },
    {
        img: 'https://heathmont.imgix.net/bitcasino/images/loyalty-expert-small.png?auto=compress,format',
        beginner: 'Chuyên gia',
        point: '3750 - 29999',
        levels_points_multiplier: 1,
        levels_reward: '6 cột mốc phần thưởng'
    },
    {
        img: 'https://heathmont.imgix.net/bitcasino/images/loyalty-master-small.png?auto=compress,format',
        beginner: 'Bậc thầy',
        point: '30000 - 249999',
        levels_points_multiplier: 1.25,
        levels_reward: '12 cột mốc phần thưởng'
    },
    {
        img: 'https://heathmont.imgix.net/bitcasino/images/loyalty-guru-small.png?auto=compress,format',
        beginner: 'Đạo sư',
        point: '250000 - 749999',
        levels_points_multiplier: 1.5,
        levels_reward: '11 cột mốc phần thưởng'
    },
    {
        img: 'https://heathmont.imgix.net/bitcasino/images/loyalty-legend-small.png?auto=compress,format',
        beginner: 'Huyền thoại',
        point: '750000 - 3749999',
        levels_points_multiplier: 2,
        levels_reward: '12 cột mốc phần thưởng'
    },
    {
        img: 'https://heathmont.imgix.net/bitcasino/images/loyalty-hero2-small.png?auto=compress,format',
        beginner: 'Anh hùng',
        point: '3750000 - ...',
        levels_points_multiplier: 3,
        levels_reward: 'Các phần thưởng game Unlimited milestone'
    }
    ]

    const items = dataBenefits?.map((el: any, index: number) => {
        return <div className="h-full w-72 max-w-full rem:min-h-[420px] xl:w-80">
            <div
                className="relative h-full rounded-moon-s-md bg-gohan bg-[length:100%_auto] bg-no-repeat lg:p-10"
                style={{
                    backgroundImage:
                        'url("//cdn.coingaming.io/bitcasino/images/loyalty_landing_levels_border.svg")'
                }}
            >
                <div className="relative overflow-hidden px-10 py-4 text-center">
                    <div className="mx-auto mb-4 grid h-24 w-24 items-center justify-items-center rounded-full text-center shadow-moon-lg shadow-none">
                        <img
                            alt=""
                            className="relative block h-auto w-full"
                            src={el?.img}
                            loading="lazy"
                        />
                    </div>
                    <h2 className="font-fraunces text-moon-24 text-piccolo">
                        <span data-translation="loyalty.tier.Beginner">
                            {el?.beginner}
                        </span>
                    </h2>
                    <div className="grid gap-1">
                        <p className="text-moon-14 text-trunks">
                            <span data-translation="loyalty_landing.levels_points">
                                Số điểm
                            </span>
                        </p>
                        <h3 className="font-fraunces text-moon-18">{el?.point}</h3>
                    </div>
                    <hr className="my-2 border-t border-dashed border-trunks" />
                    <div className="grid gap-1">
                        <p className="text-moon-14 text-trunks">
                            <span data-translation="loyalty_landing.levels_points_multiplier">
                                Hệ số điểm:
                            </span>
                        </p>
                        <h3 className="font-fraunces text-moon-18">{el?.levels_points_multiplier}X</h3>
                    </div>

                    {el?.levels_reward && <>
                        <hr className="my-2 border-t border-dashed border-trunks" />
                        <div className="grid gap-1">
                            <p className="text-moon-14 text-trunks">
                                <span data-translation="loyalty_landing.levels_milestone_rewards">
                                    Cột mốc Phần thưởng
                                </span>
                            </p>
                            <h3 className="text-moon-18">
                                <span data-translation="loyalty_landing.levels_reward">
                                    {el?.levels_reward}
                                </span>
                            </h3>
                        </div>
                    </>

                    }
                </div>
            </div>
        </div>
    });

    return (
        <div className="max-w-full overflow-hidden mt-8 [&>*]:mb-8 last:[&>*]:mb-0">
            <div className="[&>*]:mb-8 last:[&>*]:mb-0">
                <div className="relative mb-4 grid grid-flow-col text-center">
                    <img
                        alt=""
                        className="relative top-2 md:hidden"
                        src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.benefits_border_mobile.svg?auto=compress,format"
                        loading="lazy"
                    />
                    <div className="grid gap-4">
                        <h2 className="text-moon-24 font-fraunces font-light">
                            <span data-translation="loyalty_landing.benefits_main_title">
                                6 lợi ích của CLB Khách hàng thân thiết
                            </span>
                        </h2>
                    </div>
                    <img
                        alt=""
                        className="relative top-2 -scale-x-100 justify-self-end md:hidden"
                        src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.benefits_border_mobile.svg?auto=compress,format"
                        loading="lazy"
                    />
                </div>
                <div className="px-5 xl:hidden">
                    <div data-testid="carousel-wrapper">
                        <div className="relative w-full">
                            <button
                                className="h-8 p-1 gap-1 rounded-moon-i-sm flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-moon-24 max-sm:hidden shadow-moon-sm bg-gohan text-bulma absolute top-1/2 -translate-y-1/2 origin-[top_center] z-5 -start-4"
                                type="button"
                                aria-label="Scroll left"
                            >
                                <svg
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.5 25L11.5 16L20.5 7"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span
                                    aria-hidden="true"
                                    className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                />
                            </button>
                            <ul className='flex overflow-x-auto overflow-y-hidden h-auto hidden-scroll [-webkit-overflow-scrolling:touch] [scrollbar-width:none] [-ms-overflow-style:-ms-autohiding-scrollbar] [&>li]:list-none [&>li]:before:absolute [&>li]:before:content-["\200B"] [&>*]:flex-[0_0_auto] [&>img]:h-full [&>img]:basis-auto [&>img]:w-auto snap-x snap-mandatory rtl:flex-row-reverse gap-3'>
                                <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                    <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                                        <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                            <div className="mb-4 grid gap-4">
                                                <img
                                                    alt=""
                                                    className="w-32"
                                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-0.png?auto=compress,format"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <h3 className="font-fraunces text-moon-24 text-krillin">
                                                <span data-translation="loyalty_landing.benefits_title_1">
                                                    Cấp độ &amp; phần thưởng
                                                </span>
                                            </h3>
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.benefits_text_1">
                                                    Tham gia một cuộc phiêu lưu lớn cấp độ 7 và các phần
                                                    thưởng lớn không giới hạn
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                    <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                                        <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                            <div className="mb-4 grid gap-4">
                                                <img
                                                    alt=""
                                                    className="w-32"
                                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-1.png?auto=compress,format"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <h3 className="font-fraunces text-moon-24 text-krillin">
                                                <span data-translation="loyalty_landing.benefits_title_2">
                                                    Giá trị phát triển
                                                </span>
                                            </h3>
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.benefits_text_2">
                                                    Bạn đặt cược càng nhiều, càng có nhiều lợi ích
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                    <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                                        <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                            <div className="mb-4 grid gap-4">
                                                <img
                                                    alt=""
                                                    className="w-32"
                                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-2.png?auto=compress,format"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <h3 className="font-fraunces text-moon-24 text-krillin">
                                                <span data-translation="loyalty_landing.benefits_title_3">
                                                    Điểm cho mỗi lần quay
                                                </span>
                                            </h3>
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.benefits_text_3">
                                                    Chơi ngay bây giờ và kiếm điểm cho mỗi lần quay!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                    <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                                        <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                            <div className="mb-4 grid gap-4">
                                                <img
                                                    alt=""
                                                    className="w-32"
                                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-3.png?auto=compress,format"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <h3 className="font-fraunces text-moon-24 text-krillin">
                                                <span data-translation="loyalty_landing.benefits_title_4">
                                                    Giảm giá độc quyền
                                                </span>
                                            </h3>
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.benefits_text_4">
                                                    {" "}
                                                    Nhận hoàn tiền đến 10%!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                    <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                                        <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                            <div className="mb-4 grid gap-4">
                                                <img
                                                    alt=""
                                                    className="w-32"
                                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-4.png?auto=compress,format"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <h3 className="font-fraunces text-moon-24 text-krillin">
                                                <span data-translation="loyalty_landing.benefits_title_5">
                                                    Máy game slot &amp; game chơi trên bàn yêu thích
                                                </span>
                                            </h3>
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.benefits_text_5">
                                                    Chơi tất cả các trò chơi khe &amp; trò chơi bàn yêu
                                                    thích của bạn
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                    <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                                        <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                            <div className="mb-4 grid gap-4">
                                                <img
                                                    alt=""
                                                    className="w-32"
                                                    src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-5.png?auto=compress,format"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <h3 className="font-fraunces text-moon-24 text-krillin">
                                                <span data-translation="loyalty_landing.benefits_title_6">
                                                    Phần thưởng năng động
                                                </span>
                                            </h3>
                                            <div className="text-moon-16 text-trunks">
                                                <span data-translation="loyalty_landing.benefits_text_6">
                                                    Các gói quay miễn phí được phát sinh dựa theo lối chơi
                                                    của bạn.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <button
                                className="h-8 p-1 gap-1 rounded-moon-i-sm flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-moon-24 max-sm:hidden shadow-moon-sm bg-gohan text-bulma absolute top-1/2 -translate-y-1/2 origin-[top_center] z-5 -end-4"
                                type="button"
                                aria-label="Scroll right"
                            >
                                <svg
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.5 7L20.5 16L11.5 25"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span
                                    aria-hidden="true"
                                    className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="relative mx-auto mb-8 hidden px-4 rem:max-w-[1104px] xl:block">
                    <div className="relative grid grid-cols-2 grid-rows-3 gap-4 xl:grid-cols-3 xl:grid-rows-2">
                        <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                            <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                <div className="mb-4 grid gap-4">
                                    <img
                                        alt=""
                                        className="w-32"
                                        src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-0.png?auto=compress,format"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="font-fraunces text-moon-24 text-krillin">
                                    <span data-translation="loyalty_landing.benefits_title_1">
                                        Cấp độ &amp; phần thưởng
                                    </span>
                                </h3>
                                <div className="text-moon-16 text-trunks">
                                    <span data-translation="loyalty_landing.benefits_text_1">
                                        Tham gia một cuộc phiêu lưu lớn cấp độ 7 và các phần thưởng
                                        lớn không giới hạn
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                            <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                <div className="mb-4 grid gap-4">
                                    <img
                                        alt=""
                                        className="w-32"
                                        src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-1.png?auto=compress,format"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="font-fraunces text-moon-24 text-krillin">
                                    <span data-translation="loyalty_landing.benefits_title_2">
                                        Giá trị phát triển
                                    </span>
                                </h3>
                                <div className="text-moon-16 text-trunks">
                                    <span data-translation="loyalty_landing.benefits_text_2">
                                        Bạn đặt cược càng nhiều, càng có nhiều lợi ích
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                            <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                <div className="mb-4 grid gap-4">
                                    <img
                                        alt=""
                                        className="w-32"
                                        src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-2.png?auto=compress,format"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="font-fraunces text-moon-24 text-krillin">
                                    <span data-translation="loyalty_landing.benefits_title_3">
                                        Điểm cho mỗi lần quay
                                    </span>
                                </h3>
                                <div className="text-moon-16 text-trunks">
                                    <span data-translation="loyalty_landing.benefits_text_3">
                                        Chơi ngay bây giờ và kiếm điểm cho mỗi lần quay!
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                            <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                <div className="mb-4 grid gap-4">
                                    <img
                                        alt=""
                                        className="w-32"
                                        src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-3.png?auto=compress,format"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="font-fraunces text-moon-24 text-krillin">
                                    <span data-translation="loyalty_landing.benefits_title_4">
                                        Giảm giá độc quyền
                                    </span>
                                </h3>
                                <div className="text-moon-16 text-trunks">
                                    <span data-translation="loyalty_landing.benefits_text_4">
                                        {" "}
                                        Nhận hoàn tiền đến 10%!
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                            <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                <div className="mb-4 grid gap-4">
                                    <img
                                        alt=""
                                        className="w-32"
                                        src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-4.png?auto=compress,format"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="font-fraunces text-moon-24 text-krillin">
                                    <span data-translation="loyalty_landing.benefits_title_5">
                                        Máy game slot &amp; game chơi trên bàn yêu thích
                                    </span>
                                </h3>
                                <div className="text-moon-16 text-trunks">
                                    <span data-translation="loyalty_landing.benefits_text_5">
                                        Chơi tất cả các trò chơi khe &amp; trò chơi bàn yêu thích của
                                        bạn
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ml-4 h-full w-72 rounded-moon-s-sm bg-gohan p-6 text-center first-of-type:m-0 rem:min-h-[260px] xl:m-0 xl:min-h-0 xl:w-auto">
                            <div className="grid grid-rows-[100px_auto_auto] items-center justify-items-center gap-2">
                                <div className="mb-4 grid gap-4">
                                    <img
                                        alt=""
                                        className="w-32"
                                        src="//heathmont.imgix.net/bitcasino/images/loyalty-landing/benefit-5.png?auto=compress,format"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="font-fraunces text-moon-24 text-krillin">
                                    <span data-translation="loyalty_landing.benefits_title_6">
                                        Phần thưởng năng động
                                    </span>
                                </h3>
                                <div className="text-moon-16 text-trunks">
                                    <span data-translation="loyalty_landing.benefits_text_6">
                                        Các gói quay miễn phí được phát sinh dựa theo lối chơi của
                                        bạn.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-4 px-5 justify-items-center">
                    <button
                        className="px-4 h-10 py-2 gap-2 rounded-moon-i-sm relative z-0 flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo text-moon-16"
                        type="button"
                    >
                        <span data-translation="profile.create_account">Create account</span>
                        <span
                            aria-hidden="true"
                            className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                        />
                    </button>
                </div>
            </div>
            <div className="m-auto text-center rem:max-w-[1104px]">
                <div className="[&>*]:mb-8 last:[&>*]:mb-0">
                    <div className="relative mb-4 grid grid-flow-col text-center">
                        <img
                            alt=""
                            className="relative top-2 md:hidden"
                            src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.benefits_border_mobile.svg?auto=compress,format"
                            loading="lazy"
                        />
                        <div className="grid gap-4">
                            <h2 className="text-moon-24 font-fraunces font-light">
                                <span data-translation="loyalty_landing.levels_title">
                                    Bạn có thể đạt được cấp độ 7{" "}
                                </span>
                            </h2>
                        </div>
                        <img
                            alt=""
                            className="relative top-2 -scale-x-100 justify-self-end md:hidden"
                            src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.benefits_border_mobile.svg?auto=compress,format"
                            loading="lazy"
                        />
                    </div>
                    <div className="hidden md:block">
                        <div className="text-center">
                            <div className="text-moon-16 text-trunks">
                                <span data-translation="loyalty_landing.levels_subtitle">
                                    Cấp độ càng cao, lợi ích càng tốt hơn
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mx-5">
                        <div data-testid="carousel-wrapper">
                            <div className="relative w-full mb-8">
                                <CustomCarousel items={items ?? []} step={1} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Benefits;
