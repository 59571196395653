import DesktopInfoTooltip from './DesktopInfoTooltip';
import MobileInfoTooltip from './MobileInfoTooltip';
// import GameDetailsTooltip from '../../../GameDetailsTooltip';
import InfoButton from '../InfoButton';
import useIsMobile from 'src/customHook/useIsMobile';
import { Link } from 'react-router-dom';

type Props = {
  game: any;
};

const InfoTooltip: React.FC<Props> = ({ game }) => {
  const { isMobile } = useIsMobile(768)

  if (!game.rtp) {
    return null;
  }

  if (!isMobile) {
    return (
      <DesktopInfoTooltip
        trigger={<InfoButton />}
        content={
          <div>
            <div className="grid grid-flow-col items-center justify-between gap-2 rounded-moon-s-sm bg-gohan px-3 py-2 md:min-w-[18.75rem] mb-2">
              <span>Khách hàng thân thiết</span>
              <span className='font-medium font-system'>Không bao gồm</span>
            </div>
            <div className="grid grid-flow-col items-center justify-between gap-2 rounded-moon-s-sm bg-gohan px-3 py-2 md:min-w-[18.75rem] mb-2">
              <span>RTP</span>
              <span className='font-medium font-system'>89.5%</span>
            </div>
            <div className="grid grid-flow-col items-center justify-between gap-2 rounded-moon-s-sm bg-gohan px-3 py-2 md:min-w-[18.75rem]">
              <span>Cược tối thiểu -tối đa</span>
              <span className='font-medium font-system'>0.2 -100 USDT</span>
            </div>

            <div className="mb-2 mr-2 inline-block mt-4">
              <Link to={`/games`}>
                <button
                  className="border border-solid border-beerus shadow-none hover:shadow-none px-1"
                >
                  <span className="text-moon-10-caption font-medium uppercase text-bulma">
                    #Thưởng khi mua tính năng
                  </span>
                </button>
              </Link>
              <Link to={`/games`}>
                <button
                  className="border border-solid border-beerus shadow-none hover:shadow-none mx-2 px-1"
                >
                  <span className="text-moon-10-caption font-medium uppercase text-bulma">
                    #MEGAGAMES
                  </span>
                </button>
              </Link>
              <Link to={`/games`}>
                <button
                  className="border border-solid border-beerus shadow-none hover:shadow-none mx-2 px-1"
                >
                  <span className="text-moon-10-caption font-medium uppercase text-bulma">
                    #OCTOPLAY
                  </span>
                </button>
              </Link>
            </div>
          </div>}
      />
    );
  }

  return (
    <MobileInfoTooltip
      trigger={<InfoButton />}
      content={
        <div>
          <div className="grid grid-flow-col items-center justify-between gap-2 rounded-moon-s-sm bg-gohan px-3 py-2 md:min-w-[18.75rem]">
            RTP
          </div>
        </div>
      }
    />
  );
};

export default InfoTooltip;
