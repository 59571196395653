
function Vip() {
    return (
        <div className="min-h-screen w-full">
            <div className="flex h-full w-full flex-nowrap overflow-hidden">
                <div className="z-10 grow overflow-x-hidden">
                    <div className="flex w-full grow flex-col overflow-hidden">
                        <div className="relative h-[918px] px-16 pb-28 pt-32 bg-[#F8EBE9]">
                            <div
                                style={{
                                    backgroundImage:
                                        "url(//heathmont.imgix.net/bitcasino/images/VIP/pattern-bg.png?auto=compress,format)"
                                }}
                                className="absolute inset-0 bg-cover"
                            />
                            <div className="m-auto grid h-full max-w-[100rem] grid-cols-[50%_auto] grid-rows-1 items-center justify-center 2xl:grid-cols-[40%_auto] 3xl:grid-cols-[30%_auto]">
                                <div className="relative text-popo">
                                    <p
                                        className="break-words py-4 text-center font-fraunces text-[32px] font-normal leading-10 sm:px-0 sm:text-left sm:text-moon-48 lg:text-[64px] lg:leading-[4.5rem]"
                                        style={{ letterSpacing: "-2px" }}
                                    >
                                        Betcasino VIP: Tiền không thể mua được trải nghiệm
                                    </p>
                                    <p className="px-5 pb-8 text-center text-moon-16 xl:text-moon-24 break-words items-center mt-3 sm:px-0 sm:text-left sm:text-[18px] sm:leading-6 md:text-[24px] md:leading-8" />
                                    <p>
                                        Chỉ dành cho những người chơi được mời, câu lạc bộ VIP luôn phấn
                                        đấu để đạt được sự xuất sắc trong các dịch vụ, ưu đãi và sự
                                        kiện.
                                    </p>
                                    <p />
                                    <div className="relative flex">
                                        <a href="/auth/register">
                                            <button
                                                className="h-12 relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo mt-6 gap-1 self-start rounded-moon-i-sm p-[20px] px-4 py-3 text-moon-16 font-semibold sm:min-w-[8.5rem]"
                                                type="button"
                                            >
                                                <span data-translation="profile.signup">Sign up</span>
                                                <span
                                                    aria-hidden="true"
                                                    className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                                />
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="relative z-1 flex max-w-[140%] items-center justify-center sm:max-w-full">
                                    <img
                                        alt=""
                                        className="max-h-full w-auto max-w-none overflow-hidden sm:max-w-full"
                                        src="//heathmont.imgix.net/bitcasino/images//VIP/vippage.png?auto=compress,format?auto=compress,format"
                                        loading="lazy"
                                    />
                                    <button
                                        className="gap-2 text-moon-14 flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo anim-pulse animate-[pulse2_1.5s_infinite] absolute bottom-0 z-1 h-16 w-16 rounded-full p-3 sm:bottom-10"
                                        type="button"
                                    >
                                        <img
                                            alt=""
                                            className="m-auto w-6"
                                            src="//heathmont.imgix.net/bitcasino/images/icons/play-triangle.svg?auto=compress,format"
                                            loading="lazy"
                                        />
                                        <span
                                            aria-hidden="true"
                                            className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 flex flex-col items-center self-center sm:mt-[88px]">
                            <div className="mb-8 flex max-w-[100vw] flex-col items-center sm:mb-0 sm:w-[600px] lg:w-[640px] lg:pr-6 xl:w-[930px] 2xl:w-[1230px]">
                                <p className="p-4 text-center font-fraunces text-[32px] font-normal sm:text-moon-32 lg:text-moon-48 w-[380px] leading-10 xl:w-[944px] sm:w-[600px] sm:leading-0 md:flex-wrap">
                                    Cá nhân hoá dịch vụ
                                </p>
                                <p className="px-5 pb-8 text-center text-moon-16 xl:text-moon-24 w-[348px] lg:w-[700px] sm:pb-0 sm:pt-4" />
                                <p className="normal wide">
                                    Với đội ngũ quản lý tài khoản chuyên biệt đảm bảo rằng mọi thứ
                                    luôn ở tiêu chuẩn cao nhất, các thành viên được hưởng tiền thưởng
                                    độc quyền, được mời tham dự các sự kiện uy tín và các giải pháp
                                    phù hợp để mang lại trải nghiệm thú vị, nhanh chóng và công bằng
                                    cho họ.
                                </p>
                                <p />
                                <div className="max-w-full pl-5 sm:mt-12 lg:mt-[124px] pr-6">
                                    <div data-testid="carousel-wrapper">
                                        <div className="relative w-full">
                                            <button
                                                className="p-1 gap-1 font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 opacity-60 cursor-not-allowed text-moon-24 max-sm:hidden shadow-moon-sm text-bulma origin-[top_center] z-5 -start-4 absolute top-[50%] flex h-8 w-8 -translate-x-[150%] -translate-y-[50%] items-center justify-center rounded-full bg-gohan disabled:hidden"
                                                type="button"
                                                disabled
                                                aria-label="Scroll left"
                                            >
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M20.5 25L11.5 16L20.5 7"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <span
                                                    aria-hidden="true"
                                                    className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                                />
                                            </button>
                                            <ul className='flex overflow-x-auto overflow-y-hidden h-auto hidden-scroll [-webkit-overflow-scrolling:touch] [scrollbar-width:none] [-ms-overflow-style:-ms-autohiding-scrollbar] [&>li]:list-none [&>li]:before:absolute [&>li]:before:content-["\200B"] [&>*]:flex-[0_0_auto] [&>img]:h-full [&>img]:basis-auto [&>img]:w-auto snap-x snap-mandatory rtl:flex-row-reverse gap-4'>
                                                <li
                                                    className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent"

                                                >
                                                    <div className="flex h-full w-[276px] flex-col self-start rounded-lg bg-gohan px-5 pb-6 pt-5 sm:h-auto">
                                                        <div className="box-content flex h-[72px] items-center justify-center pb-4 pt-3">
                                                            <img
                                                                alt="Người quản lý VIP tận tâm"
                                                                className=""
                                                                src="//heathmont.imgix.net/bitcasino/images/VIP/vip-handshake.png?auto=compress,format"
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <p className="mb-4 w-full text-center font-fraunces text-moon-18 font-normal">
                                                            Người quản lý VIP tận tâm
                                                        </p>
                                                        <ul className="w-full list-disc ps-4">
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    Sẽ CHỈ tập trung vào nhu cầu của bạn.{" "}
                                                                </p>
                                                            </li>{" "}
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    {" "}
                                                                    Trải nghiệm sòng bạc t nhân được điều chỉnh chính
                                                                    xác cho bạn.
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li
                                                    className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent"

                                                >
                                                    <div className="flex h-full w-[276px] flex-col rounded-lg bg-gohan px-5 pb-6 pt-5 sm:h-auto self-end sm:mt-[40%]">
                                                        <div className="box-content flex h-[72px] items-center justify-center pb-4 pt-3">
                                                            <img
                                                                alt="Phần thưởng giá trị cao"
                                                                className=""
                                                                src="//heathmont.imgix.net/bitcasino/images/VIP/vip-wallet.png?auto=compress,format"
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <p className="mb-4 w-full text-center font-fraunces text-moon-18 font-normal">
                                                            Phần thưởng giá trị cao
                                                        </p>
                                                        <ul className="w-full list-disc ps-4">
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    Ưu đãi hấp dẫn được mô phỏng chỉ dành cho bạn.{" "}
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    {" "}
                                                                    Không phải đặt cược phần thưởng để nhận.{" "}
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    Tiền thưởng hàng tuần.
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li
                                                    className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent"

                                                >
                                                    <div className="flex h-full w-[276px] flex-col self-start rounded-lg bg-gohan px-5 pb-6 pt-5 sm:h-auto">
                                                        <div className="box-content flex h-[72px] items-center justify-center pb-4 pt-3">
                                                            <img
                                                                alt="Giới hạn đặt cược cao nhất trong ngành"
                                                                className=""
                                                                src="//heathmont.imgix.net/bitcasino/images/VIP/vip-coins.png?auto=compress,format"
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <p className="mb-4 w-full text-center font-fraunces text-moon-18 font-normal">
                                                            Giới hạn đặt cược cao nhất trong ngành
                                                        </p>
                                                        <ul className="w-full list-disc ps-4">
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    Không có giới hạn rút và nạp tiền điện tử!
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li
                                                    className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent"

                                                >
                                                    <div className="flex h-full w-[276px] flex-col rounded-lg bg-gohan px-5 pb-6 pt-5 sm:h-auto self-end sm:mt-[40%]">
                                                        <div className="box-content flex h-[72px] items-center justify-center pb-4 pt-3">
                                                            <img
                                                                alt="Hỗ trợ ưu tiên 24/7"
                                                                className=""
                                                                src="//heathmont.imgix.net/bitcasino/images/VIP/vip-support.png?auto=compress,format"
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <p className="mb-4 w-full text-center font-fraunces text-moon-18 font-normal">
                                                            Hỗ trợ ưu tiên 24/7
                                                        </p>
                                                        <ul className="w-full list-disc ps-4">
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    Không phải xếp hàng, không phải chờ đợi.{" "}
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    {" "}
                                                                    Chúng tôi đáp ứng nhu cầu của bạn trước tiên.
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent">
                                                    <div className="flex h-full w-[276px] flex-col self-start rounded-lg bg-gohan px-5 pb-6 pt-5 sm:h-auto mr-4">
                                                        <div className="box-content flex h-[72px] items-center justify-center pb-4 pt-3">
                                                            <img
                                                                alt="Quà tặng ngày lễ sang trọng"
                                                                className=""
                                                                src="//heathmont.imgix.net/bitcasino/images/VIP/vip-gift.svg?auto=compress,format"
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <p className="mb-4 w-full text-center font-fraunces text-moon-18 font-normal">
                                                            Quà tặng ngày lễ sang trọng
                                                        </p>
                                                        <ul className="w-full list-disc ps-4">
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    Quà tặng có giá trị cao vào ngày sinh nhật của
                                                                    bạn.{" "}
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="w-full text-moon-16">
                                                                    {" "}
                                                                    Ưu đãi được cá nhân hóa.
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            <button
                                                className="p-1 gap-1 font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-moon-24 max-sm:hidden shadow-moon-sm text-bulma origin-[top_center] z-5 -end-4 absolute right-0 top-[50%] flex h-8 w-8 -translate-y-[50%] translate-x-[150%] items-center justify-center rounded-full bg-gohan disabled:hidden"
                                                type="button"
                                                aria-label="Scroll right"
                                            >
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11.5 7L20.5 16L11.5 25"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <span
                                                    aria-hidden="true"
                                                    className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-[6] sm:mt-[70px] sm:px-8 md:mt-[120px] xl:mt-[200px]">
                                <div className="flex flex-col items-center sm:flex-row-reverse sm:flex-wrap sm:justify-center">
                                    <div className="flex w-[380px] flex-col justify-center gap-4 sm:mb-[12] sm:w-[100%] sm:flex-col-reverse sm:self-stretch md:mb-20 lg:ml-16 lg:px-0 lg:pl-9 xl:w-[30%] 2xl:ml-24">
                                        <div>
                                            <p className="p-4 text-center font-fraunces text-[32px] font-normal sm:text-moon-32 lg:text-moon-48 md:text-left md:self-start sm:pl-0 w-full">
                                                Sự kiện không biên giới
                                            </p>
                                            <p className="pb-8 text-moon-16 xl:text-moon-24 text-center px-5 sm:px-0 xl:text-left" />
                                            <p>
                                                {" "}
                                                Câu lạc bộ VIP hoạt động xa hơn nữa để tạo ra những sự kiện
                                                khó quên trên toàn cầu. Tất cả các sự kiện luôn miễn phí chi
                                                phí và lên kế hoạch cho các thành viên tham dự (người trúng
                                                thưởng khuyến mãi VIP, người đạt giải cao). Hãy tận hưởng
                                                cuộc sống một cách trọn vẹn nhất và tạo ra những kỷ niệm
                                                tuyệt vời với tư cách là thành viên VIP Club.{" "}
                                            </p>
                                            <p />
                                        </div>
                                        <div className="col-start-2 mb-2 grid grid-cols-[1fr_1fr] gap-2 rounded-xl border border-solid border-beerus p-1 text-center sm:flex sm:w-fit sm:self-start">
                                            <button className="group flex items-center justify-center rounded-md p-1.5 px-3 text-sm transition-colors bg-gohan shadow-sm">
                                                <span data-translation="vip.past_events">
                                                    Những sự kiện đã qua
                                                </span>
                                            </button>
                                            <button className="group flex items-center justify-center rounded-md p-1.5 px-3 text-sm transition-colors">
                                                <span data-translation="vip.upcoming_events">
                                                    Sự kiện sắp tới
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-[410px] max-w-[100vw] py-8 sm:ml-0 sm:h-full sm:px-5 lg:h-[684px] lg:w-[630px]">
                                        <div data-testid="carousel-wrapper">
                                            <div className="relative w-full">
                                                <button
                                                    className="p-1 gap-1 font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 opacity-60 cursor-not-allowed text-moon-24 max-sm:hidden shadow-moon-sm text-bulma origin-[top_center] z-5 -start-4 absolute top-[50%] flex h-8 w-8 -translate-x-[150%] -translate-y-[50%] items-center justify-center rounded-full bg-gohan disabled:hidden"
                                                    type="button"
                                                    disabled
                                                    aria-label="Scroll left"
                                                >
                                                    <svg
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M20.5 25L11.5 16L20.5 7"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <span
                                                        aria-hidden="true"
                                                        className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                                    />
                                                </button>
                                                <ul className='flex overflow-x-auto overflow-y-hidden h-auto hidden-scroll [-webkit-overflow-scrolling:touch] [scrollbar-width:none] [-ms-overflow-style:-ms-autohiding-scrollbar] [&>li]:list-none [&>li]:before:absolute [&>li]:before:content-["\200B"] [&>*]:flex-[0_0_auto] [&>img]:h-full [&>img]:basis-auto [&>img]:w-auto snap-x snap-mandatory rtl:flex-row-reverse gap-3'>
                                                    <li
                                                        className="snap-center flex rounded-moon-s-sm items-center justify-center bg-transparent"

                                                    >
                                                        <div className="flex h-full w-full p-6">
                                                            <div className="me-4 flex w-[160px] flex-col items-stretch lg:w-[272px]">
                                                                <div className="h-16" />
                                                                <div className="grow-1 relative h-[180px] w-[160px] overflow-hidden rounded-lg lg:h-[316px] lg:w-[272px] mb-4">
                                                                    <img
                                                                        alt=""
                                                                        className="h-full object-cover"
                                                                        src="//heathmont.imgix.net/bitcasino/images/VIP Page/Grand Prix F1 Suzuka/Japan Grand Prix F1.jpg?auto=compress,format"
                                                                        loading="lazy"
                                                                    />
                                                                    <div className="pointer absolute left-0 top-0 flex h-full w-full items-end bg-[#0000008F] opacity-0 hover:opacity-100">
                                                                        <p className="m-4 w-full text-moon-18 font-semibold text-goten">
                                                                            The Japanese Grand Prix in September 2023
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="grow-1 relative h-[180px] w-[160px] overflow-hidden rounded-lg lg:h-[316px] lg:w-[272px] ">
                                                                    <img
                                                                        alt=""
                                                                        className="h-full object-cover"
                                                                        src="//heathmont.imgix.net/bitcasino/images/VIP Page/UEFA Istanbul 2023/uefa_istanbul 2023.jpg?auto=compress,format"
                                                                        loading="lazy"
                                                                    />
                                                                    <div className="pointer absolute left-0 top-0 flex h-full w-full items-end bg-[#0000008F] opacity-0 hover:opacity-100">
                                                                        <p className="m-4 w-full text-moon-18 font-semibold text-goten">
                                                                            Chung kết UEFA Champions League tại Istanbul
                                                                            2023
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex w-[160px] flex-col items-stretch lg:w-[272px]">
                                                                <div className="grow-1 relative h-[180px] w-[160px] overflow-hidden rounded-lg lg:h-[316px] lg:w-[272px] mb-4">
                                                                    <img
                                                                        alt=""
                                                                        className="h-full object-cover"
                                                                        src="//heathmont.imgix.net/bitcasino/images/VIP Page/World Cup 2022 Qatar/world cup qatar 2022.jpg?auto=compress,format"
                                                                        loading="lazy"
                                                                    />
                                                                    <div className="pointer absolute left-0 top-0 flex h-full w-full items-end bg-[#0000008F] opacity-0 hover:opacity-100">
                                                                        <p className="m-4 w-full text-moon-18 font-semibold text-goten">
                                                                            {" "}
                                                                            World Cup 2022 tại Quatar
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="grow-1 relative h-[180px] w-[160px] overflow-hidden rounded-lg lg:h-[316px] lg:w-[272px] ">
                                                                    <img
                                                                        alt=""
                                                                        className="h-full object-cover"
                                                                        src="//heathmont.imgix.net/bitcasino/images/VIP Page/US Open Final 2022/US Open Final 2022.jpg?auto=compress,format"
                                                                        loading="lazy"
                                                                    />
                                                                    <div className="pointer absolute left-0 top-0 flex h-full w-full items-end bg-[#0000008F] opacity-0 hover:opacity-100">
                                                                        <p className="m-4 w-full text-moon-18 font-semibold text-goten">
                                                                            US Open Final in New York City
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="h-16" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <button
                                                    className="p-1 gap-1 font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 opacity-60 cursor-not-allowed text-moon-24 max-sm:hidden shadow-moon-sm text-bulma origin-[top_center] z-5 -end-4 absolute right-0 top-[50%] flex h-8 w-8 -translate-y-[50%] translate-x-[150%] items-center justify-center rounded-full bg-gohan disabled:hidden"
                                                    type="button"
                                                    disabled
                                                    aria-label="Scroll right"
                                                >
                                                    <svg
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M11.5 7L20.5 16L11.5 25"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <span
                                                        aria-hidden="true"
                                                        className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 flex w-[95vw] flex-col p-4 sm:mt-0 sm:w-[100%] md:mx-auto md:w-[80%] lg:mt-[200px] lg:w-[672px]">
                                <p className="p-4 text-center font-fraunces text-[32px] font-normal sm:text-moon-32 lg:text-moon-48 w-full">
                                    Trở thành VIP?
                                </p>
                                <p className="px-5 pb-8 text-center text-moon-16 xl:text-moon-24 w-full" />
                                <p className="normal full-width">
                                    Việc gia nhận vào Câu lạc bộ VIP chỉ có thể bằng việc được nhận
                                    lời mời. Bạn hãy liên lạc với chúng tôi để xem gói VIP phù hợp với
                                    bạn sẽ như thế nào. Hãy tham gia gia đình chúng tôi ngay bây giờ!
                                </p>
                                <p />
                                <div className="flex w-full flex-col sm:w-auto sm:flex-row sm:items-center sm:justify-center">
                                    <a className="" href="/auth/register">
                                        <button
                                            className="h-10 gap-2 relative z-0 flex justify-center items-center no-underline overflow-hidden select-none transition duration-200 active:scale-90 bg-piccolo w-full min-w-[8.5rem] whitespace-nowrap rounded-moon-i-sm p-[20px] text-moon-16 font-semibold text-gohan sm:mx-4 sm:w-auto sm:self-start sm:px-5 sm:py-3 my-2"
                                            type="button"
                                            data-testid="tw-button"
                                        >
                                            <span data-translation="profile.signup">Sign up</span>
                                            <span
                                                aria-hidden="true"
                                                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                            />
                                        </button>
                                    </a>
                                    <a href="mailto:vip@bitcasino.io?subject=Want to know more about the VIP club">
                                        <button
                                            className="h-10 gap-2 rounded-moon-i-sm z-0 justify-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-bulma relative my-2 flex w-full items-center bg-heles p-5 text-moon-16 sm:w-auto sm:px-5 sm:py-3"
                                            type="button"
                                            data-testid="tw-button"
                                        >
                                            <span aria-hidden="true" className="text-moon-24">
                                                <svg
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="text-moon-24"
                                                >
                                                    <path
                                                        d="M6.5 11L13.8221 15.7529C15.1467 16.6128 16.8532 16.6128 18.1779 15.7529L25.5 11M6.5 11L6.5 21C6.5 22.1046 7.39543 23 8.5 23L23.5 23C24.6046 23 25.5 22.1046 25.5 21V11M6.5 11C6.5 9.89546 7.39543 9.00002 8.5 9.00001L23.5 9C24.6046 9 25.5 9.89546 25.5 11"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                            <p className="whitespace-nowrap">Liên hệ với chúng tôi</p>
                                            <span
                                                aria-hidden="true"
                                                className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                                            />
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="mt-[70px] mb-8 sm:mt-[88px]">
                                <div className="flex flex-col items-center">
                                    <p className="mb-4 p-4 text-center font-fraunces font-normal text-[32px]">
                                        Giữ kết nối với chúng tôi qua mạng xã hội
                                    </p>
                                    <div className="flex gap-2">
                                        <div className="flex h-[35px] w-[35px] items-center justify-center">
                                            <a href="https://t.me/bitcasino_official">
                                                <img
                                                    src="//cdn.coingaming.io/bitcasino/images/icons/footer/footer/telegram-new.png"
                                                    alt="telegram"
                                                />
                                            </a>
                                        </div>
                                        <div className="flex h-[35px] w-[35px] items-center justify-center">
                                            <a href="https://bit.ly/yt-bchome">
                                                <img
                                                    src="//cdn.coingaming.io/bitcasino/images/icons/footer/footer/youtube.png"
                                                    alt="youtube"
                                                />
                                            </a>
                                        </div>
                                        <div className="flex h-[35px] w-[35px] items-center justify-center">
                                            <a href="https://bitcointalk.org/index.php?topic=4541166.0">
                                                <img
                                                    src="//cdn.coingaming.io/bitcasino/images/icons/footer/footer/bitcointalk.png"
                                                    alt="bitcointalk"
                                                />
                                            </a>
                                        </div>
                                        <div className="flex h-[35px] w-[35px] items-center justify-center">
                                            <a href="https://twitter.com/bitcasinoio">
                                                <img
                                                    src="//cdn.coingaming.io/bitcasino/images/icons/footer/footer/twitter.png"
                                                    alt="twitter"
                                                />
                                            </a>
                                        </div>
                                        <div className="flex h-[35px] w-[35px] items-center justify-center">
                                            <a href="https://www.instagram.com/bitcasino/">
                                                <img
                                                    src="//cdn.coingaming.io/bitcasino/images/icons/footer/footer/instagram-new.png"
                                                    alt="instagram"
                                                />
                                            </a>
                                        </div>
                                        <div className="flex h-[35px] w-[35px] items-center justify-center">
                                            <a href="https://bit.ly/tt-bchome">
                                                <img
                                                    src="//cdn.coingaming.io/bitcasino/images/icons/footer/footer/tiktok.svg"
                                                    alt="tiktok"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Vip;
