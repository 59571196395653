import { mergeClassnames } from "@heathmont/moon-core-tw";
import { Table, Tabs } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userApi } from "src/api/userApi";
import { ellipsisText } from "src/util/ellipsisText";

function WithdrawHistory() {
    const [tab, setTab] = useState('withdraw');
    const navigate = useNavigate()

    const [data, setData] = useState([]);
    const statusList: any = { 0: 'Pending', 1: 'Success', 2: 'Cancel' }

    const itemsTabs = [
        {
            label: `Tài khoản`,
            key: "profile",
        },
        {
            label: 'Lịch sử nạp tiền',
            key: "recharge",
        },
        {
            label: "Lịch sử rút tiền",
            key: "withdraw",
        },
        {
            label: "Khuyến mãi",
            key: "promotion",
        },
    ];

    const columns = [
        {
            title: "Phone Number",
            dataIndex: "phone",
        },
        {
            title: "Money",
            dataIndex: 'money',
        },
        {
            title: "Type",
            dataIndex: 'type',
        },
        {
            title: "Status",
            dataIndex: 'status',
            render: (status: number) => (
                <span className={mergeClassnames(
                    status === 0 && 'text-yellow',
                    status === 1 && 'text-green',
                    status === 2 && 'text-red'
                )}>{statusList[status]}</span>
            ),
        },
        {
            title: "Time",
            dataIndex: 'time',
            render: (time: string, record: any) => (
                <span>{moment(new Date(Number(time))).format('YYYY-MM-DD, HH:mm')}</span>
            ),
        },
        {
            title: "Wallet",
            dataIndex: 'wallet',
            render: (wallet: string, record: any) => (
                <span>{ellipsisText(wallet)}</span>
            ),
        },
    ];

    useEffect(() => {
        fetchListData()

        async function fetchListData() {
            const response: any = await userApi.getWithdrawList()
            if (response?.status) {
                setData(response?.datas)
            }
        }
    }, []);

    return (
        <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4">

            <Tabs onChange={(e) => navigate(`/${e}`)} items={itemsTabs} defaultActiveKey={tab} />

            <div className="lazyContainer">
                <h1 className="z-2 self-end text-moon-24 text-bulma mb-5">
                    <span data-translation="profile.account">Lịch sử rút tiền</span>
                </h1>
                <div className="flex gap-5 flex-col md:flex-row overflow-auto">
                    <Table columns={columns} dataSource={data} className="w-full" />
                </div>
            </div>
            {/* <div className="inline-container">
                <div className="inline-container">
                    <a href="/">
                        <p className="text-moon-12 text-trunks">
                            <img
                                alt=""
                                className=""
                                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                                height={32}
                                loading="lazy"
                            />
                        </p>
                    </a>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="m-0 text-2xl text-trunks"
                    >
                        <path
                            d="M13 10L19 16L13 22"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="inline-container">
                    <a href="/refer-a-friend">
                        <p className="text-moon-12 text-trunks">
                            <span data-translation="profile.refer-a-friend">
                                Profile
                            </span>
                        </p>
                    </a>
                </div>
            </div> */}
        </div>

    );
}
export default WithdrawHistory;
