import { ApiUrl } from "src/constant/endpoints"
import { loginInfo } from "src/features/user/userAction"
import { mainAxios } from "src/services/axiosInstance"

export const userApi = {
  login(payload: loginInfo) {
    return mainAxios.request({
      url: ApiUrl.LOGIN,
      methodType: "POST",
      payload: payload,
    })
  },

  register(payload: loginInfo) {
    return mainAxios.request({
      url: ApiUrl.SIGN_UP,
      methodType: "POST",
      payload: payload,
    })
  },
  addWallet(payload: any) {
    return mainAxios.request({
      url: ApiUrl.ADD_WALLET,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
  addWBank(payload: any) {
    return mainAxios.request({
      url: ApiUrl.ADD_BANK,
      methodType: "POST",
      payload: payload,
      requiresToken: true
    })
  },
  getProfile() {
    return mainAxios.request({
      url: ApiUrl.PROFILE,
      methodType: "GET",
      requiresToken: true
    })
  },
  getRechargeList() {
    return mainAxios.request({
      url: ApiUrl.RECHARGE_LIST,
      methodType: "GET",
      requiresToken: true
    })
  },
  getWithdrawList() {
    return mainAxios.request({
      url: ApiUrl.WITHDRAW_LIST,
      methodType: "GET",
      requiresToken: true
    })
  },
  getPromotion() {
    return mainAxios.request({
      url: ApiUrl.PROMOTION,
      methodType: "POST",
      requiresToken: true
    })
  },
  getInfoBank() {
    return mainAxios.request({
      url: ApiUrl.PROMOTION,
      methodType: "POST",
      requiresToken: true
    })
  },
}
