import { Drawer, Dropdown, Menu } from "antd"
import React, { useMemo, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons"
import "./DropDownMenu.scss"
import { clsx } from "clsx"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { Link, useNavigate } from "react-router-dom"
import ActionComponent from "src/layout/NavBar/ActionComponent"
import cq9 from "@assets/icons/cq9.png"
import ap from "@assets/icons/ap.png"
import bg from "@assets/icons/bg.png"
import dg from "@assets/icons/dg.png"
import evo from "@assets/icons/evo.png"
import icb from "@assets/icons/icb.png"
import jdb from "@assets/icons/jdb.png"
import wm from "@assets/icons/wm.png"
import { MoneyConvert } from "src/util/format"
import useIsMobile from "src/customHook/useIsMobile"
import ModalLogin from "../ModalLogin"
import ModalRegister from "../ModalRegister"

import subMenu1 from "@assets/images/menu/1.png"
import subMenu2 from "@assets/images/menu/2.png"
import subMenu3 from "@assets/images/menu/3.png"
import subMenu4 from "@assets/images/menu/4.png"
import subMenu5 from "@assets/images/menu/5.png"
import subMenu6 from "@assets/images/menu/6.png"
import subMenu7 from "@assets/images/menu/7.png"
import CloseBlack from "@assets/icons/CloseBlack.svg"
import DropDownMenuMobile from "./DropDownMenuMobile"

import liveCasino from "@assets/images/menu/mobile/live_casino_tab.png"
import lotteryTab from "@assets/images/menu/mobile/lottery_tab.png"
import casino_tab from "@assets/images/menu/mobile/casino_tab.png"
import sports_tab from "@assets/images/menu/mobile/sports_tab.png"
import esports_tab from "@assets/images/menu/mobile/esports_tab.png"
import saba_pingoal_tab from "@assets/images/menu/mobile/saba_pingoal_tab.png"
import saba_club_tab from "@assets/images/menu/mobile/saba_club_tab.png"
import virtual_sports from "@assets/images/menu/mobile/virtual_sports.png"
import { useAppDispatch } from "src/app/hooks"
import { closeLogin, openLogin } from "src/features/modal"

const { SubMenu } = Menu

const listIcon: any = {
  0: "https://heathmont.imgix.net/luxury/icons/sidebar_live-baccarat.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
  1: "https://heathmont.imgix.net/luxury/icons/sidebar_live-blackjack.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
  2: "https://heathmont.imgix.net/luxury/icons/sidebar_live-roulette.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
  3: "https://heathmont.imgix.net/luxury/icons/sidebar_live-game-shows.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
  4: "https://heathmont.imgix.net/luxury/icons/sidebar_video-slots.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
  5: "https://heathmont.imgix.net/luxury/icons/sidebar_high_roller.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
  6: "https://heathmont.imgix.net/luxury/icons/sidebar_turbo_games.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
  7: "https://heathmont.imgix.net/luxury/icons/sidebar_virtual-sports.png?w=100&h=100&auto=compress%2Cformat&fit=max&q=50",
}

const listMenuImage: any = {
  SLOT: casino_tab,
  LIVE_CASINO: liveCasino,
  SPORT_BOOK: sports_tab,
  VIRTUAL_SPORT: virtual_sports,
  LOTTERY: lotteryTab,
  QIPAI: liveCasino,
  P2P: liveCasino,
  FISHING: liveCasino,
  COCK_FIGHTING: liveCasino,
  BONUS: liveCasino,
  ESPORT: esports_tab,
  POKER: saba_club_tab,
  OTHERS: saba_pingoal_tab,
}

const listImage: any = [
  subMenu1,
  subMenu2,
  subMenu3,
  subMenu4,
  subMenu5,
  subMenu6,
  subMenu7,
]

const listIcon2: any = {
  1: evo,
  2: bg,
  3: cq9,
  4: cq9,
  5: wm,
  6: icb,
  7: dg,
  8: ap,
  9: jdb,
}

const listMenuTitle = [
  "Live Casino",
  "Slot",
  "Sport Book",
  "Virtual Sport",
  "Lottery",
  "Qipai",
  "P2P",
  "Fishing",
  "Others",
  "Cock Fighting",
  "Bonus",
  "Jackpot",
  "ESport",
]

const listMenu: any = {
  SLOT: "Slot",
  LIVE_CASINO: "Live Casino",
  SPORT_BOOK: "Sport book",
  VIRTUAL_SPORT: "Virtual sport",
  LOTTERY: "Lottery",
  QIPAI: "Qipai",
  P2P: "P2p",
  FISHING: "Fishing",
  COCK_FIGHTING: "Cock fighting",
  BONUS: "Bonus",
  ESPORT: "Esport",
  POKER: "Poker",
  OTHERS: "Others",
}
const DropDownMenu = () => {
  const dispath = useAppDispatch()
  const navigate = useNavigate()
  const [menuShow, setMenuShow] = useState(false)
  const dataUser = useSelector((state: RootState) => state.user.dataUser)
  const isOpenLogin = useSelector((state: RootState) => state.modal.isOpenLogin)
  const { isMobile } = useIsMobile(768)
  const [openRegister, setOpenRegister] = useState(false)

  const token = localStorage.getItem("token")
  const gameBaseProduct = useSelector(
    (state: RootState) => state.user.gameProduct,
  )
  const gameProduct = gameBaseProduct.reduce(
    (
      gameGroup: any,
      { id, product_code, game_type, game_name, image_url,game_code }: any,
    ) => {
      const gameConvert = {
        id,
        text: game_name,
        image_url,
        link: `/categories/game/${id}?ProductCode=${product_code}&GameType=${game_type}${game_code ? `&GameID=${game_code}`:''}`,
      }
      if (gameGroup[game_type]) {
        gameGroup[game_type].push(gameConvert)
      } else {
        gameGroup[game_type] = [gameConvert]
      }
      return gameGroup
    },
    {},
  )
  // const gameProduct = useSelector((state: RootState) =>
  //   state.user.gameProduct.reduce(
  //     (gameGroup: any, { id, ProductCode, GameType }: any) => {
  //       const gameConvert = {
  //         id,
  //         text: ProductCode,
  //         link: `/categories/game/${id}?ProductCode=${ProductCode}&GameType=${GameType}`,
  //       }
  //       if (gameGroup[GameType]) {
  //         gameGroup[GameType].push(gameConvert)
  //       } else {
  //         gameGroup[GameType] = [gameConvert]
  //       }
  //       return gameGroup
  //     },
  //     {},
  //   ),
  // )
  const navBarList = useMemo(() => {
    const convert = Object.keys(listMenu)
      .map((title) => {
        return {
          id: Date.now(),
          text: listMenu[title],
          iconMobile: listMenuImage[title],
          // icon: listIcon[
          //   Math.floor(Math.random() * Object.keys(listIcon).length)
          // ],
          link: "/",
          children: gameProduct[title],
        }
      })
      ?.filter((item) => item?.children)

    return convert
    // return listMenuTitle.map((title) => {
    //   return {
    //     id: Date.now(),
    //     text: title,
    //     icon: listIcon[
    //       Math.floor(Math.random() * Object.keys(listIcon).length)
    //     ],
    //     link: "/",
    //     children: gameProduct[title],
    //   }
    // })
  }, [gameProduct])

  const handleLogout = () => {
    // localStorage.clear()
    localStorage.removeItem("token")
    navigate("/")
    window.location.reload()
  }

  const subMenuNotoken = useMemo(() => {
    const convert = navBarList.map((item, i) => {
      return (
        <Link key={i} className="sports-card" to="/">
          <div className="sports-card__title">
            <span className="text">{item?.text}</span>
          </div>
          <div className="sports-card__image">
            {/* <img src={listImage[i>6 ? i-7 : i]} alt="" /> */}
            <img src={listImage[i]} alt="" />
          </div>
        </Link>
      )
    })
    return convert
  }, [navBarList])

  const renderMenu = (menuList: any) => {
    return menuList.map((menuItem: any, index: any) => {
      if (!menuItem.children) {
        return (
          // <div className="flex items-center p-2">
          //   <div className="w-10">
          //     <img src={menuItem.icon} alt="" className="w-full" />
          //   </div>
          //   <Link to={menuItem.link} className="text-lg hover:text-piccolo">
          //     {menuItem.text}
          //   </Link>
          // </div>
          <div className="hidden"></div>
        )
      }
      const subMenuData = menuItem.children.map(
        (subMenuItem: any, i: number) => {
          return (
            <Link
              key={subMenuItem.id}
              className="sports-card"
              to={subMenuItem.link}
            >
              <div className="sports-card__title">
                <span className="text">{subMenuItem.text}</span>
              </div>
              <div className="sports-card__image">
                {/* <img src={listImage[i>6 ? i-7 : i]} alt="" /> */}
                <img src={subMenuItem.image_url || listImage[i]} alt="" />
              </div>
            </Link>
          )
        },
      )
      const menu = (
        <Menu className="sports-list">
          {token ? subMenuData : subMenuNotoken}
        </Menu>
      )
      // const subMenuData = menuItem.children.map((subMenuItem: any) => ({
      //   key: subMenuItem.id,
      //   label: (
      //     <Link
      //       to={subMenuItem.link}
      //       className="cursor-pointer text-lg hover:text-piccolo flex items-center px-2"
      //     >
      //       <div className="w-6 mr-2">
      //         <img src={listIcon2[subMenuItem.id]} alt="" className="w-full" />
      //       </div>
      //       {subMenuItem.text}
      //     </Link>
      //   ),
      // }))
      return (
        <Dropdown
          // menu={{ items: subMenuData }}
          overlay={menu}
          placement="bottom"
          // open
        >
          <div className="cursor-pointer text-lg hover:text-piccolo flex items-center">
            <div className="">
              <img src={menuItem.icon} alt="" className="w-full" />
            </div>
            <Link to={menuItem.link}>{menuItem.text}</Link>
          </div>
        </Dropdown>
      )
    })
  }

  const renderMenuMobile = (menuList: any) => {
    return menuList.map((menuItem: any) => {
      if (!menuItem.children) {
        return (
          // <div className="flex items-center p-2 pb-0 font-semibold">
          //   <div className="w-10">
          //     <img src={menuItem.icon} alt="" className="w-full" />
          //   </div>
          //   <Link to={menuItem.link} className="text-lg hover:text-piccolo">
          //     {menuItem.text}
          //   </Link>
          // </div>
          <div className="hidden"></div>
        )
      }
      return (
        <div>
          <div className="flex items-center pt-2 pb-0 pl-0">
            <div className="w-10">
              <img src={menuItem.icon} alt="" className="w-full" />
            </div>
            <Link
              to={menuItem.link}
              className="text-lg hover:text-piccolo font-semibold text-white"
            >
              {menuItem.text}
            </Link>
          </div>
          <div className="ml-8 flex flex-col gap-1">
            {menuItem.children.map((menuChild: any, index: any) => {
              return (
                <Link
                  to={menuChild.link}
                  onClick={() => setMenuShow(false)}
                  className="text-lg hover:text-piccolo flex items-center text-white"
                >
                  <div className="w-6 mr-2">
                    <img src={menuChild.image_url} alt="" className="w-full" />
                    {/* <img
                      src={listIcon2[menuChild.id]}
                      alt=""
                      className="w-full"
                    /> */}
                  </div>
                  {menuChild.text}
                </Link>
              )
            })}
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      <div className="items-center gap-5 flex-wrap hidden lg:flex">
        {renderMenu(navBarList)}
      </div>
      <div className="flex items-center lg:hidden">
        <div
          className={clsx(
            "items-center justify-center md:gap-4 gap-2 flex-wrap flex mr-2",
          )}
        >
          {!token && (
            <>
              <a>
                <button
                  className="md:px-4 px-3 md:h-10 h-8 py-2 gap-2 md:text-moon-14 text-moon-12 text-white rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 bg-transparent ring-inset ring-1 ring-trunks hover:ring-bulma w-full font-normal shadow-none hover:shadow-none"
                  type="button"
                  onClick={() => dispath(openLogin())}
                >
                  <span data-translation="profile.login">Log in</span>
                  <span
                    aria-hidden="true"
                    className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                  />
                </button>
              </a>
              <a>
                <button
                  className="md:px-4 px-3 md:h-10 h-8 py-2 gap-2 md:text-moon-14 text-moon-12 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo anim-pulse animate-[pulse2_1.5s_infinite] w-full font-normal"
                  type="button"
                  data-testid="tw-button"
                  onClick={() => setOpenRegister(!openRegister)}
                >
                  <span data-translation="profile.signup">Sign up</span>
                  <span
                    aria-hidden="true"
                    className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                  />
                </button>
              </a>
            </>
          )}
          {
           token && <div className="lg:hidden flex">
              <ActionComponent />
            </div>
          }
        </div>

        <FontAwesomeIcon
          icon={faBars}
          size="xl"
          className="cursor-pointer"
          onClick={() => setMenuShow(true)}
        />
        <Drawer
          title=""
          placement={"left"}
          // width={300}
          width={"100%"}
          onClose={() => setMenuShow(false)}
          open={menuShow}
          className="bg-drawer"
          closeIcon={<CloseBlack />}
        >
          {/* <div
              className={clsx("overlay", !menuShow && "close")}
              onClick={() => setMenuShow(false)}
            ></div> */}
          <div>
            {/* {renderMenuMobile(navBarList)} */}
            <DropDownMenuMobile
              onLogout={handleLogout}
              onClose={() => setMenuShow(false)}
              menuList={navBarList}
            />
            {/* <div
              className={clsx(
                "items-center justify-center gap-4 flex-wrap flex mt-4",
              )}
            >
              {token ? (
                <a>
                  <button
                    onClick={handleLogout}
                    className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 bg-krillin text-bulma font-normal"
                    type="button"
                    data-testid="tw-button"
                    id="logoutButton"
                  >
                    <span data-translation="profile.logout">Log out</span>
                    <span
                      aria-hidden="true"
                      className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                    />
                  </button>
                </a>
              ) : (
                <>
                  <a>
                    <button
                      className="px-4 h-10 py-2 gap-2 text-moon-14 text-white rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 bg-transparent ring-inset ring-1 ring-trunks hover:ring-bulma w-full font-normal shadow-none hover:shadow-none"
                      type="button"
                      onClick={() => setOpenLogin(!openLogin)}
                    >
                      <span data-translation="profile.login">Log in</span>
                      <span
                        aria-hidden="true"
                        className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                      />
                    </button>
                  </a>
                  <a>
                    <button
                      className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 text-goten bg-piccolo anim-pulse animate-[pulse2_1.5s_infinite] w-full font-normal"
                      type="button"
                      data-testid="tw-button"
                      onClick={() => setOpenRegister(!openRegister)}
                    >
                      <span data-translation="profile.signup">Sign up</span>
                      <span
                        aria-hidden="true"
                        className="z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out]"
                      />
                    </button>
                  </a>
                </>
              )}
            </div> */}
          </div>
        </Drawer>
      </div>
      <ModalLogin open={isOpenLogin} setOpen={() => dispath(closeLogin())} />
      <ModalRegister open={openRegister} setOpen={setOpenRegister} />
    </div>
  )
}

export default DropDownMenu
