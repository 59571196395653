import { Col, Drawer, Modal, Row, Tooltip, notification } from 'antd';
import { useState } from 'react';
import useIsMobile from "src/customHook/useIsMobile";
import ArrowLeft from "@assets/icons/arrowLeft.svg"
import ArrowRight from "@assets/icons/arrowRight.svg"
import CloseBlack from "@assets/icons/CloseBlack.svg"
import qrcode from "@assets/images/qrcode.jpg"

import { PRICE_USDT } from 'src/constant';
import { depositApi } from 'src/api/depositApi';
import { ellipsisText } from 'src/util/ellipsisText';
import { userApi } from 'src/api/userApi';
import { useAppDispatch } from 'src/app/hooks';
import { addWalletUser } from 'src/features/header/headerSlice';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface Props {
  open: boolean
  setOpen: any
}

function AddWallet({ open, setOpen }: Props) {
  const { isMobile } = useIsMobile(768)
  const [api, contextHolder] = notification.useNotification();

  const [wallet, setWallet] = useState('');
  const dispath = useAppDispatch()

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      placement: 'bottomLeft'
    });
  };

  const handleOk = async () => {
    try {
      let payload: any = {
        "wallet": wallet
      }

      const response: any = await userApi.addWallet(payload);
      if (response?.status) {
        openNotificationWithIcon('success', response?.message);
        dispath(addWalletUser(wallet))
        handleCancel()
      } else {
        openNotificationWithIcon('error', response?.message)
      }
    } catch (error: any) {
      console.log(error);

    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleWallet = (e: any) => {
    setWallet(e.target.value)
  }

  return (
    <>
      <Modal
        open={open}
        title="Địa chỉ ví"
        onOk={handleOk}
        okText={"Lưu"}
        cancelText='Hủy'
        onCancel={handleCancel}
      >
        <div className="modal-body info">
          <div className="stack w-full gap-3 rounded-t-moon-s-sm bg-goku p-3">
            <input
              type="text"
              className="block w-full max-w-full py-0 px-4 m-0 appearance-none text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-transparent shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible:shadow-input-focus focus-visible::outline-none h-14 leading-[3.5rem] rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus-visible:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus-visible:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-xl-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err"
              name="amount"
              role="textbox"
              placeholder=""
              dir="auto"
              value={wallet}
              onChange={handleWallet}
            />
          </div>
        </div>
      </Modal>
      {contextHolder}
    </>

  )
}

export default AddWallet
