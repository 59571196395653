import EagerImg from './EagerImg';
import LazyImg from './LazyImg';

const Img: React.FC<any> = (props) => {
  const {src} = props;
  if (props.preload) {
    return <EagerImg {...props} src={src} />;
  }
  return <LazyImg {...props} src={src} />;
};

export default Img;
