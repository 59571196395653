import { Popover } from '@heathmont/moon-core-tw';

const MobileInfoTooltip: React.FC<any> = ({ trigger, content }) => (
  <Popover>
    <Popover.Trigger>{trigger}</Popover.Trigger>
    <Popover.Panel className="w-auto p-4">{content}</Popover.Panel>
  </Popover>
);

export default MobileInfoTooltip;
