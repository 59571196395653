import { mergeClassnames } from '@heathmont/moon-core-tw';
import CarouselButton from '../CarouselButton';
import useIsMobile from 'src/customHook/useIsMobile';
import Carousel from '..';

type Props = {
  items: (JSX.Element | null)[];
  step?: number;
  scrollTo?: number;
  big?: boolean;
  type?: 'game-carousel' | 'regular';
  controlsOnTopRight?: boolean;
  reelClassName?: string;
};

const CustomCarousel: React.FC<Props> = ({
  items,
  scrollTo,
  reelClassName,
  step,
  big = false,
  type = 'regular',
  controlsOnTopRight = false,
}) => {
  const { isMobile } = useIsMobile(768)

  const getScrollToButton = (direction: 'left' | 'right') => {
    if (isMobile) {
      return null;
    }
    return (
      <CarouselButton
        className={mergeClassnames(
          !controlsOnTopRight && 'disabled:hidden',
          type === 'game-carousel' && 'top-[3.875rem] z-2',
          type === 'game-carousel' && big && 'top-[4.75rem] z-2',
          controlsOnTopRight && '-top-12 ltr:left-auto rtl:right-auto',
          controlsOnTopRight &&
            direction === 'right' &&
            'ltr:right-0 rtl:left-[9.5rem]',
          controlsOnTopRight &&
            direction === 'left' &&
            'ltr:right-10 rtl:left-[7rem]',
          'h-auto rounded-full p-2',
        )}
        iconClassName="rem:stroke-[4px] text-trunks rem:text-[10px]  "
        direction={direction}
      />
    );
  };

  return (
    <Carousel
      reelClassName={reelClassName}
      scrollTo={scrollTo}
      step={step}
      items={items}
      scrollToLeftButton={getScrollToButton('left')}
      scrollToRightButton={getScrollToButton('right')}
    />
  );
};

export default CustomCarousel;
