import { Link } from 'react-router-dom';

type Props = {
  game?: any;
};

const GameThumb: React.FC<Props> = (props) => {
  const { game } = props;
  
  return (
    <Link to={game?.href}>
      <div className="xl:min-h-40 group relative overflow-hidden rounded-moon-s-sm bg-gradient-to-r from-goku to-gohan xxsm:min-h-[78px] 2xmd:min-h-[88px] 2xl:min-h-[140px] 3xl:min-h-[177px] [&_img]:!relative [&_img]:!h-auto min-h-[125px]">
        <div className="pointer-events-none absolute z-1 flex h-full w-full items-center justify-center bg-black/60 opacity-0 transition group-hover:opacity-90">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-goten/50 mix-blend-normal backdrop-blur-sm">
            <img
              alt="play game"
              loading="lazy"
              width={20}
              height={20}
              decoding="async"
              data-nimg={1}
              style={{ color: "transparent" }}
              srcSet="https://heathmont.imgix.net/bitcasino/images/icons/play-triangle.svg?w=20&h=20&auto=compress%2Cformat&fit=max&q=50 1x, https://heathmont.imgix.net/bitcasino/images/icons/play-triangle.svg?w=20&h=20&auto=compress%2Cformat&fit=max&q=50 2x"
              src="https://heathmont.imgix.net/bitcasino/images/icons/play-triangle.svg?w=20&h=20&auto=compress%2Cformat&fit=max&q=50"
            />
          </div>
        </div>
        <img
          alt="Bombay Club Speed Baccarat"
          loading="eager"
          decoding="async"
          data-nimg="fill"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            objectFit: "cover",
            color: "transparent"
          }}
          sizes="(max-width: 768px) 152px,(max-width: 820px) 183px,(max-width: 1200px) 202px, 202px"
          srcSet={`${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=16&q=50 16w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=32&q=50 32w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=48&q=50 48w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=64&q=50 64w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=96&q=50 96w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=128&q=50 128w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=256&q=50 256w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=384&q=50 384w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=640&q=50 640w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=750&q=50 750w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=828&q=50 828w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=1080&q=50 1080w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=1200&q=50 1200w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=1920&q=50 1920w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=2048&q=50 2048w, ${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=3840&q=50 3840w`}
          src={`${game?.thumbUrl}?auto=compress%2Cformat&fit=max&w=3840&q=50"`}
        />
      </div >
    </Link>

  );
};

export default GameThumb;
