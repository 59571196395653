// import LiveRtp from '~/client/features/LiveRtp';

import GameInfo from './GameInfo';
import GameCardTemplate from './template/GameCardTemplate';
import useGameCardSize from './useGameCardSize';
import GameThumb from './GameThumb';

type Props = {
  game: any;
  big?: boolean;
  light?: boolean;
  isLiveRtp?: boolean;
  rtpField?: any;
  tournamentGame?: boolean;
  href?: string;
  preloadImg?: boolean;
  showGrid?: boolean;
  isMinMaxBetVisible?: boolean;
  onClick?: any;
};

const GameCard: React.FC<Props> = ({
  game,
  big,
  tournamentGame,
  href,
  showGrid,
  onClick
}) => {
  const { width, height } = useGameCardSize(big, tournamentGame);
  const handleClick = () => {
    onClick()
  }

  return (
    <GameCardTemplate width={width} showGrid={showGrid} onClick={handleClick}>
      <GameThumb
        game={game}
      // width={width}
      // showGrid={showGrid}
      // height={height}
      // isHoverEnabled
      // href={href}
      // big={big}
      // light={light}
      // preloadImg={preloadImg}
      // isMinMaxBetVisible={isMinMaxBetVisible}
      />
      <div className="grid gap-1">
        <GameInfo game={game} href={href} />
        {/* {rtpField && (
          <LiveRtp module="badges" game={game} rtpField={rtpField} />
        )} */}
      </div>
    </GameCardTemplate>
  );
};

export default GameCard;
