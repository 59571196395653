import GameCard from "src/components/GameCard";
import datagame from "@assets/mockdata/all_games.json"
import SelectCheckbox from "src/components/SelectForm/SelectCheckbox";
import SelectButton from "src/components/SelectForm/SelectButton";

function LiveBaccarat() {

    const items = datagame?.map(({ node }: any, index: number) => {
        if (!node) {
            return null;
        }
        return (
            <GameCard
                key={node.id}
                game={node}
                showGrid
                big
            />
        );
    });

    return (
        <main className="mx-auto mb-12 mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-5 pb-0 pt-4">
            {/*$*/}
            <div className="flex flex-col gap-6">
                <div className="relative grid grid-cols-minmax gap-4 md:mb-2.5 lg:grid-flow-col lg:justify-between">
                    <div className="grid grid-cols-minmax gap-1">
                        <h1 className="z-2 self-end text-moon-24 text-bulma">Baccarat</h1>
                    </div>
                    <div className='grid grid-cols-[1fr_1fr] gap-1 md:grid-flow-col md:grid-cols-[repeat(2,_11.25rem)] [grid-template-areas:"categories_categories"_"providers_providers"_"sort_sort"] md:[grid-template-areas:"categories_providers_sort"]'>
                        <div className="w-full relative [grid-area:providers]">
                            <SelectCheckbox
                                label={'Providers'}
                                placehoder={'All Game Providers'}
                                content={[
                                    {
                                        name: 'BOMBAY LIVE'
                                    },
                                    {
                                        name: 'EVOLUTION'
                                    },
                                    {
                                        name: 'EZUGI'
                                    },
                                ]}
                            />
                        </div>
                        <div className="grid grid-cols-[minmax(1px,_1fr)] items-end gap-1 [grid-area:sort]">
                            <SelectButton
                                label={'Sort by'}
                                content={[
                                    {
                                        id: 0,
                                        name: 'Popularity'
                                    },
                                    {
                                        id: 1,
                                        name: 'A-Z'
                                    },
                                    {
                                        id: 2,
                                        name: 'Volatility'
                                    },
                                    {
                                        id: 3,
                                        name: 'Hit Ratio'
                                    },
                                    {
                                        id: 4,
                                        name: 'RTP'
                                    },
                                ]} />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-[repeat(2,1fr)] gap-4 xsm:grid-cols-[repeat(3,1fr)] md:grid-cols-[repeat(4,1fr)] xl:grid-cols-[repeat(5,1fr)] 2xl:grid-cols-[repeat(6,1fr)]">
                    {items}
                </div>
                <div className="flex justify-center items-center w-full select-none">
                    <nav
                        className="flex gap-1 items-center justify-center w-full h-10 text-moon-14 flex-grow"
                        aria-label="pagination"
                    >
                        <a
                            aria-label="page 1"
                            tabIndex={0}
                            className="flex items-center justify-center cursor-pointer w-8 h-8 rounded-moon-s-sm font-medium focus:outline-none transition-colors text-goten bg-piccolo"



                            href="?page=1"
                        >
                            1
                        </a>
                        <a
                            aria-label="page 2"
                            tabIndex={0}
                            className="flex items-center justify-center cursor-pointer w-8 h-8 rounded-moon-s-sm font-medium focus:outline-none transition-colors text-bulma hover:bg-jiren focus:bg-jiren"



                            href="?page=2"
                        >
                            2
                        </a>
                    </nav>
                    <a
                        className="moon-disabled:cursor-not-allowed moon-disabled:opacity-30"



                        href="?page=2"
                    >
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-moon-32 rtl:rotate-180"
                        >
                            <path
                                d="M13 10L19 16L13 22"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </a>
                </div>
                {/* <div className="flex flex-wrap items-center justify-start">
                    <div className="flex flex-wrap items-center justify-start">
                        <a href="/">
                            <p className="text-moon-12 text-trunks">
                                <img
                                    alt="logo"
                                    loading="lazy"
                                    width={32}
                                    height={32}
                                    decoding="async"
                                    data-nimg={1}
                                    style={{ color: "transparent" }}
                                    srcSet="https://heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?w=32&h=32&auto=compress%2Cformat&fit=max&q=50 1x, https://heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?w=32&h=32&auto=compress%2Cformat&fit=max&q=50 2x"
                                    src="https://heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?w=32&h=32&auto=compress%2Cformat&fit=max&q=50"
                                />
                            </p>
                        </a>
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="m-0 text-2xl text-trunks ltr:rotate-180"
                        >
                            <path
                                d="M19 22L13 16L19 10"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-wrap items-center justify-start">
                        <a



                            href="/games"
                        >
                            <p className="text-moon-12 text-trunks">
                                <span data-translation="general.games">Games</span>
                            </p>
                        </a>
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="m-0 text-2xl text-trunks ltr:rotate-180"
                        >
                            <path
                                d="M19 22L13 16L19 10"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-wrap items-center justify-start">
                        <p className="text-moon-12 text-bulma">
                            <span data-translation="casino.menu_item.baccarat">Baccarat trực tiếp</span>
                        </p>
                    </div>
                </div> */}
                {/* <div
                    id="collapsed-container"
                    className="group/collapsed rounded-moon-s-xs bg-gohan px-4 py-2 [&_a]:text-bulma [&_a]:active:text-hit [&_div_h3]:mt-[10px] [&_div_h3]:mb-4 [&_div_h3]:text-moon-20 [&_div_h3]:font-medium [&_div_h3]:leading-snug [&_.two-columns]:rounded-moon-s-xs [&_.two-columns]:border [&_.two-columns]:border-beerus [&_.two-columns]:pb-8 sm:[&_.two-columns]:border-none sm:[&_.two-columns]:p-0 [&_.two-columns_.group-inner]:overflow-hidden [&_.two-columns_.group-inner]:px-4 [&_.two-columns_.group-inner]:text-trunks sm:p-8 sm:pb-5 sm:[&_div_h3]:m-0 sm:[&_div_h3]:text-moon-24 sm:[&_div_h3]:leading-tight sm:[&_.two-columns_.group-inner]:grid sm:[&_.two-columns_.group-inner]:grid-flow-row sm:[&_.two-columns_.group-inner]:text-moon-14 [&_.two-columns_.group-inner]:xl:grid-cols-[1fr_1fr] [&_.two-columns_.group-inner]:xl:gap-x-14 sm:[&_.two-columns_.group-inner_p]:my-6 sm:[&_a]:font-medium sm:[&_a]:text-trunks"
                >
                    <div
                        data-cms-section="category-seo"
                        data-cms-handle="baccarat"
                        className="font-averta [&_iframe]:max-w-full [&_table]:text-base [&_img]:inline [&_p]:my-4 [&_p]:text-base [&_p]:!text-bulma [&_span]:!text-bulma [&_ol]:list-decimal [&_ol]:text-base [&_ol]:[margin-block:1rem] [&_ol]:[margin-inline:0] [&_ol]:[padding-inline-start:2.5rem] [&_ul]:list-disc [&_ul]:text-base [&_ul]:[margin-block:1rem] [&_ul]:[margin-inline:0] [&_ul]:[padding-inline-start:2.5rem] [&_.pt-button.cms_visibility_logged_out]:hidden relative max-h-52 overflow-hidden text-bulma md:max-h-48 after:absolute after:bottom-0 after:left-0 after:h-12 after:w-full after:bg-gradient-to-t after:from-gohan after:to-transparent md:after:h-[4.5rem] group-[.open]/collapsed:max-h-none group-[.open]/collapsed:after:h-0"
                    >
                        <h2
                            dir="ltr"
                            style={{
                                lineHeight: "1.38",
                                textAlign: "center",
                                marginTop: "17pt",
                                marginBottom: "6pt"
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "18pt",
                                    fontFamily: "Fraunces",
                                    fontWeight: 0,
                                    fontStyle: "bold",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap"
                                }}
                            >
                                Chơi Baccarat trực tiếp trực tuyến tại Betcasino
                            </span>
                        </h2>
                        <div className="two-columns">
                            <div className="group-inner">
                                <div className="narrow">
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tại Betcasino, đặt cược vào Người chơi hoặc Nhà cái trong
                                            baccarat chưa bao giờ dễ dàng hơn thế. Chọn trong danh
                                            mục&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            trò chơi baccarat tại sòng bạc Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;và đặt cược bằng tiền điện tử để tận hưởng tất cả các
                                            tính năng tốt nhất mà&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;cung cấp. Đọc tiếp để tìm hiểu thêm về nó và cách bắt
                                            đầu chơi.
                                        </span>
                                    </p>
                                    <h2
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "18pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Baccarat với Bitcoin là gì?
                                        </span>
                                    </h2>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Baccarat là một trò chơi bài trong sòng bạc, tại đó bạn đặt
                                            cược xem Nhà cái hay Người chơi bên nào sẽ có ván bài gần chín
                                            nhất. Không giống như trò chơi baccarat thông thường, bạn chỉ
                                            có thể đặt cược bằng tiền điện tử trong kiểu trò chơi này.
                                        </span>
                                    </p>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tất cả các lá bài được đánh số trong baccarat đều có giá trị
                                            bằng với giá trị số của chúng trong khi các lá bài hình, cụ
                                            thể là Jack, Queen và King, có giá trị bằng 0. Át có thể có
                                            giá trị 1 hoặc 11, tùy thuộc vào giá trị nào phù hợp nhất với
                                            ván bài.
                                        </span>
                                    </p>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Nếu tổng của một bên vượt quá 9, thì bỏ chữ số hàng chục đi.
                                            Nếu một bên dưới 7, thì sẽ được chia thêm một lá bài nữa.
                                        </span>
                                    </p>
                                    <h2
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "18pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Lợi ích của việc chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat trực tuyến với Bitcoin
                                        </span>
                                    </h2>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Khi bạn đặt cược vào các trò chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat với tiền điện tử
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            , thì bạn có được tất cả các lợi ích của tiền điện tử, chẳng
                                            hạn như những lợi ích được liệt kê dưới đây:&nbsp;
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Nạp và rút tiền dễ dàng
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Bạn có thể chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;bất kỳ đâu, nhờ việc nạp và rút tiền điện tử nhanh chóng
                                            tại Betcasino.&nbsp;
                                        </span>
                                        <a
                                            data-gtm-event-category="cms_block_link"
                                            data-gtm-event-action="cms_block"
                                            data-gtm-event-info="https://bitcasino.io/help-center/help-payment-options/how-do-i-deposit-in-cryptocurrency"



                                            href="/help-center/help-payment-options/how-do-i-deposit-in-cryptocurrency"
                                        >
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "underline",
                                                    WebkitTextDecorationSkip: "none",
                                                    textDecorationSkipInk: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Nạp tiền điện tử
                                            </span>
                                        </a>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;dễ dàng như quét mã QR bằng ví tiền điện tử của bạn. Bạn
                                            sẽ có tiền chỉ sau vài phút và bạn có thể quay lại chơi.
                                        </span>
                                    </p>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Quá trình rút tiền cũng vậy. Khi bạn đặt cược bằng tiền điện
                                            tử, bạn có thể rút tiền thưởng bằng tiền điện tử của mình
                                            thông qua một quy trình nhanh chóng và hiệu quả. Chỉ cần nhập
                                            số tiền bạn muốn rút, nhập địa chỉ ví tiền điện tử của bạn và
                                            bạn đã sẵn sàng.
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Ẩn danh và bảo mật khi chơi&nbsp;
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Khi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            chơi baccarat với tiền điện tử
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            , bạn sẽ không phải cung cấp thông tin cá nhân và không liên
                                            quan đến ngân hàng. Nhờ mật mã và mã hóa cao cấp bảo vệ dữ
                                            liệu của bạn, bạn có thể đặt cược ẩn danh mà không gặp rủi ro
                                            về bảo mật.&nbsp;
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tận hưởng tiền thưởng&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat với tiền điện tử
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Nhận tiền thưởng đặc biệt chỉ có tại các sòng bạc tiền điện tử
                                            khi bạn&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            chơi baccarat trực tuyến
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            . Một trong số này bao gồm
                                        </span>
                                        <a
                                            data-gtm-event-category="cms_block_link"
                                            data-gtm-event-action="cms_block"
                                            data-gtm-event-info="https://bitcasino.io/promotions/2022-09-01-welcome-cashback"



                                            href="/promotions/2022-09-01-welcome-cashback"
                                        >
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "underline",
                                                    WebkitTextDecorationSkip: "none",
                                                    textDecorationSkipInk: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                &nbsp;thưởng hoàn tiền với tiền điện tử
                                            </span>
                                        </a>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;và hơn thế nữa. Với&nbsp;
                                        </span>
                                        <a
                                            data-gtm-event-category="cms_block_link"
                                            data-gtm-event-action="cms_block"
                                            data-gtm-event-info="https://bitcasino.io/promotions"



                                            href="/promotions"
                                        >
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "underline",
                                                    WebkitTextDecorationSkip: "none",
                                                    textDecorationSkipInk: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                những khuyến mãi
                                            </span>
                                        </a>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;độc quyền như vậy, bạn có thể có lợi thế hơn những người
                                            chơi bằng tiền định danh khác và sử dụng nó để nhận thêm tiền
                                            thắng.&nbsp;
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Chơi với những người chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat trực tuyến với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;khác theo thời gian thực
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tính năng trò chuyện trực tiếp trong các trò chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat trực tuyến với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;cho phép bạn trò chuyện và chơi với mọi người trên toàn
                                            thế giới theo thời gian thực. Nó sẽ giống như chơi trong một
                                            sòng bạc truyền thống nhưng với phần thưởng là sử dụng tiền
                                            điện tử.
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Nhiều trò chơi để bạn lựa chọn&nbsp;
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Khi bạn tạo tài khoản tại Betcasino, bạn có quyền truy cập các
                                            trò chơi baccarat khác nhau, cho dù là trò chơi người chia bài
                                            trực tiếp hay định dạng video, tất cả đều có trên một nền
                                            tảng. Tất cả những gì bạn phải làm là đặt cược bằng tiền điện
                                            tử và bạn có thể&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            chơi trò chơi baccarat với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;và hơn thế nữa.&nbsp;
                                        </span>
                                    </p>
                                    <h2
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "18pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Cách chơi baccarat bằng tiền điện tử
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;
                                        </span>
                                    </h2>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Các trò chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Baccarat với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;dễ chơi và thú vị, đặc biệt là khi bạn đặt cược bằng
                                            tiền điện tử. Để hiểu rõ hơn, hãy xem hướng dẫn dưới đây về
                                            cách chơi baccarat trực tuyến với tiền điện tử:&nbsp;
                                        </span>
                                    </p>
                                    <ol
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "decimal",
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre"
                                            }}
                                        >
                                            <h3
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "15pt",
                                                    marginBottom: "10pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "13pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Nạp tiền điện tử
                                                </span>
                                            </h3>
                                        </li>
                                    </ol>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Trước khi chơi baccarat với tiền điện tử, bạn phải nạp tiền
                                            điện tử vào tài khoản của mình trước. Để thực hiện việc này,
                                            hãy làm theo các bước dưới đây.
                                        </span>
                                    </p>
                                    <ul
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "disc",
                                                fontSize: "11pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre",
                                                marginLeft: "36pt"
                                            }}
                                        >
                                            <p
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "0pt",
                                                    marginBottom: "0pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Đăng nhập vào tài khoản Betcasino của bạn và nhấp vào nút
                                                    “Nạp tiền” ở góc trên bên phải của màn hình.
                                                </span>
                                            </p>
                                        </li>
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "disc",
                                                fontSize: "11pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre",
                                                marginLeft: "36pt"
                                            }}
                                        >
                                            <p
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "0pt",
                                                    marginBottom: "0pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Xác định loại tiền điện tử bạn muốn nạp vào tài khoản của
                                                    mình. Các loại tiền điện tử được Betcasino hỗ trợ là:
                                                </span>
                                            </p>
                                            <ul
                                                style={{
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    paddingInlineStart: 48
                                                }}
                                            >
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Bitcoin (BTC)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            <a
                                                                data-gtm-event-category="cms_block_link"
                                                                data-gtm-event-action="cms_block"
                                                                data-gtm-event-info="https://bitcasino.io/blog/cryptocurrency/ethereum-casino"



                                                                href="/blog/cryptocurrency/ethereum-casino"
                                                            >
                                                                Ethereum
                                                            </a>{" "}
                                                            (ETH)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Cardano (ADA)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Litecoin (LTC)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Tron (TRX)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Tether (USDT)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Ripple (XRP)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Dogecoin (DOGE)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Binance Coin (BNB)
                                                        </span>
                                                    </p>
                                                </li>
                                                <li
                                                    aria-level={2}
                                                    dir="ltr"
                                                    style={{
                                                        listStyleType: "circle",
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre",
                                                        marginLeft: "36pt"
                                                    }}
                                                >
                                                    <p
                                                        dir="ltr"
                                                        style={{
                                                            lineHeight: "1.3800000000000001",
                                                            marginTop: "0pt",
                                                            marginBottom: "0pt"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "10pt",
                                                                fontFamily: "Fraunces",
                                                                fontWeight: 400,
                                                                fontStyle: "normal",
                                                                fontVariant: "normal",
                                                                textDecoration: "none",
                                                                verticalAlign: "baseline",
                                                                whiteSpace: "pre-wrap"
                                                            }}
                                                        >
                                                            Binance USD (BUSD).
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "disc",
                                                fontSize: "11pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre",
                                                marginLeft: "36pt"
                                            }}
                                        >
                                            <p
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "0pt",
                                                    marginBottom: "0pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Mã QR và địa chỉ ví sau đó sẽ xuất hiện trên màn hình. Bạn
                                                    có thể quét mã này bằng ví tiền điện tử của mình hoặc dễ
                                                    dàng sao chép địa chỉ ví để nạp tiền điện tử. Bạn cũng có
                                                    thể chọn nạp tiền bằng các phương thức thanh toán khác,
                                                    chẳng hạn như Metamask hoặc Paxful.
                                                </span>
                                            </p>
                                        </li>
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "disc",
                                                fontSize: "11pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre",
                                                marginLeft: "36pt"
                                            }}
                                        >
                                            <p
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "0pt",
                                                    marginBottom: "0pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Nếu bạn chọn một phương thức thanh toán khác, hãy làm theo
                                                    hướng dẫn để hoàn tất giao dịch của mình. Nhập thông tin
                                                    chính xác và xác nhận thông tin chuyển khoản.&nbsp;
                                                </span>
                                            </p>
                                        </li>
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "disc",
                                                fontSize: "11pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre",
                                                marginLeft: "36pt"
                                            }}
                                        >
                                            <p
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "0pt",
                                                    marginBottom: "0pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Khi bạn đã xác nhận chuyển khoản, nó sẽ cập nhật số dư của
                                                    bạn trong vòng vài giây.&nbsp;
                                                </span>
                                            </p>
                                        </li>
                                    </ul>
                                    <ol
                                        start={2}
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "decimal",
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre"
                                            }}
                                        >
                                            <h3
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "15pt",
                                                    marginBottom: "10pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "13pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Chọn một trò chơi baccarat&nbsp;
                                                </span>
                                            </h3>
                                        </li>
                                    </ol>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Bây giờ, bạn có thể chọn trong số rất nhiều trò chơi baccarat
                                            ly kỳ tại Betcasino. Nhấp vào “Baccarat” ở thanh bên trái để
                                            tìm tất cả các trò chơi baccarat trực tuyến có sẵn, bao gồm
                                            các trò chơi baccarat từ nhà cung cấp, trò chơi baccarat người
                                            chia bài trực tiếp và trò chơi baccarat video hỗ trợ RNG. Bạn
                                            có thể lọc các kết quả này theo mức độ phổ biến, nhà cung cấp,
                                            mức độ biến động và tỷ lệ truy cập chúng.
                                        </span>
                                    </p>
                                    <ol
                                        start={3}
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "decimal",
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre"
                                            }}
                                        >
                                            <h3
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "15pt",
                                                    marginBottom: "10pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "13pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Đặt cược của bạn và bắt đầu chơi
                                                </span>
                                            </h3>
                                        </li>
                                    </ol>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Bây giờ bạn có thể chơi. Chọn chip của bạn, đặt cược vào bên
                                            mà bạn nghĩ sẽ thắng và giành được phần thưởng tiền điện tử
                                            nếu bạn đoán đúng.
                                        </span>
                                    </p>
                                    <h2
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "18pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Cách chơi các trò chơi baccarat với Bitcoin
                                        </span>
                                    </h2>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Chơi baccarat chỉ cần vài bước đơn giản. Hãy xem bên dưới để
                                            biết cách&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            chơi baccarat với tiền điện tử&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;và giành chiến thắng dễ dàng:
                                        </span>
                                    </p>
                                    <ol
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "decimal",
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre"
                                            }}
                                        >
                                            <h3
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "15pt",
                                                    marginBottom: "10pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "13pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Chọn một trò chơi và hiểu cơ chế của nó&nbsp;
                                                </span>
                                            </h3>
                                        </li>
                                    </ol>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Mặc dù tất cả các trò chơi baccarat tại Betcasino đều tuân
                                            theo cùng một cách chơi, nhưng một số nhà cung cấp thêm các
                                            tính năng hoặc khúc cua để làm cho trò chơi của họ khác biệt.
                                            Vì vậy, hãy đọc kỹ cơ chế của chúng trước, và sau đó bạn có
                                            thể bắt đầu chơi.&nbsp;
                                        </span>
                                    </p>
                                    <ol
                                        start={2}
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "decimal",
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre"
                                            }}
                                        >
                                            <h3
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "15pt",
                                                    marginBottom: "10pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "13pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Đặt cược của bạn&nbsp;
                                                </span>
                                            </h3>
                                        </li>
                                    </ol>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Trong baccarat, bạn có hai lựa chọn đặt cược chính: Nhà cái và
                                            Người chơi. Đặt cược cho bất kỳ bên nào bạn nghĩ sẽ có giá trị
                                            bài gần 9 hơn. Bạn cũng có thể đặt cược hòa nếu bạn nghĩ rằng
                                            cả hai bên sẽ có cùng giá trị.&nbsp;
                                        </span>
                                    </p>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Bạn cũng có thể đặt cược phụ như cược Đôi cùng chất, Thắng ba
                                            lá hoặc Dragon Bonus thanh toán riêng, những cược này không
                                            liên quan đến cược chính. Tuy nhiên, tính sẵn có của chúng phụ
                                            thuộc vào trò chơi baccarat trực tuyến.
                                        </span>
                                    </p>
                                </div>
                                <div className="narrow">
                                    <ol
                                        start={3}
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "decimal",
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre"
                                            }}
                                        >
                                            <h3
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "15pt",
                                                    marginBottom: "10pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "13pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Watch as the dealer facilitates the game
                                                </span>
                                            </h3>
                                        </li>
                                    </ol>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Once the betting window closes, the dealer will reveal four cards, two for the player and two for the banker's side. If either pair gets a hand less than 7, they get another card. Whichever pair is closest to nine will be the winner. &nbsp;
                                        </span>
                                    </p>
                                    <ol
                                        start={4}
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            paddingInlineStart: 48
                                        }}
                                    >
                                        <li
                                            aria-level={1}
                                            dir="ltr"
                                            style={{
                                                listStyleType: "decimal",
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre"
                                            }}
                                        >
                                            <h3
                                                dir="ltr"
                                                style={{
                                                    lineHeight: "1.3800000000000001",
                                                    marginTop: "15pt",
                                                    marginBottom: "10pt"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "13pt",
                                                        fontFamily: "Fraunces",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Chờ kết quả trò chơi
                                                </span>
                                            </h3>
                                        </li>
                                    </ol>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginLeft: "36pt",
                                            marginTop: "0pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Khoản thanh toán tương ứng sẽ là của bạn nếu bạn đặt cược
                                            đúng. Nếu không, bạn sẽ phải đặt cược và thử lại trong vòng
                                            tiếp theo.&nbsp;
                                        </span>
                                    </p>
                                    <h2
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "18pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Các trò chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat trực tuyến với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;nên thử tại Betcasino
                                        </span>
                                    </h2>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Với các tùy chọn trò chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            sòng bạc baccarat với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            , việc lựa chọn trò chơi tốt nhất có thể là một thách thức. Để
                                            giúp bạn chọn, chúng tôi đã liệt kê một số tựa game phổ biến
                                            nhất của chúng tôi.
                                        </span>
                                    </p>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Hãy xem các trò chơi bạn nên thử khi chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat dành cho Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;tại Betcasino:&nbsp;
                                        </span>
                                    </p>
                                    <ul>
                                        <li>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/play/baccarat/baccarat-pro-onetouch"



                                                href="/play/baccarat/baccarat-pro-onetouch"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Baccarat Pro
                                                </span>
                                            </a>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                &nbsp;của&nbsp;
                                            </span>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/providers/onetouch"



                                                href="/providers/onetouch"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    OneTouch
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/play/baccarat/baccarat-3d"



                                                href="/play/baccarat/baccarat-3d"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Baccarat 3D
                                                </span>
                                            </a>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                &nbsp;của&nbsp;
                                            </span>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/providers/onetouch"



                                                href="/providers/onetouch"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    OneTouch
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/play/bombay-club-baccarat/baccarat-baruto"



                                                href="/play/bombay-club-baccarat/baccarat-baruto"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Baruto Baccarat
                                                </span>
                                            </a>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                &nbsp;của&nbsp;
                                            </span>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/providers/bombaylive"



                                                href="/providers/bombaylive"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Bombay Live
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/play/baccarat/supreme-baccarat-high-roller"



                                                href="/play/baccarat/supreme-baccarat-high-roller"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Baccarat Supreme High Roller
                                                </span>
                                            </a>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                &nbsp;của&nbsp;
                                            </span>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/providers/onetouch"



                                                href="/providers/onetouch"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    OneTouch
                                                </span>
                                            </a>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                &nbsp; &nbsp;
                                            </span>
                                        </li>
                                        <li>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/play/bombay-club-baccarat/baccarat-high-roller-1"



                                                href="/play/bombay-club-baccarat/baccarat-high-roller-1"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Baccarat High Roller 1
                                                </span>
                                            </a>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                &nbsp;của&nbsp;
                                            </span>
                                            <a
                                                data-gtm-event-category="cms_block_link"
                                                data-gtm-event-action="cms_block"
                                                data-gtm-event-info="https://bitcasino.io/providers/bombaylive"



                                                href="/providers/bombaylive"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "10pt",
                                                        fontFamily: "Fraunces",
                                                        color: "#1155cc",
                                                        fontWeight: 400,
                                                        fontStyle: "normal",
                                                        fontVariant: "normal",
                                                        textDecoration: "underline",
                                                        WebkitTextDecorationSkip: "none",
                                                        textDecorationSkipInk: "none",
                                                        verticalAlign: "baseline",
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    Bombay Live
                                                </span>
                                            </a>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                .
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                <br />
                                            </span>
                                        </li>
                                    </ul>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Cách chiến thắng tro chơi baccarat bằng tiền điện tử
                                        </span>
                                    </p>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Mặc dù baccarat không dựa vào điều gì ngoài may mắn, nhưng bạn
                                            có thể tăng cơ hội chiến thắng bằng cách thử một số chiến lược
                                            hữu ích. Hãy xem danh sách dưới đây để tìm hiểu thêm:
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Sử dụng các chiến lược đặt cược cần thiết
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Với chiến lược đặt cược, bạn thiết lập mức đặt cược và đặt
                                            cược một cách có chiến lược để giảm thua lỗ và tận dụng tối đa
                                            số tiền có trong tài khoản. Trong tất cả các chiến lược cá
                                            cược hiện có, thì Martingale là chiến lược phổ biến nhất&nbsp;
                                        </span>
                                    </p>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Trong chiến lược này, bạn gấp đôi tiền cược khi thua và giảm
                                            một nửa tiền cược khi thắng để thu được kết quả tốt nhất. Sử
                                            dụng các chiến lược có lợi cho bạn và bạn có thể có cơ hội
                                            chiến thắng cao hơn trong trò chơi baccarat trực tuyến.
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tránh đặt cược vào tùy chọn Hòa&nbsp;
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Trong số các tùy chọn cá cược trong baccarat, thì cược Hòa có
                                            mức chi trả cao nhất. Mặc dù điều này có vẻ hấp dẫn, nhưng cơ
                                            hội của bạn để giành được kết quả này là rất thấp. Do đó, sẽ
                                            là thông minh hơn nếu chọn các tùy chọn cá cược khác để có cơ
                                            hội chiến thắng cao hơn.&nbsp;
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Đặt cược vào cửa Nhà cái cho đến khi bạn thua&nbsp;
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tùy chọn đặt cược Nhà cái có cơ hội chiến thắng cao nhất vì
                                            nhà cái luôn có lợi thế hơn người chơi. Đó là lý do tại sao
                                            những người chơi baccarat cuồng nhiệt có một quy tắc ngón tay
                                            cái là họ đặt cược vào cửa Nhà cái cho đến khi họ thua. Tận
                                            dụng lợi thế của nhà cái trong trò chơi để bạn có thể tiếp tục
                                            chiến thắng.
                                        </span>
                                    </p>
                                    <h2
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "18pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Chơi các trò chơi baccarat trực tuyến
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;ngay hôm nay tại Betcasino&nbsp;
                                        </span>
                                    </h2>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Nếu bạn đang tìm kiếm một trò chơi sòng bạc ly kỳ và bổ ích,
                                            thì baccarat chính là trò chơi dành cho bạn. Bắt đầu đặt cược
                                            của bạn bằng tiền điện tử ngay hôm nay và có cơ hội mang về
                                            những chiến thắng lớn.{" "}
                                        </span>
                                    </p>
                                    <h2
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "18pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Những câu hỏi thường gặp về&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            các trò chơi baccarat với Bitcoin
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "15pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;
                                        </span>
                                    </h2>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Betcasino có cung cấp các trò chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat sòng bạc trực tuyến
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;Công bằng không?&nbsp;
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Có, Betcasino cung cấp các trò chơi baccarat Công bằng, cụ thể
                                            là các trò chơi baccarat video được trang bị RNG của&nbsp;
                                        </span>
                                        <a
                                            data-gtm-event-category="cms_block_link"
                                            data-gtm-event-action="cms_block"
                                            data-gtm-event-info="https://bitcasino.io/providers/onetouch"



                                            href="/providers/onetouch"
                                        >
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "underline",
                                                    WebkitTextDecorationSkip: "none",
                                                    textDecorationSkipInk: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                OneTouch
                                            </span>
                                        </a>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            . Những trò chơi này cho phép bạn xác minh tính công bằng của
                                            mọi kết quả trò chơi để bạn có thể chắc chắn rằng tất cả chúng
                                            đều ngẫu nhiên và không có sự thiên vị.&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Có bất kỳ phần thưởng nào cho các trò chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat với tiền điện tử
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;không?&nbsp;
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Có, Betcasino cung cấp các phần thưởng áp dụng cho baccarat
                                            như phần thưởng chương trình khách hàng thân thiết, phần
                                            thưởng hoàn tiền và phần thưởng giải đấu. Miễn là bạn là chủ
                                            tài khoản và bạn đáp ứng các yêu cầu của chương trình khuyến
                                            mãi, thì bạn có thể nhận được các phần thưởng này và sử dụng
                                            chúng trong các trò chơi baccarat.&nbsp;
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Có những loại tiền điện tử nào được hỗ trợ trong các trò
                                            chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat trực tuyến
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            ?
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Betcasino hỗ trợ nhiều loại tiền điện tử khác nhau cho các trò
                                            chơi baccarat trực tuyến, cụ thể là:
                                        </span>
                                    </p>
                                    <ol>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Bitcoin (BTC)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Ether (ETH)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Cardano (ADA)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Litecoin (LTC)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Tron (TRX)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Tether (USDT)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Ripple (XRP)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Dogecoin (DOGE)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Binance Coin (BNB)
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                Binance USD (BUSD).
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                <br />
                                            </span>
                                        </li>
                                    </ol>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tôi có thể chơi&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            baccarat trực tuyến với tiền điện tử&nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            trên thiết bị di động không?
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Có, bạn có thể chơi baccarat trên thiết bị di động của mình
                                            thông qua&nbsp;
                                        </span>
                                        <a
                                            data-gtm-event-category="cms_block_link"
                                            data-gtm-event-action="cms_block"
                                            data-gtm-event-info="https://bitcasino.io/promotions/introducing-bitcasino-android-app"



                                            href="/promotions/introducing-bitcasino-android-app"
                                        >
                                            <span
                                                style={{
                                                    fontSize: "10pt",
                                                    fontFamily: "Fraunces",
                                                    fontWeight: 400,
                                                    fontStyle: "normal",
                                                    fontVariant: "normal",
                                                    textDecoration: "underline",
                                                    WebkitTextDecorationSkip: "none",
                                                    textDecorationSkipInk: "none",
                                                    verticalAlign: "baseline",
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                ứng dụng Betcasino
                                            </span>
                                        </a>
                                        <span
                                            style={{
                                                fontSize: "11pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            &nbsp;
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            chính thức dành cho Android. Nếu bạn có thiết bị iOS, bạn vẫn
                                            có thể truy cập Betcasino thông qua trình duyệt trên điện
                                            thoại của mình.&nbsp;
                                        </span>
                                    </p>
                                    <h3
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "15pt",
                                            marginBottom: "10pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "13pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Tôi có thể chơi các trò chơi Baccarat miễn phí không?
                                        </span>
                                    </h3>
                                    <p
                                        dir="ltr"
                                        style={{
                                            lineHeight: "1.3800000000000001",
                                            marginTop: "9pt",
                                            marginBottom: "6pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "10pt",
                                                fontFamily: "Fraunces",
                                                fontWeight: 400,
                                                fontStyle: "normal",
                                                fontVariant: "normal",
                                                textDecoration: "none",
                                                verticalAlign: "baseline",
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            Có, có thể chơi miễn phí một số trò chơi baccarat tại
                                            Betcasino, cụ thể là các trò chơi baccarat video được trang bị
                                            RNG, ở chế độ demo. Tuy nhiên, trò chơi baccarat người chia
                                            bài trực tiếp không hỗ trợ chế độ chơi miễn phí. Thay vào đó,
                                            bạn có thể quan sát trận đấu trước khi đặt cược bằng tiền
                                            thật.&nbsp;
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center pt-4">
                        <button
                            className="px-4 h-10 py-2 gap-2 text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 active:scale-90 [&_+_span]:active:scale-90 [&_.hover]:hover:bg-bulma/[.07] btn-tertiary bg-hit text-bulma font-normal"
                            type="button"
                            data-testid="tw-button"
                        >
                            <span
                                data-translation="general.show_more"
                                className="group-[.open]/collapsed:hidden"
                            >
                                {" "}
                                Show more
                            </span>
                            <span
                                data-translation="general.show_less"
                                className="hidden group-[.open]/collapsed:block"
                            >
                                {" "}
                                Show less
                            </span>
                            <span
                                aria-hidden="true"
                                className="hover z-[-1] block absolute inset-0 pointer-events-none transition-[background-color_0.2s_ease-in-out] hover:bg-bulma/[.07]"
                            />
                        </button>
                    </div>
                </div> */}
            </div>
        </main>
    );
}
export default LiveBaccarat;
