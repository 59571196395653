import { Tabs, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userApi } from "src/api/userApi";
import { RootState } from "src/app/store";
import { MoneyConvert } from "src/util/format";
import IconQuyTac from "@assets/icons/ic-quy-tac.svg"
import IconCSKH from "@assets/icons/ic-CSKH-24-7.svg"
import IconChepMaMoi from "@assets/icons/ic-sao-chep-ma-moi.svg"
import IconKhuyenMai from "@assets/icons/ic-du-lieu-khuyen-mai.svg"
import IconArrowRight from "@assets/icons/arrowRight.svg"
import IconCopy from "@assets/icons/Copy.svg"

function Promotion() {
    const [tab, setTab] = useState('promotion');
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [promotion, setPromotion] = useState<any>({});

    const dataUser = useSelector((state: RootState) => state.user.dataUser);
    const textToCopy = `betcasino789.com/auth/register?ref=${dataUser?.code}`;

    useEffect(() => {
        fetchApi()
    
        async function fetchApi() {
          const response: any = await userApi.getPromotion()
          if (response?.status) {
            setPromotion(response?.invite)
          }
        }
      }, []);

    const navigate = useNavigate()
    const itemsTabs = [
        {
            label: `Tài khoản`,
            key: "profile",
        },
        {
            label: 'Lịch sử nạp tiền',
            key: "recharge",
        },
        {
            label: "Lịch sử rút tiền",
            key: "withdraw",
        },
        {
            label: "Khuyến mãi",
            key: "promotion",
        },
    ];

    const handleCopyToClipboard = (text: string, type: number) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                if (type === 0) {
                    setVisible(true);
                    setTimeout(function () {
                        setVisible(false);
                    }, 500);
                } else {
                    setVisible2(true);
                    setTimeout(function () {
                        setVisible2(false);
                    }, 500);
                }
            })
            .catch(err => {
                console.error('Error when copying to clipboard:', err);
                alert('Unable to copy to clipboard. Please try again.');
            });
    };

    return (
        <div className="mx-auto mt-0 grid max-w-[99rem] grid-cols-minmax gap-4 xl:px-8 xl:py-0 px-3 pb-0 pt-4 mian">

            <Tabs onChange={(e) => navigate(`/${e}`)} items={itemsTabs} defaultActiveKey={tab} />

            <div className="lazyContainer max-w-[700px] m-auto">
                <div data-v-7c8bbbf4="" className="promotion">
                    {/* <div data-v-7c8bbbf4="" class="tab">
              <ul data-v-7c8bbbf4="" class="c-row c-row-between">
                  <li data-v-7c8bbbf4="" onclick="location.href='/promotion'" class="action block-click">Tổng quan</li>
                  <li data-v-7c8bbbf4="" onclick="location.href='/promotion/myTeam'">Đội của tôi</li>
                  <li data-v-7c8bbbf4="" onclick="location.href='/promotion/promotionDes'">Lịch sử nhận</li>
                  <li data-v-7c8bbbf4="" onclick="location.href='/promotion/tutorial'">Hướng dẫn</li>
              </ul>
          </div> */}
                    <div data-v-7c8bbbf4="" className="box">
                        {/* <div data-v-7c8bbbf4="" class="tit">Hướng dẫn</div> */}
                        {/* <div data-v-7c8bbbf4="" class="m-t-10 c-row c-row-between p-l-15 p-r-15"
                  data-clipboard-text="">
                  
                  <div>Tổng cược: <span class="total-withdraw"></span></div>
                  <div class="btn-withdraw"
                      data-clipboard-text=""
                      data-toggle="modal" data-target="#withdrawalModal" 
                      >Rút jackpot
                  </div>
              </div> */}
                        <div data-v-7c8bbbf4="" className="info">
                            <p data-v-7c8bbbf4="" className="amount" id="roses_today">
                                {MoneyConvert(promotion?.roses_today)}&nbsp;₫
                            </p>
                            <h5 data-v-7c8bbbf4="" className="amount_txt">
                                Hoa hồng ngày hôm nay
                            </h5>
                            <div data-v-7c8bbbf4="" className="tip">
                                Thăng cấp độ để tăng thu nhập hoa hồng
                            </div>
                     
                            <div data-v-7c8bbbf4="" className="info-data c-row c-row-between flex-wrap">
                                <div data-v-7c8bbbf4="" className="flex c-row-between items-stretch w-full">
                                    <div data-v-7c8bbbf4="" className="info-item info-header">
                                        <div data-v-7c8bbbf4="" className="head">
                                            <div className="head-content">
                                                Cấp dưới trực tiếp
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-7c8bbbf4="" className="info-item info-item  info-header">
                                    
                                        <div data-v-7c8bbbf4="" className="head">
                                            <div className="head-content">
                                                Cấp dưới của đội
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div data-v-7c8bbbf4="" className="info-item">
                                    {/* <div data-v-7c8bbbf4="" className="head">
                                        <div className="head-content">
                                            Cấp dưới trực tiếp
                                        </div>
                                    </div> */}
                                    <div data-v-7c8bbbf4="" className="line1">
                                        <div data-v-7c8bbbf4="" id="f1_today">
                                        {promotion?.f1_today}
                                        </div>{" "}
                                        số người đăng ký
                                    </div>
                                    <div data-v-7c8bbbf4="" className="line2">
                                        <div data-v-7c8bbbf4="" id="f1RechargeToday">
                                        {promotion?.f1RechargeToday}
                                        </div>{" "}
                                        Số người nạp tiền
                                    </div>
                                    <div data-v-7c8bbbf4="" className="line3">
                                        <div data-v-7c8bbbf4="" id="f1RechargeMoneyToday">
                                        {MoneyConvert(promotion?.f1RechargeMoneyToday)}&nbsp;₫
                                        </div>{" "}
                                        Số tiền nạp
                                    </div>
                                    <div data-v-7c8bbbf4="" className="line1">
                                        <div data-v-7c8bbbf4="" id="f1NapdauToday">
                                        {promotion?.f1NapdauToday}
                                        </div>{" "}
                                        Số người nạp đầu
                                    </div>
                                    {/* <p data-v-7c8bbbf4="" class="txt" id="rosesf1_today">Hoa hồng trực tiếp:0</p>
                          <p data-v-7c8bbbf4="" class="txt" id="rosesf_today">Hoa hồng đội: 0</p>
                          <p data-v-7c8bbbf4="" class="txt">Tổng F mới trong ngày: <span data-v-7c8bbbf4=""
                              class="num" id="f_all_today">0</span></p>
                          <p data-v-7c8bbbf4="" class="txt">Tổng jackpot:<span data-v-7c8bbbf4=""
                              class="num" id="total_roses">0</span></p> */}
                                </div>
                                <div data-v-7c8bbbf4="" className="info-item">
                                    {/* <div data-v-7c8bbbf4="" className="head">
                                        <div className="head-content">
                                            Cấp dưới của đội
                                        </div>
                                    </div> */}
                                    <div data-v-7c8bbbf4="" className="line1">
                                        <div data-v-7c8bbbf4="" id="f_all_today">
                                        {promotion?.f_all_today}
                                        </div>{" "}
                                        số người đăng ký
                                    </div>
                                    <div data-v-7c8bbbf4="" className="line2">
                                        <div data-v-7c8bbbf4="" id="newMemberRechargeToday">
                                        {promotion?.newMemberRechargeToday}
                                        </div>{" "}
                                        Số người nạp tiền
                                    </div>
                                    <div data-v-7c8bbbf4="" className="line3">
                                        <div data-v-7c8bbbf4="" id="newMemberRechargeMoneyToday">
                                        {MoneyConvert(promotion?.newMemberRechargeMoneyToday)}&nbsp;₫
                                        </div>{" "}
                                        Số tiền nạp
                                    </div>
                                    <div data-v-7c8bbbf4="" className="line1">
                                        <div data-v-7c8bbbf4="" id="newMemberNapdauToday">
                                        {promotion?.newMemberNapdauToday}
                                        </div>{" "}
                                        Số người nạp đầu
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content mt-2">
                         
                            <div className="promote__cell btn-box">

                                {/* <div
                                    className="promote__cell-item"
                                    data-clipboard-text={dataUser?.code}
                                    onClick={() => handleCopyToClipboard(dataUser?.code, 1)}
                                >
                                    <div className="label">
                                        <IconChepMaMoi />
                                        <span>Sao chép mã mời</span>
                                    </div>
                                    <Tooltip title="COPIED" trigger="click" open={visible2}>
                                        <div className="arrow">
                                            <span className="code">{dataUser?.code}</span>
                                            <IconArrowRight />

                                        </div>
                                    </Tooltip>
                                </div> */}
                                {/* <div
                                    className="promote__cell-item"
                                    onClick={() => navigate('/promotion/myTeam')}
                                >
                                    <div className="label">
                                        <img src="https://xosovn.club/images/ic-lixi/ic-du-lieu-cap-duoi.svg" alt="" />
                                        <span>Dữ liệu cấp dưới</span>
                                    </div>
                                    <div className="arrow">
                                        <img
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    className="promote__cell-item"
                                    onClick={() => navigate('/promotion/promotionDes')}
                                >
                                    <div className="label">
                                        <img src="https://xosovn.club/images/ic-lixi/ic-chi-tiet-hoa-hong.svg" alt="" />
                                        <span>Chi tiết hoa hồng</span>
                                    </div>
                                    <div className="arrow">
                                        <img
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                                            alt=""
                                        />
                                    </div>
                                </div> */}
                                <div
                                    className="promote__cell-item"
                                    onClick={() => navigate('/promotion/tutorial')}
                                >
                                    <div className="label">
                                        <IconQuyTac />
                                        <span>Quy tắc mời</span>
                                    </div>
                                    <div className="arrow">
                                        {/**/}
                                        <IconArrowRight />

                                    </div>
                                </div>
                                <Link className="promote__cell-item" 
                                to='https://t.me/bong88cskh'
                                target="_blank"
                                // onClick={() => navigate('https://t.me/bong88cskh')}
                                >
                                    <div className="label">
                                        <IconCSKH />
                                        {/* <img src="https://xosovn.club/images/ic-lixi/ic-CSKH-24-7.svg" alt="" /> */}
                                        <span>CSKH</span>
                                    </div>
                                    <div className="arrow">
                                        {/**/}
                                        <IconArrowRight />
                                    </div>
                                </Link>
                            </div>
                            <div data-v-7c8bbbf4="" className="commission">
                                <div data-v-7c8bbbf4="" className="commission__title">
                                    <i data-v-7c8bbbf4="" className="van-badge__wrapper van-icon">
                                        {/* <img
                                            className="van-icon__image w-8 mr-2"
                                            src="https://xosovn.club/images/ic-lixi/ic-du-lieu-khuyen-mai.svg"
                                        /> */}
                                        <IconKhuyenMai />
                                    </i>
                                    <span data-v-7c8bbbf4="">Dữ liệu khuyến mãi</span>
                                </div>
                                <div data-v-7c8bbbf4="" className="commission__body">
                                    <div data-v-7c8bbbf4="">
                                        <span data-v-7c8bbbf4="" id="roses_week">
                                        {MoneyConvert(promotion?.roses_week)}&nbsp;₫
                                        </span>
                                        <span data-v-7c8bbbf4="">Tổng hoa hồng trong tuần</span>
                                    </div>
                                    <span data-v-7c8bbbf4="" />
                                    <div data-v-7c8bbbf4="">
                                        <span data-v-7c8bbbf4="" id="roses_all">
                                        {MoneyConvert(promotion?.roses_all)}&nbsp;₫
                                        </span>
                                        <span data-v-7c8bbbf4="">Tổng hoa hồng</span>
                                    </div>
                                </div>
                                <div data-v-7c8bbbf4="" className="commission__body">
                                    <div data-v-7c8bbbf4="">
                                        <span data-v-7c8bbbf4="" id="f1">
                                        {promotion?.f1}
                                        </span>
                                        <span data-v-7c8bbbf4="">Tổng số cấp dưới trực tiếp</span>
                                    </div>
                                    <span data-v-7c8bbbf4="" />
                                    <div data-v-7c8bbbf4="">
                                        <span data-v-7c8bbbf4="" id="total_f">
                                            {promotion?.total_f}
                                        </span>
                                        <span data-v-7c8bbbf4="">Tổng số cấp dưới trong đội</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="shareBtnContainer">
                                    <Tooltip title="COPIED" trigger="click" open={visible}>
                                        <button
                                            className="shareBtn"
                                            onClick={() => handleCopyToClipboard(textToCopy, 0)}
                                        >
                                            LINK GIỚI THIỆU
                                        </button>
                                    </Tooltip>
                                </div>
                                <div
                                    className="flex items-center gap-3"
                                    data-clipboard-text={dataUser?.code}
                                    onClick={() => handleCopyToClipboard(dataUser?.code, 1)}
                                >
                                    <div className="label flex items-center gap-1">
                                        <IconChepMaMoi />
                                        <span>Sao chép mã mời</span>
                                    </div>
                                    <Tooltip title="COPIED" trigger="click" open={visible2}>
                                        <div className="arrow flex items-center">
                                            <span className="code">{dataUser?.code}</span>
                                            <IconCopy className='text-3xl' />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>

            </div>
            {/* <div className="inline-container">
                <div className="inline-container">
                    <a href="/">
                        <p className="text-moon-12 text-trunks">
                            <img
                                alt=""
                                className=""
                                src="//heathmont.imgix.net/bitcasino/images/icons/bitcasino-logo.svg?auto=compress,format&h=32"
                                height={32}
                                loading="lazy"
                            />
                        </p>
                    </a>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="m-0 text-2xl text-trunks"
                    >
                        <path
                            d="M13 10L19 16L13 22"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="inline-container">
                    <a href="/refer-a-friend">
                        <p className="text-moon-12 text-trunks">
                            <span data-translation="profile.refer-a-friend">
                                Promotion
                            </span>
                        </p>
                    </a>
                </div>
            </div> */}
        </div>

    );
}
export default Promotion;
