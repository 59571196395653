import Benefits from "./Benefits";
import Levels from "./Levels";
import Welcome from "./Welcome";
import YourProgressle from "./YourProgressle";

function Loyalty() {
    return (
        <>
            <Welcome />
            <Benefits/>
            <Levels/>
            <YourProgressle/>
        </>
    );
}
export default Loyalty;
