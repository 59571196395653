import promotions from "@assets/mockdata/promotions.json"
import PromotionCard from '../PromotionCard';

function PromotionsView() {  
  return (
    <>
      {promotions?.map(({ node }: any, index: number) => {
        if (!node) {
          return null;
        }
        return (
          <PromotionCard
            promotion={node}
            position={index}
            key={node?.id}
            />
        );
      })}
    </>
  )
}
export default PromotionsView;
