import { Col, Drawer, Form, Input, Modal, Row, Select, Tooltip } from "antd"
import { useCallback, useMemo, useState } from "react"
import moment from "moment";
import "./style.scss"

export interface Props {
  open: boolean
  setOpen: any
}

function Payment({ amount, dataPayment }: any) {
  const [visible, setVisible] = useState(null)
console.log('dataPayment :>> ', dataPayment);
  const dataListPayment = useMemo(() => {
    return [
      {
        title: "Ngân hàng",
        value: dataPayment?.bank,
        isCopy: false,
      },
      {
        title: "Số tài khoản",
        value: dataPayment?.accountNumber,
        isCopy: true,
      },
      {
        title: "Tên chủ tài khoản",
        value: dataPayment?.accountName,
        isCopy: false,
      },
      {
        title: "Số tiền thành toán",
        value: dataPayment?.amount,
        isCopy: true,
      },
      {
        title: "Nội dung chuyển khoản",
        value: dataPayment?.description,
        isCopy: true,
      },
      {
        title: "Thời gian thanh toán còn lại",
        value: moment(new Date(Number(dataPayment?.timeStamp))).format('YYYY-MM-DD, HH:mm'),
        isCopy: false,
      },
    ]
  }, [dataPayment])

  const handleCopyToClipboard = (item: any, i: any) => {
    const textToCopy = item?.value
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setVisible(i)
        setTimeout(function () {
          setVisible(null)
        }, 3000)
      })
      .catch((err) => {
        console.error("Error when copying to clipboard:", err)
        alert("Unable to copy to clipboard. Please try again.")
      })
  }

  const renderItem = useCallback(
    (item: any, i: number) => {
      return (
        <div key={i} className="mb-4">
          <div className="text-white">{item?.title}</div>
          <div>
            <Tooltip title="COPIED" trigger="click" open={visible === i}>
              <button
                onClick={() => {
                  if (item?.isCopy) {
                    handleCopyToClipboard(item, i)
                  }
                }}
                className="flex bg-active w-fit cursor-pointer place-items-center items-center justify-between text-white h-[32px] w-full rounded-md px-3"
                data-testid="copy_wallet_address"
              >
                <div>{item?.value}</div>
                {item?.isCopy && (
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-bulma"
                    fontSize={28}
                  >
                    <path
                      d="M20.1923 10.1538H21.8462C22.9507 10.1538 23.8462 11.0492 23.8462 12.1538L23.8462 23.5C23.8462 24.6045 22.9507 25.5 21.8462 25.5H14.1539C13.0493 25.5 12.1539 24.6045 12.1539 23.5V21.8461M13.6154 7.23071V11.6153H9.23077M8.5 10.6853L12.6267 6.5H18.1923C19.2969 6.5 20.1923 7.39543 20.1923 8.5L20.1923 19.8462C20.1923 20.9507 19.2969 21.8462 18.1923 21.8462H10.5C9.39543 21.8462 8.5 20.9507 8.5 19.8462V10.6853Z"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      )
    },
    [handleCopyToClipboard],
  )

  return (
    <div className="modal-body info">
      {dataListPayment.map(renderItem)}
      {/* <img
        src="https://vietqr.co/api/generate/ACB/38002957/DANG DUC DUONG/50000/DH1720497319011?isMask=0&amp;logo=1&amp;style=2&amp;bg=61&amp;download"
        className="qr m-auto"
        alt=''
      /> */}
      <img src={dataPayment?.qr} className="qr m-auto" alt=''/>
    </div>
  )
}

export default Payment
