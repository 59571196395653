import { createSlice } from "@reduxjs/toolkit"
import { userApi } from "src/api/userApi"
import { AppThunk, RootState } from "src/app/store"
import { User } from "src/models"

export interface UserState {
  loading: boolean
  currentUser: User | null
  errorCode: string
  dataUser: any
  gameProduct: any
}

const initialState: UserState = {
  loading: false,
  currentUser: null,
  errorCode: "",
  dataUser: null,
  gameProduct: []
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action) => {
      state.loading = false
      state.currentUser = action.payload
      state.errorCode = ""
    },
    loginFailed: (state, action) => {
      state.loading = false
      state.errorCode = action.payload
      state.currentUser = null
    },
    logout: (state) => {
      state.loading = false
      state.currentUser = null
    },
    setUserData: (state, action) => {
      state.dataUser = action.payload
    },
    setListGameProduct: (state, action) => {
      state.gameProduct = action.payload
    },
  },
})

export const { setUserData, userLogin, loginSuccess, loginFailed, logout, setListGameProduct } =
  userSlice.actions

export const loginSelect = (state: RootState) => state.user
export const tokenSelect = (state: RootState) =>
  state.user.currentUser?.accessToken
export const userDataSelect = (state: RootState) => state.user.dataUser

export default userSlice.reducer
