// import { Card, Stack } from '@heathmont/moon-components';
// import { Caption, Text } from '@heathmont/moon-core';
import { Link } from 'react-router-dom';
import Img from 'src/components/Img';

type Props = {
  name: string;
  handle: string;
  count: number;
  sendToGtm?: () => void;
};

const ProviderCardMobile: React.FC<Props> = ({ name, handle, count, sendToGtm }) => (
  <div className='providerMobileContainer'>
    <div className='theme-bitcasino-light'>
      <div className='card-mobile'>
        <Link to={`/providers/${handle}`}>
          <Img
            src={`https://heathmont.imgix.net/bitcasino/images/providers/${handle}.svg`}
            alt={name}
            width={102}
            onClick={sendToGtm}
          />
        </Link>
      </div>
    </div>
    <div className='stack-card'>
      <Link
        to={`/providers/${handle}`}
        onClick={sendToGtm}
      >
        <>
          <span color={'trunks.100'} className='count-mobile'>
            {count} trò chơi
          </span>
          <h4 className='name-mobile' color={'bulma.100'}>
            {name}
          </h4>
        </>
      </Link>
    </div>
  </div>
);

export default ProviderCardMobile;
