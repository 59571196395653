import { Link } from "react-router-dom";
import TextTrimmer from "src/components/TextTrimmer";

type Props = { game: any };

const GameProvider: React.FC<Props> = ({ game }) => {
  const provider = game.menuItem?.[0];
  return (
    <Link to={`#`}>
      <TextTrimmer>
        <p className="text-moon-10 font-medium uppercase text-trunks">
          {provider?.name ?? '\u00A0'}
        </p>
      </TextTrimmer>
    </Link>
  );
};

export default GameProvider;
