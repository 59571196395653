import { ConfigProvider, notification } from "antd"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import io from 'socket.io-client';
import "swiper/css"
import "./App.css"
import MainLayout from "./layout"
import HomePage from "./pages/Home"
import NotFound from "./pages/NotFound"


import LoginPage from "./pages/Auth/Login"
import Baccarat from "./pages/Categories/Baccarat"
import Promotions from "./pages/Promotions"
import Loyalty from "./pages/Loyalty"
import ReferFriend from "./pages/ReferFriend"
import Providers from "./pages/Providers"
import Vip from "./pages/Vip"
import LoginRegisterLayout from "./layout/LoginRegisterLayout"
import RegisterPage from "./pages/Auth/Register"
import LiveBaccarat from "./pages/Categories/LiveBaccarat"
import LiveCasino from "./pages/Categories/LiveCasino"
import Games from "./pages/Games"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "./app/store"
import { useAppDispatch } from "./app/hooks"
import { addWalletUser } from "./features/header/headerSlice"
import Profile from "./pages/Profile"
import RechargeHistory from "./pages/RechargeHistory"
import WithdrawHistory from "./pages/WithdrawHistory"
import Promotion from "./pages/Promotion"
import MyTeam from "./pages/Promotion/MyTeam"
import PromotionDes from "./pages/Promotion/PromotionDes"
import Tutorial from "./pages/Promotion/Tutorial"
import KeFuMenu from "./pages/Promotion/KeFuMenu"
import Tutorial2 from "./pages/Promotion/Tutorial2"
import ChooseBank from "./pages/ChooseBank"
import { SocketContext } from "./sockets/socketInstance";

const socket = io('https://api.betcasino789.com', {
  transports: ['websocket'], // Cấu hình các transport
  reconnectionAttempts: 5,   // Số lần thử kết nối lại
  timeout: 5000,            // Thời gian chờ kết nối (ms)
  autoConnect: true,         // Tự động kết nối khi khởi tạo
  secure: true,              // Kết nối bảo mật (dành cho HTTPS)
});

function App() {
  const token = localStorage.getItem('token')
  const dataUser = useSelector((state: RootState) => state.user.dataUser);
  const dispath = useAppDispatch()  

  useEffect(() => {
    if (dataUser?.wallet && token) {
      dispath(addWalletUser(dataUser?.wallet))
    }
  }, [token, dataUser?.wallet]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Roboto",
        }
      }}
    >
      <SocketContext.Provider value={socket}>
      <BrowserRouter>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="" element={<HomePage />} />
          </Route>

          {/* <Route element={<MainLayout />} path="choose-bank">
            <Route path="" element={<ChooseBank />} />
          </Route> */}

          <Route path="categories" element={<MainLayout />}>
            <Route path="game/:id" element={<Baccarat />} />
            <Route path="live-baccarat" element={<LiveBaccarat />} />
            <Route path="live-casino" element={<LiveCasino />} />
          </Route>

          <Route path="games" element={<MainLayout />}>
            <Route path="" element={<Games />} />
          </Route>

          <Route path="promotions" element={<MainLayout />}>
            <Route path="" element={<Promotions />} />
          </Route>

          <Route path="loyalty" element={<MainLayout />}>
            <Route path="" element={<Loyalty />} />
          </Route>

          <Route path="refer-a-friend" element={<MainLayout />}>
            <Route path="" element={<ReferFriend />} />
          </Route>
          <Route path="profile" element={<MainLayout />}>
            <Route path="" element={<Profile />} />
          </Route>

          <Route path="recharge" element={<MainLayout />}>
            <Route path="" element={<RechargeHistory />} />
          </Route>

          <Route path="withdraw" element={<MainLayout />}>
            <Route path="" element={<WithdrawHistory />} />
          </Route>

          <Route path="promotion" element={<MainLayout />}>
            <Route path="" element={<Promotion />} />
            <Route path="myTeam" element={<MyTeam />} />
            <Route path="promotionDes" element={<PromotionDes />} />
            <Route path="tutorial" element={<Tutorial2 />} />
            <Route path="keFuMenu" element={<KeFuMenu />} />
          </Route>

          <Route path="providers" element={<MainLayout />}>
            <Route path="" element={<Providers />} />
          </Route>

          <Route path="vip" element={<MainLayout />}>
            <Route path="" element={<Vip />} />
          </Route>

          <Route
            path="*"
            element={<MainLayout />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="auth" element={<LoginRegisterLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </SocketContext.Provider>
    </ConfigProvider>
  )
}

export default App
