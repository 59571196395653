import GameName from './GameName';
import GameProvider from './GameProvider';
import InfoTooltip from './InfoTooltip';

type Props = { game: any; href?: string };

const GameInfo: React.FC<Props> = ({ game, href }) => (
  <div className="grid grid-flow-col grid-cols-minmax px-1.5 py-0">
    <div className="grid">
      <GameName game={game} href={href} />
      <GameProvider game={game} />
    </div>
    <InfoTooltip game={game} />
  </div>
);

export default GameInfo;
