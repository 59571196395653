import ProviderCardDesktop from './ProviderCardDesktop';
import ProviderCardMobile from './ProviderCardMobile';
import useIsMobile from 'src/customHook/useIsMobile';

type Props = {
  name: string;
  handle: string;
  count: number;
  games: any;
};

const ProviderCard: React.FC<Props> = ({ name, handle, count, games }) => {
  const { isMobile } = useIsMobile(768)


  return (
    <>
      {isMobile ? (
        <ProviderCardMobile
          name={name}
          handle={handle}
          count={count}
        />
      ) : (
        <div className='theme-bitcasino-light'>
          <ProviderCardDesktop
            name={name}
            handle={handle}
            count={count}
            games={games}
          />
        </div>
      )}
    </>
  );
};

export default ProviderCard;
