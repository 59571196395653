
function Levels() {
    return (
        <div className="relative grid w-full max-w-full items-center overflow-hidden text-bulma xl:grid-flow-col xl:[&_h2]:text-left">
            <div className="relative grid justify-self-center rem:max-w-[544px] xl:mx-4 xl:justify-self-end">
                <div className="relative mb-4 grid grid-flow-col text-center">
                    <img
                        alt=""
                        className="relative top-2 md:hidden"
                        src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.benefits_border_mobile.svg?auto=compress,format"
                        loading="lazy"
                    />
                    <div className="grid gap-4">
                        <h2 className="text-moon-24 font-fraunces font-light">
                            <span data-translation="loyalty_landing.profits_main_title">
                                Tận hưởng những lợi nhuận sau:
                            </span>
                        </h2>
                    </div>
                    <img
                        alt=""
                        className="relative top-2 -scale-x-100 justify-self-end md:hidden"
                        src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.benefits_border_mobile.svg?auto=compress,format"
                        loading="lazy"
                    />
                </div>
                <div className="grid gap-2">
                    <div className="relative mx-5 box-border grid cursor-pointer auto-cols-[auto_1fr] grid-flow-col gap-x-2 rounded-moon-s-sm border border-solid border-transparent p-4 duration-700 ease-in-out md:items-center md:gap-x-6 md:p-8 xl:m-0 bg-gohan">
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="stroke-[4px] text-moon-24 text-piccolo md:text-moon-32"
                        >
                            <path
                                d="M7 14.9412L13.6667 22L25 10"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div>
                            <h3 className="text-moon-20 font-fraunces md:text-moon-24">
                                <span data-translation="loyalty_landing.profits_title_1">
                                    Chơi nhiều hơn, kiếm được nhiều hơn
                                </span>
                            </h3>
                            <p className="text-trunks md:text-moon-18">
                                <span data-translation="loyalty_landing.profits_text_1">
                                    Tất cả các phần thưởng được điều chỉnh theo cách chơi của bạn.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="relative mx-5 box-border grid cursor-pointer auto-cols-[auto_1fr] grid-flow-col gap-x-2 rounded-moon-s-sm border border-solid border-transparent p-4 duration-700 ease-in-out md:items-center md:gap-x-6 md:p-8 xl:m-0">
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="stroke-[4px] text-moon-24 text-piccolo md:text-moon-32"
                        >
                            <path
                                d="M7 14.9412L13.6667 22L25 10"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div>
                            <h3 className="text-moon-20 font-fraunces md:text-moon-24">
                                <span data-translation="loyalty_landing.profits_title_2">
                                    Không yêu cầu đặt cược
                                </span>
                            </h3>
                            <p className="text-trunks md:text-moon-18">
                                <span data-translation="loyalty_landing.profits_text_2">
                                    Tất cả phần thưởng là tiền thật mà không cần đặt cược
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="relative mx-5 box-border grid cursor-pointer auto-cols-[auto_1fr] grid-flow-col gap-x-2 rounded-moon-s-sm border border-solid border-transparent p-4 duration-700 ease-in-out md:items-center md:gap-x-6 md:p-8 xl:m-0">
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="stroke-[4px] text-moon-24 text-piccolo md:text-moon-32"
                        >
                            <path
                                d="M7 14.9412L13.6667 22L25 10"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div>
                            <h3 className="text-moon-20 font-fraunces md:text-moon-24">
                                <span data-translation="loyalty_landing.profits_title_3">
                                    Hoàn toàn được cá nhân hóa
                                </span>
                            </h3>
                            <p className="text-trunks md:text-moon-18">
                                <span data-translation="loyalty_landing.profits_text_3">
                                    Tất cả phần thưởng được tạo để phù hợp với phong cách chơi của
                                    bạn
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative h-60 w-auto overflow-hidden xl:rem:h-[544px] xl:rem:w-[544px]">
                <img
                    alt=""
                    className="absolute left-1/2 m-auto h-60 -translate-x-1/2 transition-opacity duration-700 xl:left-0 xl:w-auto xl:translate-x-0 xl:rem:h-[544px] opacity-100"
                    src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.profits_img_1.png?auto=compress,format"
                    loading="eager"
                />
                <img
                    alt=""
                    className="absolute left-1/2 m-auto h-60 -translate-x-1/2 opacity-0 transition-opacity duration-700 xl:left-0 xl:w-auto xl:translate-x-0 xl:rem:h-[544px]"
                    src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.profits_img_2.png?auto=compress,format"
                    loading="eager"
                />
                <img
                    alt=""
                    className="absolute left-1/2 m-auto h-60 -translate-x-1/2 opacity-0 transition-opacity duration-700 xl:left-0 xl:w-auto xl:translate-x-0 xl:rem:h-[544px]"
                    src="//heathmont.imgix.net/bitcasino/images/loyalty_landing.profits_img_3.png?auto=compress,format"
                    loading="eager"
                />
            </div>
        </div>
    );
}
export default Levels;
